import { React, Component, _, View, Image, Text, Tooltip, styleSpread, destroyDecision, prepareToAnimate, Link, Button } from '../index.js';
import { connect } from '@symbolic/redux';
import { TouchableOpacity } from 'react-native';
import { confirm, CopilotStepView, Label, TrialStatusButton, NetworkIndicator } from '@symbolic/rn-lib';
import { withRouter } from 'react-router-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { sharingUnlocked } from '~/lib/subscription';
import { K } from '~/styles';

import UrlPattern from 'url-pattern';
import homeImage from '../../assets/home-icon-white.png';
import viewCollaboratorsImage from '../../assets/view-collaborators-icon.png';
import collaboratorsErrorIcon from '../../assets/collaborators-error-icon.png';
import shareImage from '../../assets/share.png';
import styles from './header-content.styles.js';

var s = styleSpread(styles);

class HeaderContent extends Component {
  deleteActiveDecision = async () => {
    var {activeDecision} = this.props;
    var {title} = activeDecision;

    if (await confirm('Delete', `Are you sure? ${title ? `"${title}"` : 'this decision'} will be deleted permanently`)) {
      this.props.history.push('/');

      await this.props.destroyDecision({id: activeDecision.id});
    }
  }

  handleHomeButtonPress = () => {
    var {activeDecision, history} = this.props;

    if (activeDecision && activeDecision.wasModified === 0) this.props.destroyDecision({id: activeDecision.id});

    history.push('/');
  }

  async setActiveDecisionMode(activeDecisionMode) {
    prepareToAnimate();

    this.props.setActiveView({...this.props.activeView, data: {activeDecisionMode}});
  }

  get isOwner() {
    return this.props.activeDecision.ownerId === this.props.session.user.id;
  }

  render() {
    var {setActiveView, activeDecision, session} = this.props;
    var isHome = _.includes(['', '/'], this.props.location.pathname);

    var hasAccess = sharingUnlocked(session.user);

    return (
      <View style={{...styles.header}}>
        <View {...s.leftContent}>
          {!isHome && (
            <Button style={{...styles.button, marginRight: K.margin}} onPress={this.handleHomeButtonPress}>
              <Image {...s.backImage} source={homeImage}/>
            </Button>
          )}
          <TrialStatusButton/>
        </View>
        <View style={{...styles.rightContent}}>
          <View style={{marginRight: K.spacing + K.margin}}>
            <NetworkIndicator />
          </View>
          {activeDecision && (
            <Tooltip text='Help menu'>
              <TouchableOpacity
                style={{...styles.button, backgroundColor: 'white', width: 'auto', paddingHorizontal: K.spacing, marginRight: K.margin}}
                onPress={() => setActiveView({data: {helpPopupIsVisible: true}})}
              >
                <Label style={{color: 'black', opacity: 1}}>Help</Label>
                {/* <Image {...s.shareImage} source={helpIcon}/> */}
              </TouchableOpacity>
            </Tooltip>
          )}
          {activeDecision && (
            <Tooltip text='View collaborators'>
              <TouchableOpacity
                style={{...styles.button, ...(hasAccess || !this.isOwner || !K.isWeb ? {} : {backgroundColor: K.colors.deleteRed})}}
                onPress={hasAccess || !this.isOwner ?
                  () => setActiveView({data: {sharePopupIsVisible: true, isViewingCollaborators: true}}) :
                  () => setActiveView({data: {trialExpiredPopupIsVisible: true}})
                }
              >
                <Image {...s.shareImage} source={hasAccess || !this.isOwner || !K.isWeb ? viewCollaboratorsImage : collaboratorsErrorIcon}/>
              </TouchableOpacity>
            </Tooltip>
          )}
          {activeDecision && (
            <CopilotStepView name='helpButton' order={13} style={{marginLeft: K.margin}} text={`${K.isWeb ? 'Click' : 'Tap'} here to share your decision.`}>
              <Tooltip text='Share menu'>
                <TouchableOpacity
                  style={{...styles.button}}
                  onPress={() => setActiveView({data: {sharePopupIsVisible: true}})}
                >
                  <Image {...s.shareImage} source={shareImage}/>
                </TouchableOpacity>
              </Tooltip>
            </CopilotStepView>
          )}
        </View>
      </View>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({mapState: (state, ownProps) => {
  var pattern = new UrlPattern('/decisions/:decisionId(/*)');

  if (pattern.match(ownProps.location.pathname)) {
    var {decisionId} = pattern.match(ownProps.location.pathname);
  }

  return {
    activeDecision: _.get(state.resources.decisions.byId, parseInt(decisionId)),
    users: state.resources.users.byId,
    activeView: state.activeView,
    session: state.session
  };
}, mapDispatch: {setActiveView, destroyDecision}})(HeaderContent)));
