import { React, Component, _, View, styleSpread, Button, Popup, Text } from '~/components/index.js'; //eslint-disable-line
import { Image, Pressable } from 'react-native';
import { connect } from '@symbolic/redux';
import { K } from '~/styles';

import styles from './basics-popup.styles';

import desktopDiagram from '~/assets/basics-popup/desktop-diagram.png'

var s = styleSpread(styles);

class BasicsPopup extends Component {
  render() {
    return (
      <Popup scrollEnabled height={K.orientation === 'landscape' ? '75%' : 500} onClose={this.props.hideBasicsPopup}>
        <View {...s.basicsPopupContainer}>
          <Text {...s.basicsPopupTitle}>The Basics</Text>
          <Pressable dataSet={{nohover: 1}}>
            <View {...s.diagramContainer}>
              <Image {...s.basics} resizeMode='contain' source={desktopDiagram}/>
            </View>
            <Text style={{...K.explanationText, marginTop: K.spacing}}>{`Protagonist is a tool designed to help you make various types of decisions when multiple options are available. You can use our templates as a starting point and modify them as you wish.\n\nTo come up with a decision, rate the multiple options based on factors to get total scores, and then weight the factors based on their importance. If you’re making a group decision, share the decision with others to get their perspectives.`}</Text>
            <Text style={{...K.explanationText, paddingBottom: 0, marginTop: 0}}><Text style={{fontWeight: 'bold'}}>Options</Text> - the courses of action you’re considering taking</Text>
            <Text style={{...K.explanationText, paddingBottom: 0, marginTop: 0}}><Text style={{fontWeight: 'bold'}}>Factors</Text> - the parameters your decision is based on</Text>
            <Text style={{...K.explanationText, paddingBottom: 0, marginTop: 0}}><Text style={{fontWeight: 'bold'}}>Ratings</Text> - your evaluation of each option</Text>
            <Text style={{...K.explanationText, marginTop: 0, marginBottom: K.spacing}}><Text style={{fontWeight: 'bold'}}>Perspectives</Text> - support for different opinions when making a group decision</Text>
            <Button text='Got it' {...s.doneButton} textStyle={styles.doneButtonText} onPress={() => this.props.hideBasicsPopup()}/>
          </Pressable>
        </View>
      </Popup>
    );
  }
}

export default connect({
  mapState: state => ({session: state.session})
})(BasicsPopup);
