import { K } from '~/styles';

var s = {};

s.K = {padding: K.spacing, gapSize: 1, borderRadius: K.bar.borderRadius, barHeight: K.bar.height, maxColWidth: K.bar.minWidth};

s.titleButton = {
  alignItems: 'center',
  flexDirection: 'row',
  height: s.K.barHeight,
  width: '100%'
};
s.titleInputContainer = {
  alignItems: 'center',
  justifyContent: 'center',
  height: s.K.barHeight,
  width: '100%',
  flexDirection: 'row'
};
  s.optionTextInput = {
    ...K.fonts.sl,
    width: '100%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderBottomWidth: 0,
  };
s.titleContainer = {
  width: '50%',
  backgroundColor: 'white',
  borderRadius: K.borderRadius,
};
s.statusContainer = {
  outerView: {
    flexGrow: 1, padding: 0, marginLeft: K.spacing
  }
}
s.commentsIconContainer = {
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
  flexDirection: 'row'
};
s.commentsIcon = {
  width: K.calc(13),
  height: K.calc(12),
  opacity: 0.3,
  marginRight: K.spacing
};
s.valueText = {
  alignItems: 'center',
  justifyContent: 'center',
  height: K.bar.height,
  borderRadius: K.bar.borderRadius
}
s.numericInputContainer = {
  position: 'absolute',
  alignItems: 'center',
  marginLeft: K.spacing - 5,
  width: 30,
  // justifyContent: 'center',
  //paddingLeft: K.margin,
  //height: K.bar.height,
  //width: '100%',
  flexDirection: 'row',
};
  s.numericInput = {
    //width: K.calc(60),
    zIndex: 100,
    paddingRight: 0,
    paddingLeft: 5,
    width: 25,
    height: 'auto',
    textAlign: 'left',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    // ...K.shadow
  };
  s.showNotesPopupButton = {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: K.bar.height,
    left: '90%',
    width: '10%',
    flexDirection: 'row',
    zIndex: 100,
    opacity: 0.2
  };
s.deleteButton = {
  position: 'absolute',
  left: '100%',
  top: 0,
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  paddingHorizontal: 20,
  flexDirection: 'column',
  justifyContent: 'center',
  height: s.K.barHeight
};
s.editButton = {
  height: 50,
  width: K.spacing * 2,
  alignItems: 'center',
  justifyContent: 'center',
};
s.deleteButtonImage = {
  width: 10,
  height: 10
}
s.barContainer = {
  marginRight: s.K.gapSize
};
  s.bar = {
    minHeight: s.K.barHeight,
  };
s.netValueBar = {
  backgroundColor: 'rgba(165, 165, 165, 0.3)',
  height: s.K.barHeight,
  position: 'absolute',
  top: 0,
  zIndex: -1
};

export default s;
