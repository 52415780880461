import _ from 'lodash';
import lib from '@symbolic/lib';

var coloredFactorsFor = ({factors}) => {
  var colors = lib.colors.fun;

  factors = _.map(_.sortBy(factors, 'id'), (factor, index) => {
    return {...factor, color: factor.weight === 0 ? lib.colors.colorFor({status: 'archived'}) : colors[index % colors.length]};
  });

  return factors;
};

export {coloredFactorsFor};
