import { React, _, styleSpread, View} from '../../../../index.js'; //eslint-disable-line

import { K } from '~/styles';
import { Animated } from 'react-native';
import Row from './row/row';
import styles from './rows.styles';

var s = styleSpread(styles);

var Rows = ({renderMode, sticky, scrollViewIsEnabled, options, sortedOptionsBySheet, values, ...restProps}) => {
  var {focusedResourceKey, focusedResourceId, sheets, decision} = restProps;
  var throttleValue = renderMode === 'bars' ? 16 : 10;
  var sideKey = renderMode === 'bars' ? 'right' : 'left';

  var rowsData = [];

  if (focusedResourceKey === 'decisionOption') {
    rowsData = sheets;

    options = _.map(options, option => {
      var relevantValues = _.filter(values, {decisionOptionId: focusedResourceId});

      option.noSheetValues = !relevantValues || _.every(relevantValues, ({value}) => value === null || value === undefined);

      return option;
    });
  }
  else if (focusedResourceKey === 'decisionFactor') {
    options = _.map(options, option => {
      var relevantValues = _.filter(values, {decisionFactorId: focusedResourceId});

      option.noSheetValues = !relevantValues || _.every(relevantValues, ({value}) => value === null || value === undefined);

      return option;
    });

    rowsData = options;
  }
  else if (focusedResourceKey === null) {
    var noSheetValues = !_.some(_.flatMap(sortedOptionsBySheet), option => !option.noSheetValues);

    _.forEach(sheets, (sheet, index) => {
      rowsData.push({sheet, option: {}});
      var options = sortedOptionsBySheet[sheet.id];

      _.forEach(options, option => {
        option.noSheetValues = noSheetValues;

        rowsData.push({sheet, option});
      });
    });
  }
  else {
    rowsData = options;
  }

  var _rows = _.map(rowsData, (resource, index) => {
    var option, sheet, rowResourceKey, rowResourceId;

    if (focusedResourceKey === 'decisionOption') {
      sheet = resource;

      option = _.find(sortedOptionsBySheet[sheet.id], {id: focusedResourceId});
    }
    else if (focusedResourceKey === null) {
      ({sheet, option} = resource);
    }
    else {
      option = resource;

      sheet = focusedResourceKey === 'decisionSheet' ? _.find(sheets, {id: focusedResourceId}) : {id: index};
    }

    if (!_.isEmpty(option) && focusedResourceKey !== 'decisionOption') {
      rowResourceKey = 'option';
      rowResourceId = option.id;
    }
    else {
      rowResourceKey = 'sheet';
      rowResourceId = sheet.id;
    }

    return (
      <Row
        {...{option, options, sheet, renderMode, index, rowResourceKey, rowResourceId}}
        key={`${option.id}-${_.get(sheet, 'id')}-${renderMode}-${focusedResourceKey}-${focusedResourceId}-${restProps.mode}`}
        decisionId={decision.id}
        {...restProps}
      />
    );
  });

  return renderMode === 'screenshot' ? (
    <View >{_rows}</View>
  ) : (
    <Animated.ScrollView
      onScrollBeginDrag={sticky[sideKey].handleBeginDrag}
      onScroll={sticky[sideKey].handleScroll}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      scrollEnabled={scrollViewIsEnabled}
      scrollEventThrottle={throttleValue}
      ref={sticky[sideKey].getRef}
      contentContainerStyle={{paddingBottom: K.spacing * 8}}
      {...s.verticalScrollView}
      bounces={false}
    >{_rows}</Animated.ScrollView>
  );
};

export default Rows;
