import { K } from '~/styles';

var s = {};

s.basicsPopupContainer = {

};
  s.basicsPopupTitle = {
    marginLeft: K.spacing,
    marginTop: K.spacing,
    textTransform: 'uppercase',
    fontSize: K.calcFont(16),
    letterSpacing: K.calcFont(2),
    fontWeight: 'bold'
  };
  s.diagramContainer = {
    alignItems: 'center',
    justifyContent: 'center',
    height: K.calc(200),
    marginTop: K.spacing * 2
  };
    s.basics = {
      width: '100%',
      height: '100%'
    };
  s.doneButton = {
    backgroundColor: 'black'
  };
  s.doneButtonText = {
    color: 'white'
  };


export default s;
