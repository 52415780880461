import _ from 'lodash';

function viewableDecisionSheetIdsFor({user, decision, decisionSheets}) {
  return _.chain(decisionSheets)
    .filter(sheet => {
      return decision.ownerId === user.id || sheet.isMasterSheet || decision.collaborationMode === 'manyPublicSheets'
       || (decision.collaborationMode === 'manyPrivateSheets' && (sheet.userId === user.id || sheet.editableBy === user.id));
    })
    .map('id')
    .value();
}

export {viewableDecisionSheetIdsFor};
