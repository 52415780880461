import { React, Component, _, View, resourceActions, Button, Text, TextInput, PickerInput, styleSpread, Popup, LabelledView, createDecisionTemplate } from '~/components/index.js'; //eslint-disable-line
import { CopilotStepView, Label, confirm, OrgIcon, CheckboxInput } from '@symbolic/rn-lib';
import { withRouter } from 'react-router-native';
import { Linking } from 'react-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { connect, setProtagonistData } from '@symbolic/redux';
import { Keyboard } from 'react-native';
import styles from './decision-popup.styles';
import lib from '@symbolic/lib';

import { K } from '~/styles';

var s = styleSpread(styles);

class DecisionPopup extends Component {
  state = {};

  handleInputChange = ({key, value}) => {
    this.props.updateDecision({id: this.props.decision.id, props: {[key]: value, wasModified: 1}});
  }

  handleDeletePress = async () => {
    var {decision} = this.props;
    var {id, title} = decision;

    // if (await confirm('', 'Are you sure you want to DELETE this?')) {
      setTimeout(() => this.props.history.push('/'), 0);

      this.props.destroyDecision({id: decision.id});

      this.props.triggerUndoPopup({event: {
        message: `${title ? `The decision "${title}"` : 'That decision'} has been deleted`,
        action: () => {
          this.props.updateDecision({id, props: {deleted: 0}});

          this.props.trackDecisions({decisions: [decision]});
        }
      }});
    // }
  }

  createTemplate = async () => {
    var orgId = _.get(this.state, 'templateOrgId', this.props.session.activeOrg.id);
    var title = _.get(this.state, 'templateTitle', '');
    var ownerId = this.props.session.user.id;

    var factorsData = _.map(this.props.factors, factor => ({
      title: factor.title,
      weight: this.state.templateFactorsAreWeighted === '1' ? factor.weight : 1,
      notes: this.state.templateFactorsIncludeBackgroundInfo === '1' ? factor.notes : ''
    }));

    if (!title) {
      alert('Please name your template!');
    }
    else {
      await this.props.createDecisionTemplate({props: {orgId, title, factorsData, ownerId}});

      this.setState({isCreatingTemplate: false});

      alert('Your template has been successfully created. You and others in the selected workspace may now use it from the home screen.');
    }
  }

  close = () => {
    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => this.props.onClose(), K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile
  }

  handlePolydotButtonPress = () => {
    var {decision, session} = this.props;

    if (!_.get(session.user, 'protagonistData.hasSeenPolydotIntegration', false)) {
      alert('This allows you to connect this step to a Polydot project. This is especially useful if this decision is related to a project that requires multiple stakeholders to stay in the loop. Polydot is another Symbolic Frameworks app - check it out at Polydot.app!');

      this.props.setProtagonistData({key: 'hasSeenPolydotIntegration', value: true});
    }

    if (decision.processInstanceId) {
      var domain = `http${process.env.NODE_ENV === 'production' ? 's://protag.app' : '://localhost:19007'}`;
      var subdirectory = `/projects/${decision.processInstanceId}${decision.processStepId ? `/steps/${decision.processStepId}` : ''}`
      var url = domain + subdirectory;

      if (K.isWeb) {
        window.open(url, '_blank');
      }
      else {
        Linking.openURL(url);
      }
    }
    else {
      this.props.setActiveView({data: {polydotPopupIsVisible: true}});
    }
  }

  render() {
    var {decision} = this.props;

    var statusToStoriesStatusMap = {
      'brainstorming': 'planning',
      'analysis': 'active',
      'finalReview': 'pending',
      'finalDecision': 'complete',
    };

    var statusOptions = [
      {value: 'brainstorming', title: 'Evaluation - add and rate options', color: lib.colors.colorFor({status: statusToStoriesStatusMap.brainstorming})},
      {value: 'analysis', title: 'Analysis - weight factors and discuss', color: lib.colors.colorFor({status: statusToStoriesStatusMap.analysis})},
      {value: 'finalReview', title: 'Review - scrutinize the top choices', color: lib.colors.colorFor({status: statusToStoriesStatusMap.finalReview})},
      {value: 'finalDecision', title: 'Finalized - lock changes and export', color: lib.colors.colorFor({status: statusToStoriesStatusMap.finalDecision})}
    ];

    return (
      <Popup
        scrollEnabled
        closeOnEnter
        useCloseButton
        onClose={this.close}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <View style={{flexDirection: 'row', width: 'auto', marginBottom: K.spacing * 2}}>
          <Button
            style={{backgroundColor: K.colors.deleteRed, flex: 2, marginRight: K.margin}}
            onPress={this.handleDeletePress}
            label='Delete entire decision'
          />
          <Button label='Done' style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={this.close}/>
        </View>
        <TextInput
          label='Decision title'
          grayLabelledView
          onChange={({value}) => this.handleInputChange({key: 'title', value})}
          value={_.get(decision, 'title', '')}
        />
        <TextInput
          multiline
          label='Background Info'
          value={decision.notes || ''}
          grayLabelledView
          standardAutoheightStyles
          onChange={({value}) => this.handleInputChange({key: 'notes', value})}
        />
        <LabelledView label='Status' gray>
          <PickerInput
            style={{marginTop: 0}}
            value={_.get(decision, 'status', 'brainstorming')}
            options={statusOptions}
            onChange={({value}) => this.handleInputChange({key: 'status', value})}
            buttonStyle={{...styles.pickerButton, backgroundColor: K.colors.gray, justifyContent: 'flex-start'}}
            textStyle={{...K.fonts.label, flex: 1}}
            showDownArrow
          />
        </LabelledView>
        <CheckboxInput
          style={{marginTop: K.spacing}}
          onChange={({value}) => this.props.updateDecision({id: decision.id, props: {shareesCanEditOptions: value, wasModified: 1}})}
          label='Collaborators can add options'
          value={decision.shareesCanEditOptions}
        />
        <CheckboxInput
          style={{marginTop: 1}}
          onChange={({value}) => this.props.updateDecision({id: decision.id, props: {isLocked: value, wasModified: 1}})}
          value={decision.isLocked || 0}
          label={'Edits are locked'}
        />
        <Button
          style={{backgroundColor: 'black', width: 'auto', marginTop: K.spacing * 2}}
          textStyle={{color: 'white'}}
          label='Save as reusable template'
          onPress={() => this.setState({isCreatingTemplate: true})}
        />
        {!decision.processInstanceId && (
          <Button
            onPress={() => this.props.setActiveView({data: {polydotPopupIsVisible: true}})}
            style={{backgroundColor: 'black', width: 'auto', marginTop: K.margin}}
            label='Link a Polydot Project'
            textStyle={{color: 'white'}}
          />
        )}
        {this.state.isCreatingTemplate && (
          <Popup scrollEnabled onClose={() => this.setState({isCreatingTemplate: false})}>
            <Text style={{fontStyle: 'italic'}}>{`1. A template has a name and collection of factors. \n2. We typically recommend resetting factor weights because they tend to be contextual. \n3. When you click "Create Template", this template will become available to everyone in the selected workspace.`}</Text>
            <View style={{flexDirection: 'row', marginVertical: K.spacing * 2}}>
              {_.map(this.props.session.orgs, org => (
                <OrgIcon
                  {...s.org}
                  key={org.id}
                  initialsStyle={{fontSize: K.calcFont(12), letterSpacing: K.calcFont(1.5)}}
                  hideBorder={_.get(this.state, 'templateOrgId', this.props.session.activeOrg.id) !== org.id}
                  onPress={() => this.setState({templateOrgId: org.id})}
                  org={org}
                />
              ))}
            </View>
            <TextInput autoFocus label='Template title' onChange={({value}) => this.setState({templateTitle: value})}/>
            <Label style={{marginTop: K.spacing, marginLeft: K.spacing}}>Factor Weights</Label>
            <PickerInput
              value={this.state.templateFactorsAreWeighted || '0'}
              style={{marginTop: K.margin}}
              textStyle={{...K.fonts.label, opacity: 1}}
              inline
              basic
              onChange={({value}) => this.setState({templateFactorsAreWeighted: value})}
              options={[{title: 'Reset weights', value: '0'}, {title: 'Keep weights', value: '1'}]}
            />
            <Label style={{marginTop: K.spacing, marginLeft: K.spacing}}>Factor Background Info</Label>
            <PickerInput
              value={this.state.templateFactorsIncludeBackgroundInfo || '0'}
              style={{marginTop: K.margin}}
              textStyle={{...K.fonts.label, opacity: 1}}
              inline
              basic
              onChange={({value}) => this.setState({templateFactorsIncludeBackgroundInfo: value})}
              options={[{title: 'Reset info', value: '0'}, {title: 'Keep info', value: '1'}]}
            />
            <View style={{flexDirection: 'row', marginTop: K.spacing * 2}}>
              <Button label='Cancel' style={{width: 'auto', flex: 1}} onPress={() => this.setState({isCreatingTemplate: false})}/>
              <Button label='Create Template' style={{marginLeft: K.margin, backgroundColor: 'black', width: 'auto', flex: 2}} textStyle={{color: 'white'}} onPress={this.createTemplate}/>
            </View>
          </Popup>
        )}
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {resources, session} = state;

    var decision = _.get(resources.decisions.byId, ownProps.id);
    var factors = _.filter(resources.decisionFactors.byId, {decisionId: ownProps.id});

    return {decision, factors, session};
  },
  mapDispatch: {
    createDecisionTemplate, setActiveView, setProtagonistData,
    ..._.pick(resourceActions.decisions, ['trackDecisions', 'updateDecision', 'destroyDecision'])
  }
})(DecisionPopup));
