import { React, Component, _, resourceActions, Button, Text, TextInput, styleSpread, Popup, PickerInput, LabelledView, View } from '~/components/index.js'; //eslint-disable-line
import { K } from '~/styles';
import { setActiveView } from '~/redux/active-view/active-view';
import { withRouter } from 'react-router-native';
import { connect, setProtagonistData } from '@symbolic/redux';
import styles from './share-suggestion-popup.styles';

var s = styleSpread(styles);

class ShareSuggestionPopup extends Component {
  state = {};

  close = async () => {
    this.props.setProtagonistData({key: 'showedShareSuggestionPopup', value: true});

    this.props.setActiveView({data: {shareSuggestionPopupIsVisible: false}});
  }

  handleShareButtonPress = async () => {
    this.props.setProtagonistData({key: 'showedShareSuggestionPopup', value: true});

    this.props.setActiveView({data: {sharePopupIsVisible: true, shareSuggestionPopupIsVisible: false, shareSuggestionContext: undefined}});
  }

  get shareSuggestionCopy() {
    var {data} = this.props.activeView;
    var context = _.get(data, 'shareSuggestionContext');

    // var contextToCopyMap = {
    //   sharing: 'Copy about how you should share coming from sharing',
    //   addingPerspective: 'Copy about how you should share coming from added perspective',
    //   creatingFactorsOrOptions: 'Copy about how you should share coming from creating an option'
    // };

    return `Often decisions involve multiple parties/stakeholders.\n\nProtagonist is suited for use by a group - an interviewing team, a marketing team, a family...\n\nEveryone can convey their opinion and understand why a decision was made.`;//contextToCopyMap[context] || 'copy about how you should share without context';
  }

  render() {
    return (
      <Popup
        onClose={this.close}
        scrollEnabled
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <Text>{this.shareSuggestionCopy}</Text>
        <View style={{flexDirection: 'row', width: 'auto', marginTop: K.spacing * 2}}>
          <Button onPress={this.close} style={{flex: 1, marginRight: K.margin}} label={'Ok'}/>
          <Button style={{backgroundColor: 'black', flex: 2}} textStyle={{color: 'white'}} onPress={this.handleShareButtonPress} label={'try collaborating'}/>
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {activeView} = state;

    return {activeView};
  },
  mapDispatch: {
    setActiveView, setProtagonistData
  }
})(ShareSuggestionPopup));
