import {
  React, Component, _, View, Image, Button, Text, styleSpread, Tooltip, lib, PickerInput, TouchableOpacity,
  trackDecisionSheets, updateDecisionSheet, destroyDecisionSheet, Link
} from '~/components/index';

import { confirm, CopilotStepView, prepareToAnimate } from '@symbolic/rn-lib';
import { withRouter } from 'react-router-native';
import { ScrollView, TouchableWithoutFeedback, Linking } from 'react-native';
import { connect } from '@symbolic/redux';
import { setActiveView } from '~/redux/active-view/active-view';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { K } from '~/styles';

import DecisionPopup from '~/components/popups/decision-popup/decision-popup.js';
import SheetPopup from '~/components/popups/sheet-popup/sheet-popup.js';
import { viewableDecisionSheetIdsFor } from '~/lib/permissions';

import lockImage from '~/assets/lock-icon.png';
import leftIcon from '~/assets/left-arrow-dark.png';
import sortIcon from '~/assets/sort-icon.png';
import editIcon from '~/assets/edit-icon.png';
import whiteLockIcon from '~/assets/lock-icon-white.png';
import blackLockIcon from '~/assets/lock-icon-black.png';
import rightIcon from '~/assets/right-arrow-dark.png';
import closeIcon from '~/assets/x-icon-dark.png';
import compareIcon from '~/assets/collaborators.png';
import styles from './secondary-header.styles.js';
import controlsIcon from '~/assets/controls-icon.png';
import controlsIconWhite from '~/assets/x-icon-white.png';
import settingsIcon from '~/assets/settings-icon.png';
import whiteSettingsIcon from '~/assets/settings-icon-white.png';

var s = styleSpread(styles);

//TODO header
class SecondaryHeader extends Component {
  state = {};

  static buttonMap = {
    previous: {icon: leftIcon, functionKey: 'rotateFactorIdLeft'},
    next: {icon: rightIcon, functionKey: 'rotateFactorIdRight'},
    back: {icon: closeIcon, functionKey: 'toggleFactorFocusMode'},
    enterFactorFocusMode: {icon: compareIcon, functionKey: 'toggleFactorFocusMode'}
  }

  handleDisplayChange = async ({value}) => {
    if (value !== this.props.focusedResourceKey) {
      this.props.setFocusedResource({resourceKey: value !== 'complete' ? value : null});
    }
  }

  confirmDelete = async () => {
    var {decisionSheets, focusedResourceId, focusedResourceKey} = this.props;

    if (focusedResourceKey === 'decisionSheet') {
      var {title} = _.find(decisionSheets, {id: focusedResourceId});

      if (await confirm(`Delete`, `Are you sure? ${title ? `"${title}"` : 'this sheet'} will be deleted permanently`)) {
        var fallbackDecisionSheet = _.find(decisionSheets, ({id}) => id !== focusedResourceId);

        this.props.setFocusedResource({resourceId: fallbackDecisionSheet.id});

        this.props.destroyDecisionSheet({id: focusedResourceId});
      }
    }
  }

  handleSheetDelete = ({id}) => {
    if (this.props.focusedResourceKey === 'decisionSheet' || _.reject(this.props.decisionSheets, {id}).length < 2) {
      var fallbackDecisionSheet = _.find(this.props.decisionSheets, sheet => sheet.id !== id);

      this.props.setFocusedResource({resourceId: fallbackDecisionSheet.id, resourceKey: 'decisionSheet'});
    }

    this.props.setActiveView({data: {isEditingSheet: false, isCreatingSheet: false, editingSheetId: undefined}});
  }

  rotateFactorId = ({direction}) => {
    var {relevantResources} = this;

    var index = _.indexOf(_.map(relevantResources, 'id'), this.props.focusedResourceId);

    var newIndex = index + (direction === 'left' ? -1 : 1);

    if (newIndex === -1) {
      newIndex = relevantResources.length - 1;
    }
    else if (newIndex === relevantResources.length) {
      newIndex = 0;
    }

    this.props.setFocusedResource({resourceId: relevantResources[newIndex].id});
  }

  rotateFactorIdLeft = () => this.rotateFactorId({direction: 'left'});
  rotateFactorIdRight = () => this.rotateFactorId({direction: 'right'});
  toggleControlsVisible = () => this.setState({controlsVisible: !this.state.controlsVisible});

  async setActiveDecisionMode(activeDecisionMode) {
    var {focusedResourceKey} = this.props;
    prepareToAnimate();

    this.props.setActiveView({...this.props.activeView, data: {activeDecisionMode}});

    if (focusedResourceKey === null || focusedResourceKey === 'decisionSheet') {
      this.props.sort();
    }
  }

  get relevantResources() {
    return _.get(this.props, `${this.props.focusedResourceKey}s`, []);
  }

  get decisionOwner() {
    var {activeDecision, users} = this.props;

    return _.get(users, activeDecision.ownerId);
  }

  render() {
    var {activeDecision, activeView, decisionSheets, processInstances, session, focusedResourceKey, focusedResourceId, insets, isStatic, scale = 1, fontScale = 1} = this.props;
    var {controlsVisible} = this.state;
    var {activeDecisionMode} = activeView.data;
    var {user} = this.props.session;
    var isOwner = this.props.session.isLoggedIn && activeDecision.ownerId === user.id;

    if (focusedResourceKey === 'decisionSheet') {
      var activeSheet = _.find(decisionSheets, {id: focusedResourceId});
    }

    var statusToStoriesStatusMap = {
      'brainstorming': 'planning',
      'analysis': 'active',
      'finalReview': 'pending',
      'finalDecision': 'complete',
    };

    var buttonKeys = this.relevantResources.length > 1 && focusedResourceKey !== 'decisionSheet' ? ['previous', 'next'] : [];

    var displayOptions = [
      {value: 'decisionSheet', title: 'By Perspective'},
      {value: 'complete', title: 'View All'},
      {value: 'decisionFactor', title: 'By Factor'},
      {value: 'decisionOption', title: 'By Option'}
    ];

    var sheetTabsVisible = (!controlsVisible || K.isWeb) && focusedResourceKey === 'decisionSheet' && decisionSheets.length > 1;
    var isLandscape = K.orientation === 'landscape';
    var {relevantResources} = this;

    if (this.props.focusedResourceKey === 'decisionSheet') {
      relevantResources = _.sortBy(relevantResources, [
        sheet => sheet.isMasterSheet ? 0 : 1,
        sheet => sheet.editableBy === this.props.session.user.id ? 0 : 1
      ]);
    }

    var viewableSheetIds = viewableDecisionSheetIdsFor({user, decision: activeDecision, decisionSheets});

    var collaborationModeText = {
      oneViewableSheet: 'Single Perspective',
      oneEditableSheet: 'Shared Perspective',
      manyPrivateSheets: 'Private Perspectives',
      manyPublicSheets: 'Public Perspectives'
    }[activeDecision.collaborationMode];

    return (
      <View style={{
        ...styles.secondaryHeader,
        paddingLeft: K.insets(insets, 'left', styles.secondaryHeader.paddingHorizontal * scale),
        paddingRight: K.insets(insets, 'right', styles.secondaryHeader.paddingHorizontal * scale),
        borderColor: sheetTabsVisible ? 'black' : 'white',
        borderBottomWidth: sheetTabsVisible ? K.margin : 1,
        ...(isStatic ? {borderTopLeftRadius: K.borderRadius, borderTopRightRadius: K.borderRadius} : {}),
        ...(isLandscape ? {flexDirection: 'row', alignItems: 'center'} : {}),
      }}>
        {(activeSheet || activeView.data.editingSheetId) && (activeView.data.isEditingSheet || activeView.data.isCreatingSheet) && (
          <SheetPopup
            considerShowingShareSuggestionPopup={this.props.considerShowingShareSuggestionPopup}
            id={_.get(activeSheet, 'id') || activeView.data.editingSheetId}
            onSheetDelete={({id}) => this.handleSheetDelete({id})}
            triggerUndoPopup={this.props.triggerUndoPopup}
            isCreating={activeView.data.isCreatingSheet}
          />
        )}
        {this.state.isEditingDecision && (
          <DecisionPopup id={activeDecision.id} triggerUndoPopup={this.props.triggerUndoPopup} onClose={() => this.setState({isEditingDecision: false})}/>
        )}
        <TouchableWithoutFeedback onPress={() => isOwner && this.setState({isEditingDecision: true})}>
          <View style={{...styles.meta, ...(!K.isWeb ? {marginBottom: isLandscape || controlsVisible ? 0 : K.margin} : {})}}>
            <CopilotStepView style={{...(K.orientation === 'landscape' ? {} : {flex: 1}), ...(K.isWeb ? {paddingTop: K.margin * 2, paddingBottom: K.margin} : {paddingTop: isLandscape || _.size(this.props.users) <= 1 ? 0 : K.spacing})}} hideExit name='decisionTitle' order={1} text='You’ll want to edit this TITLE to match the decision you’re making'>
              <View {...s.decisionTitleContainer}  dataSet={{hover: 1, 'cursor-pointer': 1}}>
                <Text style={{...styles.title, fontSize: styles.title.fontSize * fontScale, letterSpacing: styles.title.letterSpacing * fontScale}}>{activeDecision.title || 'Untitled Decision'}</Text>
                {isOwner && (<View><Image {...s.editImage} source={editIcon}/></View>)}
              </View>
              {(_.size(this.props.users) > 1) && <Text style={{opacity: 0.6, marginTop: 2}}>{`Owner: ${_.get(this.decisionOwner, 'firstName')} ${_.get(this.decisionOwner, 'lastName')}, Mode: ${collaborationModeText}`}</Text>}
              {!!processInstances && processInstances.length > 0 && (
                <TouchableOpacity style={{marginTop: K.calc(5)}} onPress={() => {
                  var url = `http${process.env.NODE_ENV === 'production' ? 's://polydot.app' : '://localhost:19006'}/projects/${activeDecision.processInstanceId}`;

                  if (K.isWeb) {
                    window.open(url, '_blank');
                  }
                  else {
                    Linking.openURL(url);
                  }
                }}>
                  <Text>Polydot: {processInstances[0].title} →</Text>
                </TouchableOpacity>
              )}
            </CopilotStepView>
            <View {...s.statusDotContainer}>
              <View style={{...styles.statusDot, backgroundColor: lib.colors.colorFor({status: statusToStoriesStatusMap[activeDecision.status]})}}/>
            </View>
            {(activeDecision.isLocked === 1) && (
              <View style={{alignContent: 'center', justifyContent: 'center'}}>
                <View {...s.lockImageContainer}>
                  <Image {...s.lockImage} source={lockImage}/>
                </View>
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
        {!isStatic && <View style={{
          ...styles.secondRow,
          ...(isLandscape ? {
            justifyContent: 'flex-end',
            paddingTop: K.spacing,
            alignItems: 'center',
            marginLeft: K.spacing * 2,
            flex: 1
          } : {})
        }}>
          {sheetTabsVisible && (
            <ScrollView keyboardShouldPersistTaps='handled' horizontal style={{flex: 1, height: K.spacing + K.button.height, marginRight: K.spacing}} contentContainerStyle={{}}>
              <CopilotStepView {...s.tabs} name='decisionSheets' order={11} disabled={this.props.focusedResourceKey !== 'decisionSheet'} text={`Different Perspective sheets can be created for collaborators, or to view a decision from a different point of view.\n\nIf you are collaborating with others, you can either create individual perspectives for each person or just have one perspecitve and allow the team to leave notes about the rankings for discussion.`}>
                {_.map(relevantResources, ({id, title, editableBy, userId, isMasterSheet}, index) => {
                  var isFocused = id === focusedResourceId && focusedResourceKey === 'decisionSheet';
                  var hasEditPermission = _.includes([activeDecision.ownerId, userId, editableBy], session.user.id);
                  var canView = _.includes(viewableSheetIds, id) && (hasEditPermission || activeDecision.collaborationMode === 'manyPublicSheets' || isMasterSheet);

                  return (
                    <TouchableOpacity
                      style={{...styles.tabContainer, backgroundColor: isFocused ? '#000' : K.colors.doubleGray, paddingRight: K.spacing, marginRight: index === _.get(relevantResources, 'length') - 1 ? 0 : 1}}
                      onPress={() => isFocused && hasEditPermission ? this.props.setActiveView({data: {isEditingSheet: true}}) : (canView && this.props.setFocusedResource({resourceId: id}))}
                      dataSet={{[isFocused ? 'nohover' : 'hover']: 1}}
                      key={id}
                    >
                      <View style={{height: K.button.height, alignItems: 'center', flexDirection: 'row'}}>
                        <Text style={{...styles.sheetTabTitle, color: isFocused ? 'white' : 'black'}} key={id}>{title || 'Untitled Perspective '}</Text>
                        {(isFocused && hasEditPermission) || !canView && (
                          <Image
                            style={{width: K.calc(20), height: K.calc(20), marginLeft: K.margin}}
                            source={hasEditPermission && canView ? whiteSettingsIcon : (isFocused ? whiteLockIcon : blackLockIcon)}
                          />
                        )}
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </CopilotStepView>
            </ScrollView>
          )}
          <View {...s.buttonContainer}>
            {!controlsVisible && (<>
              {isOwner && (<CopilotStepView name='decisionSettings' order={9} style={{}} text={`${K.isWeb ? 'Click' : 'Tap'} to edit your decision's status and set permission levels for collaborators.`}>
                <Tooltip text='Decision settings'>
                  <View>
                    <Button icon={settingsIcon} style={{backgroundColor: K.colors.doubleGray, marginRight: K.margin}} onPress={() => this.setState({isEditingDecision: true})}/>
                  </View>
              </Tooltip>
              </CopilotStepView>)}
            </>)}
            <CopilotStepView
              text={`${K.isWeb ? 'Click' : 'Tap'} to change your view from the ranking view - where you rank individual factors - to a cumulative 'scoring' view - to a numerical view of the rankings.`}
              style={{flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap'}}
              name='sheetViewPicker'
              order={10}
            >
              {(controlsVisible || K.isWeb) && (<>
                {_.map(buttonKeys, buttonKey => {
                  var {icon, functionKey} = SecondaryHeader.buttonMap[buttonKey];

                  return <Button style={{...styles.headerButton, marginTop: !K.isWeb ? K.margin : 0}} onPress={this[functionKey]} icon={icon} key={buttonKey}/>
                })}
                <Tooltip text='Sort by net score'>
                  <Button style={{...styles.headerButton, marginRight: decisionSheets.length > 1 ? K.margin : 0, marginTop: !K.isWeb ? K.margin : 0, opacity: this.props.optionsAreSorted ? 0.2 : 1}} onPress={this.props.sort} icon={sortIcon}/>
                </Tooltip>
                {decisionSheets.length > 1 && (<View>
                  {/*HINT the 'value' for focusedResourceKey is null when showing all*/}
                  <PickerInput
                    onChange={({value}) => this.handleDisplayChange({value})}
                    value={focusedResourceKey || 'complete'}
                    buttonStyle={{...styles.pickerButton}}
                    textStyle={{...styles.pickerText}}
                    options={displayOptions}
                    showDownArrow
                    style={{...styles.picker, marginTop: !K.isWeb ? K.margin : 0}}
                  />
                </View>)}
                <PickerInput
                  key={activeDecisionMode}
                  onChange={({value}) => this.setActiveDecisionMode(value)}
                  buttonStyle={{...styles.pickerButton}}
                  textStyle={{...styles.pickerText}}
                  value={activeDecisionMode}
                  options={[
                    {value: 'joined', title: 'Summary View'},
                    {value: 'split', title: 'Ranking View'},
                    {value: 'numeric', title: 'Numerical View'}
                  ]}
                  showDownArrow
                  style={{...styles.picker, marginLeft: K.margin, marginTop: !K.isWeb ? K.margin : 0}}
                />
              </>)}
              {!K.isWeb && (<Button style={{marginTop: controlsVisible && !K.isWeb ? K.margin : 0, marginLeft: controlsVisible ? K.margin : 0, backgroundColor: controlsVisible ? 'black' : K.colors.doubleGray}} onPress={this.toggleControlsVisible} icon={controlsVisible ? controlsIconWhite : controlsIcon}/>)}
            </CopilotStepView>
           </View>
        </View>}
      </View>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({
  mapState: (state, ownProps) => {
    var activeDecision = _.get(state.resources.decisions.byId, ownProps.decisionId);

    return ({
      activeDecision,
      decisionSheets: _.filter(state.resources.decisionSheets.byId, {decisionId: ownProps.decisionId}),
      decisionFactors: _.filter(state.resources.decisionFactors.byId, {decisionId: ownProps.decisionId}),
      decisionOptions: _.filter(state.resources.decisionOptions.byId, {decisionId: ownProps.decisionId}),
      processInstances: _.filter(state.resources.processInstances.byId, {id: activeDecision.processInstanceId}),
      users: state.resources.users.byId,
      activeView: state.activeView,
      session: state.session,
      test: state
    })
  },
  mapDispatch: {trackDecisionSheets, updateDecisionSheet, destroyDecisionSheet, setActiveView}
})(SecondaryHeader)));
