import { K } from '~/styles';

var s = {};

s.pickerButton = {
  paddingHorizontal: K.paddingHorizontal,
  backgroundColor: K.colors.doubleGray,
  height: K.button.height
};
s.titleContainer = {
  width: '100%',
  backgroundColor: 'white',
  borderRadius: K.borderRadius,
  paddingBottom: 10
};
s.statusContainer = {
  outerView: {
    flexGrow: 1, padding: 0, marginBottom: K.spacing * 2, marginTop: K.spacing, paddingBottom: 0
  }
}

export default s;
