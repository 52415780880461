import React, { Component } from 'react';
import { Text, Loading, Button, TouchableOpacity } from '~/components';
import { View, Image, Pressable } from 'react-native';
import { Popup } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { K } from '~/styles';
import { Video } from 'expo-av';
import { setActiveView } from '~/redux/active-view/active-view';

import lib from '@symbolic/lib';
import backIcon from '~/assets/left-arrow.png';
import videoIcon from '~/assets/help-popup/video-icon.png';
import tourIcon from '~/assets/help-popup/tour-icon.png';
import helpIcon from '~/assets/help-icon.png';
import documentIcon from '~/assets/help-popup/document-icon.png';
import helpList from './help-list';

import _ from 'lodash';

class HelpPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewKey: null,
      loadingVideo: true
    };

    this.getVideoRef = ref => this.videoRef = ref;
  }

  showBasicsPopup = () => {
    this.close();

    this.props.showBasicsPopup();
  }

  startTour = () => {
    this.close();

    this.props.startTour();
  }

  close = () => {
    this.props.setActiveView({data: {helpPopupIsVisible: false}});
  }

  render() {
    var {viewKey, loadingVideo} = this.state;

    return (
      <Popup scrollEnabled height={K.orientation === 'landscape' ? '75%' : 400} onClose={this.close}>
        {viewKey && (
          <Button icon={backIcon} style={{marginBottom: K.spacing}} onPress={() => this.setState({viewKey: null, loadingVideo: true})}/>
        )}
        {!viewKey && (
          <View>
            {/* <View style={{backgroundColor: 'black', alignSelf: 'flex-end', paddingRight: K.spacing * 4.5, left: K.spacing * 2, alignItems: 'flex-end', padding: K.spacing, borderRadius: K.borderRadius, marginBottom: K.spacing}}>
              <Button style={{backgroundColor: 'white'}} icon={helpIcon}/>
            </View> */}
            {_.map([
              // {key: 'basics', icon: documentIcon, title: 'The basics', color: lib.colors.fun[2], description: 'An overview of the fundamentals.', onPress: this.showBasicsPopup},
              {key: 'howToVideo', icon: videoIcon, title: 'Short video to learn more', color: lib.colors.fun[0], style: {marginTop: K.spacing, marginBottom: K.spacing * 2}},
              {key: 'tour', icon: tourIcon, title: 'Guided tour', color: lib.colors.fun[1], description: 'Tooltips that walk you through the interface.', onPress: this.startTour},
              // {key: 'sharingVideo', icon: videoIcon, title: 'Sharing video', color: lib.colors.fun[0], description: `2-minute video on collaboration features.`},
              {key: 'useCases', icon: documentIcon, title: 'What is this app for?', color: lib.colors.fun[2]},
              {key: 'list', icon: documentIcon, title: 'Features and tips list', color: lib.colors.fun[2], description: 'A summary of what Protagonist is capable of and intermediate/advanced info.'}
            ], (view) => (
              <TouchableOpacity
                style={{flexDirection: 'row', backgroundColor: K.colors.gray, borderRadius: K.borderRadius, marginBottom: K.margin, ...view.style}}
                onPress={view.onPress || (() => this.setState({viewKey: view.key}))}
                key={view.key}
              >
                <View style={{flex: 1, padding: K.spacing}}>
                  <Text style={{textTransform: 'uppercase', opacity: 1, letterSpacing: K.calcFont(1.5), fontWeight: 'bold'}}>{view.title}</Text>
                  {view.description && (
                    <Text style={{marginTop: K.margin, opacity: 0.6}}>{view.description}</Text>
                  )}
                </View>
                <View style={{justifyContent: 'center', alignItems: 'center', alignSelf: 'stretch', width: K.button.width, borderTopRightRadius: K.borderRadius, borderBottomRightRadius: K.borderRadius, backgroundColor: view.color}}>
                  <Image source={view.icon} style={{width: K.calc(16), height: K.calc(16)}}/>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        )}
        {viewKey === 'howToVideo' && (
          <>
            {loadingVideo && (
              <Loading text={'Loading Video'}/>
            )}
            <Video
              ref={this.getVideoRef}
              resizeMode='contain'
              style={{width: '100%', height: 300}}
              source={{uri: 'https://symbolic-public.s3.amazonaws.com/protagonist/help/protagonist-demo.mp4'}}
              onLoad={() => {
                this.setState({loadingVideo: false});
                if (this.videoRef && !K.isWeb) this.videoRef.presentFullscreenPlayer();
              }}
              useNativeControls
              shouldPlay
              isMuted
            />
          </>
        )}
        {viewKey === 'sharingVideo' && (
          <>
            {loadingVideo && (<Loading text={'Loading Video'}/>)}
            <Video
              ref={this.getVideoRef}
              resizeMode='contain'
              style={{width: '100%', height: 300}}
              source={{uri: 'https://symbolic-public.s3.amazonaws.com/protagonist/help/protagonist-demo.mp4'}}
              onLoad={() => {
                this.setState({loadingVideo: false});
                if (this.videoRef && !K.isWeb) this.videoRef.presentFullscreenPlayer();
              }}
              useNativeControls
              shouldPlay
              isMuted
            />
          </>
        )}
        {viewKey === 'list' && (
          <Pressable dataSet={{nohover: 1}}>
            {_.map(helpList, category => (
              <View key={category.title} style={{marginTop: K.spacing, marginBottom: K.spacing * 2}}>
                <View style={{marginBottom: K.spacing}}>
                  <Text style={{marginLeft: K.spacing, textTransform: 'uppercase', fontSize: K.calcFont(16), letterSpacing: K.calcFont(2), fontWeight: 'bold'}}>{category.title}</Text>
                </View>
                <View>
                  {_.map(category.items, (item, i) => (
                    <View key={item.title} style={{marginBottom: K.spacing, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
                      <View style={{backgroundColor: lib.colors.fun[i], borderTopLeftRadius: K.borderRadius, borderTopRightRadius: K.borderRadius, padding: K.spacing}}>
                        <Text style={{textTransform: 'uppercase', opacity: 1, fontWeight: 'bold'}}>{item.title}</Text>
                      </View>
                      <Text style={{opacity: 0.9, padding: K.spacing}}>{item.description}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </Pressable>
        )}
        {viewKey === 'useCases' && (
          <View>
            <Pressable dataSet={{nohover: 1}}>
              <Text>{`Protagonist is a decision-structuring app that enables individuals and teams to make better decisions and share those decisions with others; colleagues, partners, family...in ways that are more likely to be understood.

Within minutes of logging in, you can begin getting value out of Protagonist by structuring an important decision for your work or your personal life, using either the IOS or web version of the app.

A LITTLE STRUCTURE GOES A LONG WAY
Important decisions are often poorly made despite the time spent considering them. particularly group decisions, because there is a lack of structure in the process.

Often we make even important decisions with little structure…  We all know the drill: we go around the room, debate a few points, often superficially (or sometimes one point to the exclusion of others), bring evidence in spurts and starts, discount or even forget certain criteria. In the end, we often make poor decisions because without a road map and a record of our thinking – our minds aren’t able to digest and process and hold on to all the factors.

Protagonist provides a lightweight framework for a clear, visual process that can be used to deepen understanding and keep track of what is most important through a decision making process.

STIMULATING A CREATIVE CULTURE
People often have ideas at work, but get stuck because they aren’t sure how to bring them forward, or where they fit in the overall picture of the company’s priorities. Protagonist gives a whole team the ability to assess their ideas against other ideas before they bring them forward, improve them where they are weak, and bring them forward in a professional way.

GOOD FOR BUSINESS OR FOR IMPORTANT PERSONAL DECISIONS
Protagonist works for both business decisions (who to hire, which business improvement to make…) and personal decisions (which college to go to, where to buy a home, which candidate to vote for…)

Individuals or teams can quickly organize their thoughts, weigh various factors, compare options, and identify the best path forward.

PROTAGONIST’S FEATURES...
- Structure the factors in a decision-making process so that they can be more carefully analyzed.
- Make the primary criteria for a decision clear and transparent and help assure each factor is given proper weight by visualizing it.
- Share decisions and let others provide their perspective, creating a shared playing field and a visual way of relating to the decision.

The web version is available at https://protag.app.
- Provide a basis for effective discussion - going deeper rather than just wider as a decision is made. Through this simple but structured visual exercise, the best decisions rise to the top and can be compared against other high-ranking alternatives.
- Help make a decision make sense to others and help them have confidence in the decision once it is finalized.
- Create a proposal or report based on the information behind the decision.

Enjoy `}</Text>
            </Pressable>
          </View>
        )}
      </Popup>
    );
  }
}

export default connect({mapDispatch: {setActiveView}})(HelpPopup);
