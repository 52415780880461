import { React, Component, _, resourceActions, Button, Text, TextInput, styleSpread, View, updateDecision } from '~/components/index.js'; //eslint-disable-line
import { Label, Popup, confirm } from '@symbolic/rn-lib';
import { K } from '~/styles';
import FactorWeightBar from './factor-weight-bar/factor-weight-bar.js';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { Keyboard } from 'react-native';
import styles from './factor-popup.styles';

var s = styleSpread(styles);

class FactorForm extends Component {
  handleInputChange = ({value, key}) => {
    var {decision, factor} = this.props;

    if (this.isOwner && !decision.isLocked) {
      this.props.updateDecisionFactor({id: factor.id, props: {[key]: value}});

      if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});
    }
  }

  handleDeletePress = async () => {
    var {factor} = this.props;
    var {id, title} = factor;

    // if (await confirm('', `Are you sure you want to DELETE ${title ? `the factor "${title}"` : 'this factor'}?`)) {
      this.delete();
      this.close();

      this.props.triggerUndoPopup({event: {
        message: `${title ? `The factor "${title}"` : 'That factor'} has been deleted`,
        action: () => {
          this.props.updateDecisionFactor({id, props: {deleted: 0}});

          this.props.trackDecisionFactors({decisionFactors: [factor]});
        }
      }});
    // }
  }

  handlePopupClose = type => {
    var couldDelete = this.props.isCreating && (!this.props.factor.title && !this.props.factor.notes);

    if (type !== 'done' && ((couldDelete && type !== 'confirm') || type === 'cancel')) {
      this.delete();
    }
    else if (this.props.isCreating && this.props.focusedResourceKey === 'decisionFactor') {
      this.props.setFocusedResource({resourceKey: 'decisionFactor', resourceId: this.props.factor.id});
    }

    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => this.close(), K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile
  }

  delete = () => {
    setTimeout(() => {
      this.props.destroyDecisionFactor({id: this.props.factor.id});

      setTimeout(() => this.props.sort());
    });
  }

  close = () => this.props.history.push(`/decisions/${this.props.decision.id}`)

  get isOwner() {
    var {decision, session} = this.props;

    return decision.ownerId === session.user.id;
  }

  render() {
    var {factor, decision, sort, isCreating} = this.props;

    return (
      <Popup
        cancelConfirmMessage={`Are you sure? "${factor.title}" will be deleted permanently.`}
        deleteWithoutConfirming={!(factor.title || factor.notes)}
        onClose={this.handlePopupClose}
        cancelOnDelete
        scrollEnabled
        closeOnEnter
        useCloseButton
        height={350}
      >
        <View style={{opacity: this.isOwner ? 1 : 0.7}}>
          {this.isOwner && (
            <View style={{flexDirection: 'row', width: 'auto', marginBottom: K.spacing * 2}}>
              {isCreating ? (
                <Button label='Cancel' style={{flex: 1, marginRight: K.margin}} onPress={() => this.handlePopupClose('cancel')}/>
              ) : (
                <Button disabled={decision.isLocked} style={{opacity: decision.isLocked ? 0.5 : 1, flex: 1, marginRight: K.margin, backgroundColor: K.colors.deleteRed}} onPress={this.handleDeletePress} label='Delete Factor'/>
              )}
              <Button label='Done' style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={() => this.handlePopupClose('done')}/>
            </View>
          )}
          <TextInput
            grayLabelledView
            editable={this.isOwner && !decision.isLocked}
            label='title'
            autoFocus={!factor.title}
            value={factor.title || ''}
            onChange={({value}) => this.handleInputChange({key: 'title', value})}
          />
          <TextInput
            multiline
            grayLabelledView
            standardAutoheightStyles
            editable={this.isOwner && !decision.isLocked}
            label='Background Info'
            value={factor.notes || ''}
            onChange={({value}) => this.handleInputChange({key: 'notes', value})}
          />
          <>
            <Label style={{paddingLeft: K.spacing, paddingBottom: K.calc(8)}}>Factor Importance</Label>
            <FactorWeightBar disabled={!this.isOwner || decision.isLocked} {...{factor, decision, sort}}/>
          </>
          <Text style={K.explanationText}>
  {`To effectively compare options, it is important to evaluate them based on several factors. Each column is a “factor,” which is a different aspect of the options being compared.

Factor names should be positive, so that higher values always indicate a better option (e.g., "cheapness" rather than "cost"). Factor names should be short - you can put more explanation in the "Background Info" section above.

Close this popup and drag the colored sliders to rate each option by your factors.`}
          </Text>
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var decision;
    var {resources, session} = state;
    var factor = _.get(resources.decisionFactors.byId, ownProps.id);
    var decision = _.get(resources.decisions.byId, _.get(ownProps, 'match.params.decisionId'));

    if (!decision) {
      factor = {};

      ownProps.history.push('/404/decision');
    }
    else if (!factor) {
      factor = {};

      ownProps.history.push(`/404/decisionFactor/${decision.id}`);
    }

    return {factor, decision, session};
  },
  mapDispatch: {
    ..._.pick(resourceActions.decisionFactors, ['trackDecisionFactors', 'updateDecisionFactor', 'destroyDecisionFactor']),
    updateDecision
  }
})(FactorForm));
