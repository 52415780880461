import { K } from '~/styles';

var s = {};

s.secondaryHeader = {
  backgroundColor: K.colors.gray,
  paddingHorizontal: K.spacing,
  borderBottomWidth: 1,
  borderColor: 'white'
};
  s.meta = {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: K.button.height + K.spacing,
    marginBottom: K.spacing
  };
    s.decisionTitleContainer = {
      // flex: 1,
      flexDirection: 'row',
      marginRight: K.spacing,
      alignItems: 'center'
    };
      s.editImage = {
        width: K.calc(24),
        height: K.calc(22),
        opacity: 0.5,
      };
    s.statusDotContainer = {
      marginLeft: K.spacing,
    };
      s.statusDot = {
        width: K.spacing,
        height: K.spacing,
        borderRadius: K.spacing,
      };
    s.lockImageContainer = {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignContent: 'flex-end',
      marginLeft: K.spacing
    };
      s.lockImage = {
        width: K.calc(13),
        height: K.calc(18)
      };

  s.controlsContainer = {
    flexDirection: 'row',
    alignItems: 'center'
  };
  s.picker = {
    height: K.button.height
  };
  s.pickerButton = {
    paddingHorizontal: K.paddingHorizontal,
    backgroundColor: K.colors.doubleGray,
    height: K.button.height
  };
  s.pickerText = {

  };
  s.button = {
    marginLeft: K.margin,
    backgroundColor: K.colors.doubleGray,
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: 9
  };
  s.headerButton = {
    ...s.button,
    marginLeft: 0,
    width: K.button.width,
    height: K.button.height,
    borderRadius: K.borderRadius
  }
  s.secondRow = {
    flexDirection: 'row', // K.isWeb ? 'row' : 'column',
    alignItems: K.isWeb ? 'flex-end' : 'flex-end',
    justifyContent: K.isWeb ? 'flex-end' : 'flex-end',
    // marginBottom: K.spacing,
  };
  // TODO: set min height
  s.tabs = {
    flexDirection: 'row',
    alignItems: 'flex-end'
  };
    s.tabContainer = {
      flexDirection: 'row',
      alignItems: 'flex-start',
      backgroundColor: K.colors.doubleGray,
      borderTopLeftRadius: K.borderRadius,
      borderTopRightRadius: K.borderRadius,
      // paddingBottom: K.spacing,
      height: '100%'
    };
    s.tab = {
      marginRight: 1,
      paddingHorizontal: 0,
      width: K.calc(80)
    };
      s.sheetTabTitle = {
        paddingLeft: K.spacing
      };
    s.tabButton = {
      ...s.button,
      top: 1
    };
    s.tabIcon = {
      // marginRight: K.margin,
      justifyContent: 'center',
      alignItems: 'center',
      width: 18,
      height: 18,
      borderRadius: 9
    };
    s.createSheetButton = {
      backgroundColor: K.colors.doubleGray,
      marginRight: K.margin
    };
  s.buttonContainer = {
    flexDirection: 'row',
    marginBottom: K.spacing
  };
    s.helpButton = {
      backgroundColor: K.colors.doubleGray
    };
    s.title = {
      fontSize: K.isWeb ? 18 : 14,
      letterSpacing: K.isWeb ? 0.8 : 0.4,
      fontWeight: 'bold',
      paddingRight: K.spacing,
      paddingBottom: 0,
      alignContent: 'center',
      justifyContent: 'center',
    };

export default s;
