import { K } from '~/styles';

var s = {};

s.K = {padding: K.spacing, gapSize: 1, borderRadius: K.calc(50 / 2), barHeight: K.bar.height, maxColWidth: K.bar.minWidth, factorHeight: K.calc(150)};

s.tableHeaderCell = {
  backgroundColor: '#f0f0f0',
  marginRight: s.K.gapSize,
  flexDirection: 'column',
  // borderBottomRightRadius: K.bar.borderRadius,
  // borderBottomLeftRadius: K.bar.borderRadius
};
  s.rotatedTextContainer = {
    flexDirection: 'column'
  }
  s.factorText = {
    ...K.fonts.su
  };
  s.sortArrowImage = {
    width: K.calc(10),
    height: K.calc(5)
  };
  s.notesImage = {
    width: K.calc(12),
    height: K.calc(12),
    opacity: 0.5
  };
  s.compareFactorButton = {
    position: 'absolute',
    top: K.spacing / 2,
    right: K.spacing / 2,
    width: 15,
    height: 15,
    paddingHorizontal: 3,
    backgroundColor: 'black',
    zIndex: 10
  };

export default s;
