import { React, Component, _, resourceActions, Button, Text, TextInput, View, LabelledView, PickerInput, styleSpread, Popup, lib, Comments, updateDecision } from '~/components/index.js'; //eslint-disable-line
import { K } from '~/styles';
import { setActiveView, setEvent } from '~/redux/index';
import { withRouter } from 'react-router-native';
import { confirm } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { Keyboard } from 'react-native';
import styles from './option-popup.styles';

var s = styleSpread(styles);

class OptionPopup extends Component {
  handleInputChange({key, value}) {
    var {decision, option} = this.props;

    if (!decision.isLocked) {
      this.props.updateDecisionOption({id: option.id, props: {[key]: value}});

      if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});
    }
  }

  handleDeletePress = async () => {
    var {decision, option} = this.props;
    var {id, title} = option;

    if (decision.isLocked) {
      alert('This decision is locked and cannot be edited.');
    }
    else {
      // if (await confirm('', `Are you sure you want to DELETE ${title ? `${title}` : 'this option'}?`))
      this.delete();
      this.close();

      this.props.triggerUndoPopup({event: {
        message: `${title ? `The option "${title}"` : 'That option'} has been deleted`,
        action: () => {
          this.props.updateDecisionOption({id, props: {deleted: 0}});

          this.props.trackDecisionOptions({decisionOptions: [option]});
        }
      }});
    }
  }

  handlePopupClose = (mode) => {
    if (mode !== 'done' && (mode === 'cancel' || (mode !== 'confirm' && this.isCreating && !this.props.option.title && !this.props.option.notes))) {
      this.delete();
    }
    else if (this.isCreating && this.props.focusedResourceKey === 'decisionOption') {
      this.props.setFocusedResource({resourceKey: 'decisionOption', resourceId: this.props.option.id});
    }

    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => this.close(), K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile
  }

  onCreateComment = () => {
    var {decision} = this.props;

    if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});
  }

  delete = () => {
    setTimeout(() => {
      this.props.destroyDecisionOption({id: this.props.option.id});
    });
  }

  close = () => this.props.history.push(`/decisions/${this.props.decision.id}`);

  get isCreating() {
    return this.props.location.pathname.includes('create');
  }

  get canDelete() {
    return !!this.props.editable;
  }

  render() {
    var {option, decisionSheetId, decision, editable} = this.props;

    var statusOptions = [
      {value: 'tentative', title: 'Tentative - just an idea', color: lib.colors.colorFor({status: 'planning'})},
      {value: 'candidate', title: 'Candidate - serious consideration', color: lib.colors.colorFor({status: 'active'})},
      {value: 'final', title: 'Selected - accepted, chosen', color: lib.colors.colorFor({status: 'complete'})},
      {value: 'ruledOut', title: 'Eliminated - no longer a consideration', color: lib.colors.colorFor({status: 'archived'})}
    ];

    return (
      <Popup
        deleteWithoutConfirming={!(option.title || option.notes)}
        cancelConfirmMessage={'Delete this option? This cannot be undone'}
        cancelOnDelete={this.canDelete}
        closeOnEnter
        scrollEnabled
        useCloseButton
        onClose={this.handlePopupClose}
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        {!!editable && (
          <View style={{flexDirection: 'row', width: 'auto', marginBottom: K.spacing * 2}}>
            {this.isCreating ? (
              <Button label='Cancel' style={{flex: 1, marginRight: K.margin}} onPress={() => this.handlePopupClose('cancel')}/>
            ) : (
              this.canDelete && (
                <Button
                  style={{backgroundColor: K.colors.deleteRed, flex: 1, marginRight: K.margin, opacity: decision.isLocked ? 0.5 : 1}}
                  onPress={this.handleDeletePress}
                  disabled={decision.isLocked}
                  label='Delete Option'
                />
              )
            )}
            <Button label='Done' style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={() => this.handlePopupClose('done')}/>
          </View>
        )}
        <TextInput
          editable={!!editable && !decision.isLocked}
          label='title'
          grayLabelledView
          style={{width: '100%'}}
          value={option.title || ''}
          autoFocus={!option.title && K.orientation !== 'landscape'}
          onChange={({value}) => this.handleInputChange({key: 'title', value})}
        />
        <TextInput
          editable={!!editable && !decision.isLocked}
          multiline
          grayLabelledView
          label='Background Info'
          standardAutoheightStyles
          value={option.notes || ''}
          onChange={({value}) => this.handleInputChange({key: 'notes', value})}
        />
        <LabelledView label={'Status'} styles={{...styles.statusContainer}}>
          <PickerInput
            enabled={!!editable && !decision.isLocked}
            showDownArrow
            value={option.status}
            onChange={({value}) => this.handleInputChange({key: 'status', value})}
            options={statusOptions}
            buttonStyle={{paddingHorizontal: 15}}
          />
        </LabelledView>
        <Comments
          placeholder='DISCUSS / SUMMARIZE YOUR OPINION'
          onCreate={this.onCreateComment}
          creationProps={{decisionSheetId, orgId: decision.orgId}}
          decisionOptionId={option.id}
          resourceId={decision.id}
          type={'decisionOption'}
          additionalUsers={this.props.users}
          autoFocus={false}
        />
        <Text style={K.explanationText}>
{`Protagonist helps answer the question “Which option should we choose?” Each row is an option. Options are the possible courses of action you could take.

Close this popup and drag the colored sliders to rate each option.`}
        </Text>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var options;
    var {resources} = state;
    var decision = _.get(resources.decisions.byId, _.get(ownProps, 'match.params.decisionId'));
    var option = _.get(resources.decisionOptions.byId, ownProps.id);
    var sheet = _.get(resources.decisionSheets.byId, ownProps.decisionSheetId);

    if (!decision) {
      ownProps.history.push('/404/decision');
    }
    else if (!option || option.decisionId !== decision.id) {
      ownProps.history.push(`/404/decisionOption/${decision.id}`);
    }
    else if (!sheet) {
      ownProps.history.push(`/404/decisionSheet/${decision.id}`);
    }
    else {
      options = _.filter(resources.decisionOptions.byId, {decisionId: decision.id});
    }

    if (!(decision && option && sheet)) {
      decision = {};
      option = {};
      sheet = {};
    }

    return {option, options, decision, sheet, comments: _.get(resources, 'comments.byId', {}), users: _.get(state, 'resources.users.byId')};
  },
  mapDispatch: {
    setActiveView, setEvent, updateDecision,
    ..._.pick(resourceActions.decisionOptions, ['trackDecisionOptions', 'updateDecisionOption', 'destroyDecisionOption'])
  }
})(OptionPopup));
