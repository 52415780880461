import { React, Component, _, View, resourceActions, logAnalyticsEvent } from '~/components/index.js'; //eslint-disable-line
import { connect } from '@symbolic/redux';
import api from '../../../../lib/api';

import { decisionTemplateCategories } from '@symbolic/rn-lib';

class TemplateLinkView extends Component {
  async componentDidMount() {
    var {templateKey} = this.props.match.params;

    var templates = _.flatMap(_.flatMap(decisionTemplateCategories, 'content.subCategories'), 'templates');

    var template = _.find(templates, {key: templateKey});

    if (!template) {
      this.props.history.push(`/404/template`);
    }
    else {
      var {title, factorTitles=null, factorWeights=[]} = template;

      logAnalyticsEvent('template_decision_created');

      var decision = await api.create('decision', {title, orgId: this.props.session.user.personalOrgId, ownerId: this.props.session.user.id, status: 'brainstorming', wasModified: 0, collaborationMode: 'manyPrivateSheets'});

      if (factorTitles) {
        await Promise.all(_.map(factorTitles, async (title, index) => {
          var weight = factorWeights[index] || 1;

          await api.create('decisionFactor', {title, decisionId: decision.id, weight, orgId: this.props.session.user.personalOrgId});
        }));

        await api.create('decisionOptions', _.map(template.optionTitles, title => ({title: title, decisionId: decision.id, orgId: this.props.session.user.personalOrgId})));
      }

      var decisionSheet = await api.create('decisionSheet', {
        title: 'Main Perspective',
        userId: this.props.session.user.id,
        editableBy: this.props.session.user.id,
        decisionId: decision.id,
        orgId: decision.orgId,
        isMasterSheet: true
      });

      await this.props.trackDecisionSheets({decisionSheets: [decisionSheet]});
      await this.props.trackDecisions({decisions: [decision]});

      setTimeout(() => {
        this.props.history.push(`/decisions/${decision.id}`);
      });
    }
  }

  render() {
    return (
      <View></View>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.decisions, ['trackDecisions']),
    ..._.pick(resourceActions.decisionSheets, ['trackDecisionSheets'])
  }
})(TemplateLinkView);
