import {K} from '~/styles';

var s = {};

s.K = {padding: K.spacing, gapSize: 1, barHeight: K.bar.height, maxColWidth: K.bar.minWidth, factorHeight: K.calc(150)};

s.decisionView = {
  flex: 1
};
  s.overlay = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1
  };
  s.meta = {
    flexDirection: 'row',
    paddingVertical: 10
  };
    s.notesLabelledViewStyles = {
      outerView: {
        flex: 1
      }
    };
      s.notesInputStyle = {
        margin: 0,
        minHeight: 50,
        borderBottomWidth: 0
      };
    s.modeLabelledViewStyles = {
      outerView: {
        width: 80
      }
    };
    s.modeImage = {
      width: 30,
      height: 25
    };
  s.table = {
    flex: 1,
    flexDirection: 'row'
  };
    s.horizontalScrollView = {
      overflowX: 'overlay'
    };
    s.tableHeader = {
      flexDirection: 'row',
      marginBottom: s.K.gapSize
    };
    s.tableRow = {
      flexDirection: 'row',
      marginBottom: s.K.gapSize
    };
      s.tableCell = {
        justifyContent: 'center',
        width: s.K.maxColWidth,
        minHeight: s.K.barHeight,
        marginRight: s.K.gapSize
      };
  s.notesContainer = {
    padding: s.K.padding
  };
  s.notesText = {
    letterSpacing: 1
  };
  s.footer = {
    height: K.isWeb ? K.button.height : 60,
    width: '100%',
    flexDirection: 'row'
  };
    s.addButtonContainer = {
    };
      s.addButton = {
        backgroundColor: 'black',
        width: K.calc(70),
        height: K.calc(70),
        borderRadius: K.calc(70 / 2),
      };
  s.screenshotContent = {
    display: 'none'
  };

s.notesInput = {
  width: '100%',
  borderBottomWidth: 0,
  paddingLeft: K.padding,
  paddingRight: 5,
  paddingBottom: 5,
  paddingTop: 3,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  height: 'auto',
  minHeight: 75,
  paddingTop: 10
}

export default s;
