import { K } from '~/styles';

var s = {};

s.pickerButton = {
  paddingHorizontal: K.paddingHorizontal,
  backgroundColor: K.colors.doubleGray,
  height: K.button.height
};

export default s;
