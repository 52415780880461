import { React, Component, _, View, styleSpread, Button, Popup, Text } from '~/components/index.js'; //eslint-disable-line
import { setActiveView, setEvent } from '~/redux/index.js';
import { Image, Pressable, Dimensions } from 'react-native';
import { withKeyEvents } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import lib from '@symbolic/lib';
import { K } from '~/styles';
import xIcon from '~/assets/x-icon.png';

import styles from './undo-popup.styles';

var s = styleSpread(styles);

class UndoPopup extends Component {
  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.triggerClose);
  }

  setTimer = () => {
    clearTimeout(this.triggerClose);

    this.triggerClose = setTimeout(this.close, 10000);
  }

  undo = () => {
    var {action} = this.props.event;

     if (action) action();

     this.close();
  }

  close = () => {
    this.props.setActiveView({data: {undoPopupIsVisible: false}});

    this.props.setEvent({event: {}});
  }

  handleKeyDown = event => {
    if (lib.event.keyPressed(event, 'ctrlcmd') && event.key === 'z') {
      this.undo();
    }
  }

  render() {
    var deviceWidth = Dimensions.get('window').width;
    var {event} = this.props;

    var platformStyles = {
      ...(K.isWeb ? {minWidth: 300} : {width: deviceWidth - K.spacing * 2}),
    };

    return (
      <View
        style={{...styles.container, ...platformStyles}}
      >
        <Text style={{...styles.message, ...(K.isWeb ? {} : {maxWidth: deviceWidth - K.spacing * 6 - 80 - 20})}}>{event.message}</Text>
        <Button onPress={this.undo} {...s.undoButton}>
          <Text>Undo</Text>
        </Button>
        <Button onPress={this.close} {...s.closeButton}>
          <Image source={xIcon} {...s.closeIcon}/>
        </Button>
      </View>
    );
  }
}

export default connect({
  mapState: state => ({session: state.session, event: state.event}),
  mapDispatch: {setActiveView, setEvent}
})(withKeyEvents(UndoPopup));
