import { React, Component, _, resourceActions, Button, Text, TextInput, styleSpread, Popup, PickerInput, LabelledView, View } from '~/components/index.js'; //eslint-disable-line
import { K } from '~/styles';
import { setActiveView } from '~/redux/active-view/active-view';
import * as Linking from 'expo-linking';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import styles from './request-review-popup.styles';
import * as StoreReview from 'expo-store-review';

var s = styleSpread(styles);

class RequestReviewPopup extends Component {
  state = {
    mode: 'initial'
  };

  close = async () => {
    this.props.setProtagonistData({key: 'showedRequestReviewPopup', value: true});

    this.props.setActiveView({data: {requestReviewPopupIsVisible: false}});
  }

  handleWillingToGiveFeedback = async () => {
    this.close();

    Linking.openURL('mailto:support@symbolicframeworks.com');
  }

  handleWillingToRateApp = async () => {
    if (await StoreReview.isAvailableAsync() && await StoreReview.hasAction()) {
      await StoreReview.requestReview();

      this.setState({mode: 'done'});
      setTimeout(() => this.close, 1000);
    }
  }

  render() {
    var {mode} = this.state;

    var text = {
      initial: 'Enjoying Protagonist?',
      requestFeedback: 'Would you mind giving us some feedback?',
      requestRating: 'How about rating us on the App Store?',
      done: 'Thanks!'
    }[mode];

    return (
      <Popup
        onClose={this.close}
        scrollEnabled
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <Text>{text}</Text>
        <View style={{flexDirection: 'row', width: 'auto', marginTop: K.spacing * 2}}>
          {mode === 'initial' && (
            <>
              <Button style={{flex: 1, marginRight: K.margin}} onPress={() => this.setState({mode: 'requestFeedback'})} label={'not really'}/>
              <Button style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={() => this.setState({mode: 'requestRating'})} label={'yes'}/>
            </>
          )}
          {mode === 'requestFeedback' && (
            <>
              <Button onPress={this.close} style={{flex: 1, marginRight: K.margin}} label={'no thanks'}/>
              <Button style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={this.handleWillingToGiveFeedback} label={'ok, sure'}/>
            </>
          )}
          {mode === 'requestRating' && (
            <>
              <Button onPress={this.close} style={{flex: 1, marginRight: K.margin}} label={'no thanks'}/>
              <Button style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={this.handleWillingToRateApp} label={'yes'}/>
            </>
          )}
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {activeView} = state;

    return {activeView};
  },
  mapDispatch: {
    setActiveView,
  }
})(RequestReviewPopup));
