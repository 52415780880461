import { React, _, styleSpread, View, Image, Text, updateDecisionFactors } from '../../../../index.js'; //eslint-disable-line
import { TouchableOpacity, FlatList } from 'react-native';
import { CopilotStepView } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { K } from '~/styles';

import DraggableFlatList from 'react-native-draggable-flatlist';
import commentsIcon from '~/assets/comments-icon.png';
import styles from './column-headers.styles';

var s = styleSpread(styles);

class ColumnHeaders extends React.Component {
  handleDragEnd = ({data}) => {
    if (this.props.session.user.id === this.props.decision.ownerId) {
      var ids = _.map(data, 'id');

      this.props.updateDecisionFactors({updates: _.map(ids, (id, rank) => ({props: {rank}, where: {id}}))});
    }
    else {
      alert('Whoops! Only the decision owner can reorder factors.');
    }
  }

  renderItem = ({item, index, drag}) => {
    var {relevantResources} = this;
    var {history, decision, mode, renderForPdf, sheets, insets, scale, fontScale, sortByFactorId, focusedResourceKey, focusedResourceId, setActiveView, session} = this.props;
    var resource = _.find(relevantResources, {id: item.id});

    var isLandscape = renderForPdf || mode !== 'joined';
    var height = isLandscape ? K.calc(62) : K.calc(150);

    var isSortFactor = (sortByFactorId === resource.id && !!focusedResourceKey);
    var title = resource.shortTitle;

    if (resource.id && focusedResourceKey === 'decisionSheet' && resource.weight > 1 && mode !== 'joined') title = `${title} - ${resource.weight}x`;

    var paddingHorizontal = scale * (isLandscape ? (resource.width < 100 ? K.margin : K.spacing) : 20);
    var textArea = {width: (isLandscape ? resource.width : height) - paddingHorizontal, height: isLandscape ? height : resource.width - 20};
    var maxTitleLength = (textArea.width / (10 * fontScale)) * Math.max(1, Math.round(textArea.height / 16));
    var fontSize = K.calcFont(Math.max(Math.min(12, 12 * (maxTitleLength / title.length)), 10));
    var isLastFactor = index === relevantResources.length - 1;
    var isFirstFactor = index === 0;
    var hasNotes = _.get(resource.notes, 'length') > 0;
    var isAllSheetsInitial = !focusedResourceKey && !title && !resource.id;
    var width = resource.id === null ? K.insets(insets, 'left', resource.width) : resource.width;

    var copilotProps = null;

    if (focusedResourceKey === 'decisionFactor') {
      copilotProps = {
        text: `${K.isWeb ? 'Click' : 'Tap'} to view more and manage this perspective. \n\nPerspectives are used to compare and contrast differing opinions, by allowing different ratings of the same options.\n\nWe suggest creating a perspective for each team member if you're collaborating.`,
        name: 'sheetAsHeader',
        order: 6
      };
    }
    else if (title === 'analyze →') {
      copilotProps = {
        text: `${K.isWeb ? 'Click' : 'Tap'} to see the results of your rankings`,
        name: 'analyze',
        order: 6
      };
    }
    else if (index === 0) {
      copilotProps = {
        text: `Columns are the FACTORS you care about (not the options you're considering).\n\LONG PRESS and DRAG to move them.`,
        name: 'factorTitle0',
        order: 3,
        hideExit: true,
      };
    }

    var onPress = () => {};
    var topLeftStyles = {};

    if (focusedResourceKey === 'decisionFactor' && resource.id === null) {
      onPress = () => history.push(`/decisions/${decision.id}/factors/${focusedResourceId}`);
    }
    else if (focusedResourceKey === 'decisionOption' && resource.id === null) {
      onPress = () => {
        var sheetId = _.find(sheets, {isMasterSheet: 1}).id;

        history.push(`/decisions/${decision.id}/options/${focusedResourceId}/perspectives/${sheetId}`);
      }
    }
    else {
      if (resource.id === null) {
        if (focusedResourceKey === 'decisionSheet' || !focusedResourceKey) {
          onPress = () => setActiveView({data: {activeDecisionMode: mode === 'joined' ? 'split' : 'joined'}});

          if (focusedResourceKey) topLeftStyles = {backgroundColor: 'black', color: 'white'};
        }
      }
      else {
        if (focusedResourceKey === 'decisionFactor') {
          onPress = () => {
            var hasEditPermission = _.includes([decision.ownerId, resource.editableBy], session.user.id);

            if (hasEditPermission) setActiveView({data: {isEditingSheet: true, editingSheetId: resource.id}});
          }
        }
        else {
          onPress = () => history.push(`/decisions/${decision.id}/factors/${resource.id}`);
        }
      }
    }

    return (
      <TouchableOpacity
        key={resource.id || ''}
        disabled={isAllSheetsInitial}
        onPress={onPress}
        onLongPress={drag}
        delayLongPress={300}
      >
        {!(mode === 'joined' && resource.weight === 0) && (
          <CopilotStepView
            disabled={!isFirstFactor}
            {...copilotProps}
            style={{
              ...styles.tableHeaderCell, height,
              width,
              paddingLeft: resource.id === null ? K.insets(insets, 'left', 0) : 0,
              backgroundColor: resource.color + (resource.color === '#f5f5f5' ? '' : 'CD'),//resource.color === '#CCCCCC' ? '4D' : 'CD'),
              marginRight: isLastFactor ? 0 : styles.K.gapSize,
              opacity: resource.weight === 0 ? 0.4 : 1
            }}
          >
            {/* {isSortFactor && (
              <Image source={sortArrowImage} style={{...styles.sortArrowImage, position: 'absolute', left: (resource.id === null ? K.insets(insets, 'left', sortArrowLeft) : sortArrowLeft) * scale, bottom: 7}}/>
            )} */}
            {hasNotes && (
              <Image source={commentsIcon} style={{...styles.notesImage, position: 'absolute', right: (resource.id === null ? K.insets(insets, 'left', K.spacing) : K.spacing) * scale, bottom: 5}}/>
            )}
            <View style={{
              ...styles.rotatedTextContainer,
              height: isLandscape ? height : width,
              paddingHorizontal,
              ..._.pick(topLeftStyles, ['backgroundColor']),
              ...(isLandscape ? {} : {
                width: styles.K.factorHeight,
                transform: [{rotate: '-90deg'}, {translateY: -(height - width) / 2}, {translateX: -(height - width) / 2}],
                justifyContent: 'center'
              })
            }}>
              <Text style={{
                ...styles.factorText,
                ..._.pick(topLeftStyles, ['color']),
                letterSpacing: styles.factorText.letterSpacing * fontScale,
                width: isLandscape ? 'auto' : styles.K.factorHeight - K.spacing * 2,
                fontWeight: isSortFactor ? 'bold' : 'normal',
                fontSize: fontScale * fontSize,
                // lineHeight: 'auto',
                paddingTop: (isLandscape ? K.spacing / 2 : 0) * scale,
                paddingLeft: (isLandscape ? 0 : 0) * scale
              }} dataSet={resource.width < 100 ? {'word-break-all': 1} : {}}>
                {isAllSheetsInitial ? '' : _.truncate(title || ((focusedResourceKey === 'decisionSheet' && !resource.id) ? '' : (`NEW ${focusedResourceKey === 'decisionFactor' ? 'PERSPECTIVE' : focusedResourceKey === 'decisionOption' ? 'OPTION' : 'FACTOR'}`)), {length: maxTitleLength * 1.7})}
              </Text>
            </View>
          </CopilotStepView>
        )}
      </TouchableOpacity>
    );
  }

  get relevantResources() {
    var {mode, renderMode, options, factors, sheets, scale, focusedResourceKey, focusedResourceId} = this.props;
    var resources = focusedResourceKey === 'decisionFactor'? sheets : factors;
    var netResource = {shortTitle: '', id: null, width: scale * K.table.titleColumnWidth, color: '#f5f5f5'};

    if (focusedResourceKey === 'decisionFactor') {
      var activeFactor = _.find(factors, {id: focusedResourceId});

      netResource.shortTitle = activeFactor.title;

      _.forEach(sheets, sheet => {
        sheet.width = activeFactor.width;
        sheet.shortTitle = sheet.title;
        sheet.color = activeFactor.color;
      });
    }
    else if (focusedResourceKey === 'decisionOption') {
      var activeOption = _.find(options, {id: focusedResourceId});

      netResource.shortTitle = activeOption.title;
    }
    else if (focusedResourceKey === 'decisionSheet') {
      netResource.shortTitle = mode === 'joined' ? 'back →' : 'analyze →';
    }

    return {title: [netResource], bars: resources, screenshot: [netResource, ...resources]}[renderMode];
  }

  render() {
    var {relevantResources} = this;
    var {focusedResourceKey, renderMode} = this.props;

    var flatListData = _.map(relevantResources, resource => ({id: resource.id, item: {id: resource.id}}))
    var FlatListComponent = focusedResourceKey === 'decisionSheet' && renderMode === 'bars' ? DraggableFlatList : FlatList;

    return (
      <FlatListComponent
        data={flatListData}
        renderItem={this.renderItem}
        keyExtractor={item => `draggable-item-${item.id}`}
        keyboardShouldPersistTaps='handled'
        onDragEnd={this.handleDragEnd}
        dataSet={{'draggable-flatlist': 1}}
        horizontal
        scrollEnabled={false}
      />
    );
  }
}

export default withSafeAreaInsets(connect({
  mapDispatch: {updateDecisionFactors}
})(ColumnHeaders));
