import _ from 'lodash';
import moment from 'moment';

var getSubscriptionIsActive = user => {
  return (_.get(user, 'licenseStatuses.protagonist.status', 'inactive') === 'active') || (_.get(user, 'licenseStatuses.allApps.status', 'inactive') === 'active');
}

var sharingUnlocked = user => {
  var activeSubscription = getSubscriptionIsActive(user);
  var trialEndDate = _.get(user, 'licenseStatuses.protagonist.trialEndDate');

  if (!trialEndDate) return true;

  var daysLeft = moment.utc(trialEndDate).diff(moment.utc(), 'days');
  var activeTrial = daysLeft > 0;

  return activeSubscription || activeTrial;
}

export {sharingUnlocked, getSubscriptionIsActive};
