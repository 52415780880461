import { React, Component, _, View, styleSpread, Text, TextInput, Button, logAnalyticsEvent, Popup } from '~/components/index.js'; //eslint-disable-line
import { connect } from '@symbolic/redux';
import { K } from '~/styles';
import { Pressable } from 'react-native';

import styles from './pdf-popup.styles';

var s = styleSpread(styles);

class PDFPopup extends Component {
  state = {}

  async componentDidMount() {
    var defaultSettings = await this.defaultSettings();

    this.setState({...defaultSettings});
  }

  hidePopup() {
    this.props.hidePDFPopup();
  }

  async handleInputChange({key, value}) {
    await sessionStore.set(`protagonistPdfReportData.${this.props.activeDecision.id}.${key}`, value);

    this.setState({[key]: value});
  }

  async defaultSettings() {
    var {title, notes} = this.props.activeDecision;
    var {factors, options} = this.props;

    var savedValues = {};

    await Promise.all(_.map(this.settingsKeys, async settingsKey => {
      var savedValue = await sessionStore.get(`protagonistPdfReportData.${this.props.activeDecision.id}.${settingsKey}`);

      savedValues[settingsKey] = savedValue || '';
    }));

    title = savedValues.title || `${title}: Proposal`;

    var decisionIsWeighted = _.some(_.tail(factors), ({weight}) => weight !== factors[0].weight); // check if every weight is the same

    //< considerations/factors
    var considerations = savedValues.considerations;

    if (!considerations) {
      var lines;

      if (decisionIsWeighted) {
        var groups = _.groupBy(factors, 'weight');
        var orderedGroups = _.map(_.orderBy(_.uniq(_.map(factors, 'weight')), 'desc'), weight => groups[weight]);

        lines = _.map(orderedGroups, group => _.join(_.map(group, 'title'), ', '));
      }
      else {
        lines = _.map(factors, 'title');
      }

      var considerations = `The top considerations were:
  ${_.join(_.map(lines, (line, index) => ` - ${line}${index === lines.length - 1 ? '' : '\n'}`), '')}`;
    }
    //> considerations/factors

    //< finalists/options
    var finalists = savedValues.finalists;

    if (!finalists) {
      var userSelectedFinalists = _.find(options, {status: 'final'});
      var finalOptions = userSelectedFinalists ? _.filter(options, {status: 'final'}) : _.slice(_.orderBy(options, 'netValue', 'desc'), 0, 2);
      var finalistText = 'finalists';

      if (userSelectedFinalists) finalistText = finalOptions.length === 1 ? `finalist, ${finalOptions[0].title},` : `finalists, ${_.map(finalOptions, 'title').join(', ')},`;

      var finalists = `The ${finalistText} excelled in those categories. As you can see in the chart below a careful weighted ranking supports these top candidates moving forward.`;
    }
    //> finalists/options

    return {title, summary: savedValues.summary || notes || '', considerations, finalists, presenter: savedValues.presenter || _.get(this.props, 'session.user.name', ''), references: savedValues.references || ''};
  }

  get settings() {
    return _.pick(this.state, this.settingsKeys)
  }

  get settingsKeys() {
    return ['title', 'presenter', 'summary', 'considerations', 'finalists', 'references'];
  }

  handleDonePress = () => {
    this.props.sharePDF({settings: this.settings});

    logAnalyticsEvent('generate_pdf_report');
  }

  handleResetFormPress = async () => {
    await Promise.all(_.map(this.settingsKeys, async settingsKey => {
      await sessionStore.set(`protagonistPdfReportData.${this.props.activeDecision.id}.${settingsKey}`, null);
    }));

    var defaultSettings = await this.defaultSettings();

    this.setState({...defaultSettings});
  }

  get settingKeyToLabel() {
    return {
      title: 'proposal title',
      presenter: 'your name',
      summary: 'summary of recommendation',
      considerations: 'top considerations',
      finalists: 'finalists',
      references: 'references'
    };
  }

  render() {
    return (
      <Popup scrollEnabled height={500} onClose={() => this.props.hidePDFPopup()}>
        <Text style={{...K.explanationText, marginBottom: K.spacing, marginTop: 0, paddingTop: 0}}>
          You can use and edit this form to generate a PDF sharing via email or printing that summarizes the thinking represented in this decision.
        </Text>
        <Pressable {...s.pdfPopupContainer} dataSet={{nohover: 1}}>
          {_.map(this.settingsKeys, key => (
            <TextInput
              key={key}
              style={{...styles.textInput, height: _.includes(['presenter', 'title'], key) ? K.inputHeight : K.inputHeight * 1.5}}
              onChange={async ({value}) => await this.handleInputChange({value, key})}
              label={_.startCase(this.settingKeyToLabel[key])}
              value={this.state[key]}
              {...{multiline: !_.includes(['presenter', 'title'], key)}}
            />
          ))}
          <View style={{flex: 1}}/>
          <Button text='Print/Save to PDF' {...s.shareButton} textStyle={styles.shareButtonText} onPress={this.handleDonePress}/>
          <Button text='Reset form to default' {...s.resetFormButton} textStyle={styles.resetFormButtonText} onPress={this.handleResetFormPress}/>
        </Pressable>
      </Popup>
    );
  }
}

export default connect({
  mapState: state => ({session: state.session})
})(PDFPopup);
