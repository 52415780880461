import { React, Component, _, resourceActions, Button, Text, TextInput, styleSpread, Popup, PickerInput, LabelledView, View } from '~/components/index.js'; //eslint-disable-line
import { K } from '~/styles';
import { setActiveView } from '~/redux/active-view/active-view';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import styles from './sheet-popup.styles';
import { Label, confirm, withKeyEvents } from '@symbolic/rn-lib';
import { Keyboard } from 'react-native';
import { api } from '@symbolic/lib';

var s = styleSpread(styles);

class SheetPopup extends Component {
  state = {editableByOptions: []};

  async componentDidMount() {
    await this.setUsers();
  }

  async setUsers() {
    var {decision} = this.props;

    if (decision) {
      var usersData = await api.request({uri: '/get-users', body: {decisionId: decision.id, orgId: _.get(this.props, 'session.activeOrg.id')}});
      var users = _.get(usersData, 'data.users', []);

      var editableByOptions = _.map(users, ({id, firstName, lastName}) => ({value: `${id}`, title: id === this.props.session.user.id ? 'You' : `${firstName} ${lastName}`}));

      this.setState({editableByOptions});
    }
  }

  handlePickerChange = ({key, value}) => {
    var {decision, sheet} = this.props;

    if (!decision.isLocked) this.props.updateDecisionSheet({id: sheet.id, props: {[key]: _.isNumber(parseInt(value)) ? parseInt(value) : null}});
  }

  handleTitleChange = ({value}) => {
    if (!this.props.decision.isLocked) this.props.updateDecisionSheet({id: this.props.sheet.id, props: {title: value}});
  }

  handleDelete = async () => {
    var {sheet} = this.props;
    var {id, title} = sheet;
    var isBlank = this.props.isCreating && !title;

    if (isBlank) {
      this.delete();
    }
    else {
      // if (await confirm('', `Are you sure you want to DELETE ${title ? `the perspective "${title}"` : 'this perspective'}?`))
      this.delete();

      setTimeout(() => this.props.triggerUndoPopup({event: {
        message: `${title ? `The perspective "${title}"` : 'That perspective'} has been deleted`,
        action: () => {
          this.props.updateDecisionSheet({id, props: {deleted: 0}});

          this.props.trackDecisionSheets({decisionSheets: [sheet]});
        }
      }}));
    }

    this.close();
  }

  handlePopupClose = type => {
    var {sheet, isCreating} = this.props;

    if (isCreating && this.isOwner && type !== 'done' && ((!sheet.title && type !== 'confirm') || type === 'cancel')) {
      this.delete();
    }

    if (!K.isWeb) Keyboard.dismiss();

    setTimeout(() => this.close(), K.isWeb ? 0 : 100); //HINT wait for keyboard blur on mobile
  }

  delete = () => {
    setTimeout(() => {
      this.props.destroyDecisionSheet({id: this.props.sheet.id});
      this.props.onSheetDelete({id: this.props.sheet.id});
    })
  }

  close = () => this.props.setActiveView({data: {isEditingSheet: false, isCreatingSheet: false, editingSheetId: undefined}});

  get permissionToDelete() {
    var {sheet, sheets} = this.props;

    return !sheet.isMasterSheet && sheets.length > 1 && this.props.decision.ownerId === this.props.session.user.id;
  }

  get isOwner() {
    var {decision, session} = this.props;

    return decision.ownerId === session.user.id;
  }

  get explanationText() {
    var {sheet, users, decision, session} = this.props;
    var explanationText = `Only you can view this Perspective currently. (It's not shared with anyone).`;

    var decisionOwnerName = _.get(users, `[${decision.ownerId}].firstName`, '(removed)');
    var editableByName = _.get(users, `[${sheet.editableBy}].firstName`, '(removed)');
    var isEditableByAnotherUser = sheet.editableBy !== null && sheet.editableBy !== decision.ownerId;
    var otherUserName = decision.ownerId === session.user.id ? editableByName : decisionOwnerName;

    if (_.size(users) > 1) {
      if (sheet.viewableByAll || sheet.isMasterSheet) {
        if (sheet.isMasterSheet && sheet.editableByAll) {
          explanationText = `Everyone can view and make changes to this perspective tab.`;
        }
        else if (isEditableByAnotherUser) {
          explanationText = `You and ${otherUserName} can make changes to this perspective tab. Everyone can view it.`;
        }
        else {
          explanationText = `You can make changes to this perspective tab. Everyone can view it.`;
        }
      }
      else {
        if (isEditableByAnotherUser) {
          explanationText = `Only you and ${otherUserName} can view and make changes to this perspective tab.`;
        }
        else {
          explanationText = `Only you can view and make changes to this perspective tab.`;
        }
      }
    }

    return explanationText ? `${explanationText}\n\n` : explanationText;
  }

  render() {
    var {isCreating, sheet, sheets, decision, session, users} = this.props;
    var {editableByOptions} = this.state;

    return (
      <Popup
        onClose={this.handlePopupClose}
        cancelOnDelete={this.permissionToDelete}
        scrollEnabled
        closeOnEnter
        useCloseButton
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <View style={{flexDirection: 'row', width: 'auto', marginBottom: K.spacing * 2}}>
          {isCreating ? (
              <Button label='Cancel' style={{flex: 1, marginRight: K.margin}} onPress={() => this.handlePopupClose('cancel')}/>
            ) : (
              this.permissionToDelete && (
                <Button
                  style={{backgroundColor: K.colors.deleteRed, flex: 1, marginRight: K.margin, opacity: decision.isLocked ? 0.5 : 1}}
                  disabled={decision.isLocked}
                  onPress={this.handleDelete}
                  label='Delete Perspective'
                />
              )
            )}
          <Button label='Done' style={{backgroundColor: 'black', flex: 1}} textStyle={{color: 'white'}} onPress={() => this.handlePopupClose('done')}/>
        </View>
        <TextInput
          grayLabelledView
          editable={!decision.isLocked}
          label='title'
          onChange={this.handleTitleChange}
          value={sheet.title}
          autoFocus={!sheet.title && K.orientation !== 'landscape'}
        />
        {editableByOptions.length > 1 && this.isOwner && <LabelledView label={'Belongs to collaborator:'}>
          <PickerInput
            showDownArrow
            onChange={({value}) => this.handlePickerChange({value, key: 'editableBy'})}
            value={sheet.editableBy ? `${sheet.editableBy}` : ''}
            style={{marginBottom: K.spacing}}
            enabled={!decision.isLocked}
            options={editableByOptions}
          />
        </LabelledView>}
        {/* {this.isOwner && !!sheet.isMasterSheet && <LabelledView label={'Collaborators can edit main perspective:'}>
          <PickerInput
            inline
            basic
            enabled={!decision.isLocked}
            options={[{value: '0', title: 'No'}, {value: '1', title: 'Yes'}]}
            onChange={({value}) => this.handlePickerChange({value, key: 'editableByAll'})}
            style={{marginBottom: K.spacing}}
            value={`${sheet.editableByAll || 0}`}
          />
        </LabelledView>} */}
        {/* {!sheet.isMasterSheet && (
          <LabelledView label={'Perspective is visible to all collaborators:'}>
            <PickerInput
              inline
              basic
              enabled={!decision.isLocked}
              options={[{value: '0', title: 'No'}, {value: '1', title: 'Yes'}]}
              onChange={({value}) => this.handlePickerChange({value, key: 'viewableByAll'})}
              style={{marginBottom: K.spacing}}
              value={`${sheet.viewableByAll || 0}`}
            />
          </LabelledView>
        )} */}
        <Text style={K.explanationText}>
          {this.explanationText}
          People don’t always see eye to eye. “Perspectives” are different tabs where people can enter their own judgment about each factor for each option.
        </Text>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var decision, sheets;
    var {resources, session} = state;
    var sheet = _.get(resources.decisionSheets.byId, ownProps.id);

    if (!sheet) {
      ownProps.history.push('/404/decisionSheet');
    }
    else {
      decision = _.get(resources.decisions.byId, sheet.decisionId);
      sheets = _.filter(resources.decisionSheets.byId, {decisionId: decision.id});

      if (!decision) ownProps.history.push('/404/decision');
    }

    return {sheet, sheets, decision, session, users: resources.users.byId};
  },
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.decisionSheets, ['trackDecisionSheets', 'updateDecisionSheet', 'destroyDecisionSheet'])
  }
})(withKeyEvents(SheetPopup)));
