import {K as LibK} from '@symbolic/rn-lib/dist/styles/index';
import * as ScreenOrientation from 'expo-screen-orientation';
import _ from 'lodash';

var setOrientationFor = o => {
  LibK.orientation = constants.orientation = !LibK.isWeb && _.includes([3, 4], o) ? 'landscape' : 'portrait';
  LibK.o = constants.o = o;
};

(async () => setOrientationFor(await ScreenOrientation.getOrientationAsync()))();

ScreenOrientation.addOrientationChangeListener(({orientationInfo}) => setOrientationFor(orientationInfo.orientation));

var constants = {
  ...LibK,
  spacing: LibK.calc(15),
  themeColor: '#333',
  inverseThemeColor: '#fff',
  fonts: {
    sl: {fontSize: LibK.calcFont(14), letterSpacing: LibK.calcFont(1)},
    su: {fontSize: LibK.calcFont(12), letterSpacing: LibK.calcFont(1), textTransform: 'uppercase'}
  },
  shadow: {
    shadowOpacity: 0.2,
    shadowRadius: 3,
    shadowOffset: {height: 0, width: 0}
  },
  table: {
    titleColumnWidth: LibK.isWeb ? 200 : 120
  },
  bold: {fontWeight: 'bold'},
  bar: {
    height: LibK.isWeb ? 32 : 44,
    borderRadius: LibK.isWeb ? 32 / 2 : 44 / 2,
    minWidth: LibK.isWeb ? 150 : 150
  }
};

constants.explanationText = {
  marginTop: constants.spacing,
  padding: constants.spacing,
  // backgroundColor: constants.colors.gray,
  borderRadius: constants.borderRadius,
  fontStyle: 'italic'
};

export default constants;
