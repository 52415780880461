import {K} from '~/styles';

var s = {};

s.page = {
  height: '100%',
  flex: 1,
  ...(K.isWeb ? {} : {paddingHorizontal: K.spacing})
};
  s.header = {
    marginTop: 30,
    marginBottom: K.spacing * 2,
    alignSelf: 'center',
    alignItems: 'center',
    ...(K.isWeb ? {width: 400} : {})
  };
    s.homeIconContainer = {
      marginBottom: 10,
    };
      s.homeIcon = {
        width: 30,
        height: 30
      };
    s.headerTitle = {
      ...K.fonts.label,
      fontSize: 20,
      marginBottom: 20,
      // letterSpacing: 4
    };
    s.headerMessages = {
      fontSize: 13,
      opacity: 0.5,
      letterSpacing: 0.8,
      marginBottom: K.spacing,
      alignItems: 'center'
    };
      s.headerMessage = {
        textAlign: 'center'
      };
    s.addButtonContainer = {
      marginTop: K.spacing
    };
      s.addButton = {
        backgroundColor: 'black',
        borderRadius: K.calc(70 / 2),
        width: K.calc(70),
        height: K.calc(70),
        ...(!K.isWeb && {
          top: -1,
          left: -1
        })
      };
  s.scrollViewContent = {
    paddingBottom: K.spacing * 7
  };
  s.decisionsContainer = {
    marginBottom: K.spacing * 3,
    alignSelf: K.isWeb ? 'center' : 'stretch',
    alignItems: 'center',
  };
    s.decisionsLabel = {
      ...K.fonts.su,
      fontSize: K.calc(15),
      fontWeight: 'bold',
      opacity: 1,
      marginBottom: K.spacing,
    };
    s.decisions = {
      flex: 1,
      marginBottom: 0,//60
    };
      s.decisionRow = {
        flexDirection: 'row',
        marginTop: 1,
        ...(K.isWeb ? {width: 400} : {})
      };
        s.decision = {
          paddingLeft: K.spacing,
          flexGrow: 1,
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: K.button.height,
          borderRadius: 0,
          flexDirection: 'row',
          backgroundColor: K.colors.gray,
          borderTopLeftRadius: K.borderRadius,
          borderBottomLeftRadius: K.borderRadius
        };
          s.statusDot = {
            marginRight: K.spacing,
            width: 15,
            height: 15,
            borderRadius: 15
          };
          s.decisionTitle = {
            flex: 1,
          };
        s.deleteButton = {
          marginLeft: 1,
          width: K.button.width,
          height: K.button.height,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: K.colors.gray,
          borderTopRightRadius: K.borderRadius,
          borderBottomRightRadius: K.borderRadius
        }
          s.deleteImage = {
            zIndex: 1,
            width: K.calc(20),
            height: K.calc(20),
            opacity: 0.5
          };
    s.decisionsPlaceholder = {
      height: K.calc(70),
      paddingHorizontal: K.spacing,
      backgroundColor: K.colors.gray,
      borderRadius: K.borderRadius,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 1,
      opacity: 0.8,
      ...(K.isWeb ? {width: 400} : {alignSelf: 'stretch'})
    };
      s.decisionsPlaceholderText = {
        opacity: 0.5,
        textAlign: 'center',
        paddingHorizontal: K.spacing
      };

export default s;
