import {K} from '~/styles';

var s = {};

s.weightBar = {
  height: K.bar.height,
  marginBottom : 1
};
  s.weightBarBackground = {
    backgroundColor: '#f0f0f0',
    width: '100%',
    height: '100%',
    // position: 'absolute',
    left: 0,
    borderRadius: K.bar.borderRadius,
  }
  s.weightBarFill = {
    height: '100%',
    // position: 'absolute',
    minWidth: K.bar.height,
    borderRadius: K.bar.borderRadius,

    left: 0,
    // borderTopRightRadius: K.borderRadius,
    // borderBottomRightRadius: K.borderRadius,
  };

export default s;
