import { React, Component, _, resourceActions, Text, styleSpread, View, Comments, updateDecision } from '~/components/index.js'; //eslint-disable-line
import { Pressable } from 'react-native';
import { Popup } from '@symbolic/rn-lib';
import { K } from '~/styles';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import styles from './factor-value-popup.styles';

var s = styleSpread(styles);

class FactorValuePopup extends Component {
  onCreateComment = async () => {
    var {decision} = this.props;

    if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});
  }

  render() {
    var {factor, decision, history, users, decisionFactorId, decisionOptionId, decisionSheetId, noExistingComments} = this.props;

    return (
      <Popup scrollEnabled height={K.orientation === 'landscape' ? '75%' : 400} onClose={() => history.push(`/decisions/${decision.id}`)}>
        <Pressable style={{marginBottom: K.spacing * 6}} dataSet={{nohover: 1}}>
          {/* <TextInput
            multiline
            label='notes'
            style={{height: 'auto', paddingVertical: K.margin, minHeight: 80, maxHeight: 150}}
            labelledViewStyles={{outerView: {paddingVertical: 15}}}
            value={this.notes}
            onChange={({value}) => this.props.onNotesChange(value)}
          /> */}
          <View style={{...((noExistingComments && _.flatMap(users).length > 1) ? {paddingTop: 10 + (_.min([_.flatMap(users).length, 3]) * 35)} : {})}}>
            <Comments
              placeholder='DISCUSS / EXPLAIN YOUR REASONING'
              decisionOptionFactorId={decisionFactorId}
              decisionOptionId={decisionOptionId}
              creationProps={{decisionSheetId, orgId: decision.orgId}}
              resourceId={decision.id}
              onCreate={this.onCreateComment}
              additionalUsers={users}
              autoFocus={K.isWeb && K.orientation !== 'landscape'}
              type={'decisionOptionFactor'}
            />
          </View>
          <Text style={K.explanationText}>
            Close this popup and drag the colored slider to rate a factor between 0 and 10. The value ? represents unknown or to be determined.
          </Text>
        </Pressable>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {resources} = state;
    var decision = _.get(resources.decisions.byId, ownProps.decisionId);
    var option = _.get(resources.decisionOptions.byId, ownProps.decisionOptionId);
    var sheet = _.get(resources.decisionSheets.byId, ownProps.decisionSheetId);
    var factor = _.get(resources.decisionFactors.byId, ownProps.decisionFactorId);

    if (!decision) {
      ownProps.history.push('/404/decision');
    }
    else if (!option || option.decisionId !== decision.id) {
      ownProps.history.push(`/404/decisionOption/${decision.id}`);
    }
    else if (!sheet || sheet.decisionId !== decision.id) {
      ownProps.history.push(`/404/decisionSheet/${decision.id}`);
    }
    else if (!factor || factor.decisionId !== decision.id) {
      ownProps.history.push(`/404/decisionFactor/${decision.id}`);
    }

    if (!(decision && option && sheet && factor)) {
      decision = {};
      option = {};
      sheet = {};
      factor = {};
    }

    return {factor, decision};
  },
  mapDispatch: {
    ..._.pick(resourceActions.decisionFactors, ['trackDecisionFactors', 'updateDecisionFactor', 'destroyDecisionFactor']),
    updateDecision
  }
})(FactorValuePopup));
