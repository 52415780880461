import {
  React, Component, _, styleSpread,
  View, Text,
  updateDecisionFactor, destroyDecisionFactor, updateDecision
} from '~/components/index.js'; //eslint-disable-line

import { PanResponder, Dimensions } from 'react-native';
import { K } from '~/styles';
import { connect, setProtagonistData } from '@symbolic/redux';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './factor-weight-bar.styles';
import { coloredFactorsFor } from '../../../../lib/color';

var s = styleSpread(styles);

class FactorWeightBar extends Component {
  state = {
    notesModalIsVisible: false,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (!this.props.disabled) {
      var dismissedDecisionStatusAlert = _.get(this.props, 'session.user.protagonistData.dismissedDecisionStatusAlert') || await sessionStore.get('dismissedDecisionStatusAlert') || false;

      this.setState({dismissedDecisionStatusAlert});

      this._panResponder = PanResponder.create({
        onPanResponderTerminationRequest: () => false,
        onStartShouldSetPanResponderCapture: () => true,
        onPanResponderGrant: () => {
          if (this.props.decision.status === 'brainstorming' && !this.state.dismissedDecisionStatusAlert) {
            alert(`You can adjust the weight of any factor at any time, but it's usually best to do it in the final stage of decision making.\n\nOnce you have ranked all factors and are starting to reflect on what the results are telling you, the effect of weighting specific factors is more clear, particularly if you are working with a group of people.`);

            this.props.setProtagonistData({key: 'dismissedDecisionStatusAlert', value: true});

            this.setState({dismissedDecisionStatusAlert: true});
          };
          // this.props.setScrollViewIsEnabled(false);

          //TODO indicate initial pan
        },
        onPanResponderMove: (_event, gestureState) => {
          var {dx} = gestureState;
          var {lastWeight, maxBarWidth} = this;

          this.dx = dx;

          if (dx) {
            var normalDx = (dx / maxBarWidth) * 10;
            var value = lastWeight + normalDx;
            if (value <= 0.5) value = 0;
            var snapValue = _.max([0, _.min([10, _.round(value)])]);
          }

          this.setState({tempWeight: (snapValue || snapValue === 0) ? snapValue : undefined});
        },
        onPanResponderRelease: () => {
          // this.props.setScrollViewIsEnabled(true);

          if (this.dx && (this.state.tempWeight || this.state.tempWeight === 0)) {
            this.handleInputChange({key: 'weight', value: this.state.tempWeight});
          }

          this.dx = 0;
        }
      });

      this.forceUpdate();
    }
  }

  componentDidUpdate() {
    if (this.state.tempWeight !== undefined && this.state.tempWeight === this.props.factor.weight) {
      this.setState({tempWeight: undefined});
    }
  }

  handleInputChange({key, value}) {
    var {decision, factor} = this.props;

    this.props.updateDecisionFactor({id: factor.id, props: {[key]: value}});

    if (decision && decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});

    setTimeout(() => this.props.sort());
  }

  get lastWeight() {
    return this.props.factor.weight;
  }

  get weight() {
    return _.get(this.state, 'tempWeight', this.props.factor.weight);
  }

  get displayValue() {
    //turn temp value to display value
    return _.round(this.weight);
  }

  get maxBarWidth() {
    var availableWidth = K.isWeb ? 400 : Dimensions.get('window').width;

    var {insets} = this.props;

    return availableWidth - (K.spacing * 2) - K.insets(insets, 'left') - K.insets(insets, 'right') - (K.isWeb || K.orientation === 'portrait' ? 0 : K.button.width + K.spacing * 2);
  }

  render() {
    var {maxBarWidth} = this;
    var {factor, factors} = this.props;
    var barWidth = (this.weight / 10) * maxBarWidth;

    factor = _.find(coloredFactorsFor({factors: factors}), {id: factor.id}) || {};

    return (
      <View
        style={{...styles.weightBar, width: maxBarWidth}}
        // disabled={this.props.index !== 0}
        // name='factorWeightSlider'
        // order={5}
        // text={`Weight for each factor can be adjusted by clicking and dragging these bars.\n\nWeighting here means assigning importance of each factor to the decision.`}
        {..._.get(this, '_panResponder.panHandlers')}
      >
        <View style={{...styles.weightBarBackground, backgroundColor: factor.color + '4D'}}>
          <View style={{...styles.weightBarFill, backgroundColor: barWidth === 0 ? 'none' : factor.color, width: barWidth}}>
            <View style={{paddingLeft: K.spacing, justifyContent: 'center', height: '100%'}}>
              <Text {...(K.isWeb ? {style: {userSelect: 'none'}} : {})}>{this.displayValue}x</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => {
    var {resources, session} = state;

    var factors = _.filter(resources.decisionFactors.byId, {decisionId: _.get(ownProps, 'decision.id')});

    return {factors, session};
  },
  mapDispatch: {updateDecisionFactor, destroyDecisionFactor, updateDecision, setProtagonistData}
})(FactorWeightBar));
