import { React, Component, _, resourceActions, View, styleSpread, TextInput, Text, Button, Image, Popup, Link, logAnalyticsEvent, LabelledView, AccessManager } from '~/components/index.js';
import { connect } from '@symbolic/redux';
import { confirm, Label, CheckboxInput, AutocompleteInput } from '@symbolic/rn-lib';
import { K } from '~/styles';
import { TouchableOpacity, TouchableWithoutFeedback, Pressable } from 'react-native';
import { setActiveView } from '~/redux/active-view/active-view';
import { sharingUnlocked, getSubscriptionIsActive } from '~/lib/subscription';
import { withRouter } from 'react-router-native';

import styles from './share-popup.styles';
import api from '~/lib/api';
import moment from 'moment';
import lib from '@symbolic/lib';
import deleteIcon from '~/assets/x-icon.png';

var s = styleSpread(styles);

class SharePopup extends Component {
  state = {
    users: [],
    potentialSharees: [],
    searchTerm: '',
    hideAutocomplete: true,
  };

  async componentDidMount() {
    await this.setUsers();
    await this.setPotentialSharees();
  }

  async setUsers() {
    var {activeDecision} = this.props;

    if (activeDecision) {
      var usersData = await api.request({uri: '/get-users', body: {decisionId: activeDecision.id, orgId: activeDecision.orgId}});
      var users = _.get(usersData, 'data.users', []);

      this.setState({users});
    }
  }

  async setPotentialSharees() {
    if (this.isOwner) {
      var {activeDecision} = this.props;

      if (activeDecision) {
        var usersData = await api.request({uri: '/get-users', body: {}});
        var potentialSharees = _.get(usersData, 'data.users', []);

        this.setState({potentialSharees});
      }
    }
  }

  async share({email}) {
    var {activeDecision} = this.props;

    if (activeDecision && email) {
      await api.request({uri: '/decisions/share', body: {
        inviteeEmail: email,
        decisionId: activeDecision.id,
      }});

      if (activeDecision.wasModified !== 1) await this.props.updateDecision({id: activeDecision.id, props: {wasModified: 1}});

      alert(`An email has been sent to ${email} including a link to this decision. They’ll be able to access it from their computer or phone.`);

      logAnalyticsEvent('decision_shared');

      this.setState({searchTerm: ''});

      await this.setUsers();
    }
  }

  async unshareFromUser({user}) {
    if (await confirm(``, `Remove ${user.name}?`)) {
      var {activeDecision} = this.props;

      await api.request({uri: '/decisions/unshare', body: {
        decisionId: activeDecision.id,
        emailToUnshare: user.email
      }});

      var {users} = this.state;

      _.set(users, `[${_.indexOf(users, user)}].wasRemoved`, 1);

      this.setState({users});
    }
  }

  handleInputChange = async ({key, value, confirmCondition, confirmMessage}) => {
    if (!confirmCondition || await confirm('', confirmMessage)) {
      this.props.updateDecision({id: this.props.activeDecision.id, props: {[key]: value, wasModified: 1, shareesCanEditOptions: 0}});
    }
  }

  hidePopup = () => {
    this.props.hideSharePopup();
  }

  handleManageCollaboratorsPress = () => {
    this.props.setActiveView({data: {isViewingCollaborators: true}});
  }

  createSheet = () => {
    this.props.createSheet();

    setTimeout(this.hidePopup);
  }

  get isOwner() {
    var activeDecisionOwnerId = _.get(this.props, 'activeDecision.ownerId', 1)
    var activeUserId = _.get(this.props, 'session.user.id', 0);

    return activeDecisionOwnerId === activeUserId;
  }

  get autocompleteOptions() {
    var {potentialSharees, searchTerm, users: sharees} = this.state;
    var lowerSearchTerm = _.toLower(searchTerm);

    var userSuggestions = _.filter(potentialSharees, user => {
      return (_.includes(_.toLower(user.name), lowerSearchTerm) || _.includes(_.toLower(user.email), lowerSearchTerm)) && !_.includes(_.map(sharees, 'id'), user.id);
    });

    if (searchTerm && (!userSuggestions.length || lib.validation.emailIsValid(searchTerm))) {
      userSuggestions.unshift({
        id: -1,
        firstName: lib.validation.emailIsValid(searchTerm) ? `"${searchTerm}" (valid email)` : `"${searchTerm}"`,
        lastName: '',
        email: searchTerm,
        invalidMessage: lib.validation.emailIsValid(searchTerm) ? '' : 'Please enter a valid collaborator or email'
      });
    }

    return userSuggestions;
  }

  get domain() {
    var url = {protagonist: 'protag.app', weflowLite: 'polydot.app'}[APP_KEY];

    return process.env.NODE_ENV === 'development' ? 'http://localhost:19006' : url;
  }

  render() {
    var {activeDecision, activeView, sheets, session} = this.props;
    var {isViewingCollaborators} = activeView.data;
    var {users, searchTerm, hideAutocomplete} = this.state;
    var activeUserId = _.get(this.props, 'session.user.id', 0);

    var isSubscribed = getSubscriptionIsActive(session.user);
    var trialEndDate = _.get(session.user, 'licenseStatuses.protagonist.trialEndDate');
    var daysLeft = trialEndDate && moment.utc(trialEndDate).diff(moment.utc(), 'days');

    var SharingTrialExplanation = props => (<View style={props.style}>
      <Text style={{fontSize: K.calcFont(14), ...styles.sharingTrialTitle}}>Sharing {K.isWeb ? 'Trial' : ''}</Text>
      {trialEndDate && daysLeft > 0 && (<Label style={{opacity: 0.5}}>{`${daysLeft} day${daysLeft === 1 ? '' : 's'} left`}</Label>)}
      <Text style={{marginVertical: K.spacing}}>Often decisions involve multiple parties/stakeholders. Protagonist is suited for use by a group - an interviewing team, a marketing team, a family... Everyone can convey their opinion and understand why a decision was made.</Text>
      <Text style={{marginBottom: K.spacing}}>Sharing is a {K.isWeb ? 'paid feature, but you can currently access it through your free trial. Once your trial is over, you can upgrade for the ability to share.' : `desktop-only feature - please visit ${this.domain} on your computer to give it a try!`}</Text>
    </View>);

    var collaborationOptions = [
      {
        text: <Text><Text style={{fontWeight: "bold"}}>Each team member has a private perspective:</Text> Team members each rate each option on their own private sheet, then discuss together (good for qualitative decisions like interviewing new hires where you don’t want team members to be swayed by others' rankings early in the process.)</Text>,
        collaborationMode: 'manyPrivateSheets',
        confirmCondition: sheets.length > 1 && users.length > 2,
        confirmMessage: 'This will hide collaborators\' perspectives from each other.'
      },
      {
        text: <Text><Text style={{fontWeight: "bold"}}>Each team member has a perspective that all can see:</Text> Team members each rate each option on their own sheet, but everyone on the team can see all team members sheets.</Text>,
        collaborationMode: 'manyPublicSheets',
        confirmCondition: sheets.length > 1 && users.length > 2,
        confirmMessage: 'This will make sheets that are currently hidden visible to all collaborators.'
      },
      {
        text: <Text><Text style={{fontWeight: "bold"}}>Single perspective: </Text>The decision owner rates each option. Collaborators can comment and join the discussion, but don't rate options themselves (great for more quantitative decisions like capital investment etc.)</Text>,
        collaborationMode: 'oneViewableSheet',
        confirmCondition: sheets.length > 1,
        confirmMessage: 'This will hide all perspectives besides the main perspective from collaborators.'
      },
      {
        text: <Text><Text style={{fontWeight: "bold"}}>Shared rating:</Text> Allows all team members to rate options together (good when the rating of different factors requires specific expertise from different team members.)</Text>,
        description: 'Each collaborator is responsible for a few options or factors',
        collaborationMode: 'oneEditableSheet',
        confirmCondition: sheets.length > 1,
        confirmMessage: 'This will hide all perspectives besides the main perspective from collaborators.'
      }
    ];

    var ownerName = _.get(_.find(users, {id: _.get(activeDecision, 'ownerId')}), 'name', 'the decision owner');

    return (
      <Popup scrollEnabled cancelOnDelete height={300} onClose={() => this.props.hideSharePopup()}>
        <View {...s.sharingPopupContainer}>
          {isViewingCollaborators ? (
            <TouchableWithoutFeedback dataSet={{nohover: 1}}>
              <View dataSet={{nohover: 1}}>
                <AccessManager behaviorMap={{inactive: 'hide'}}>
                  {this.isOwner && (
                    <LabelledView label='Invite Collaborators' gray styles={{outerView: {zIndex: 2}}}>
                      <AutocompleteInput
                        data={this.autocompleteOptions}
                        placeholder={'NAME OR EMAIL'}
                        inputValue={searchTerm}
                        setInputValue={(value) => this.setState({searchTerm: value})}
                        autocompleteIsVisible={!hideAutocomplete}
                        setAutocompleteIsVisible={(value) => this.setState({hideAutocomplete: !value})}
                        onSelect={item => this.share({email: item.email, item})}
                      />
                    </LabelledView>
                  )}
                </AccessManager>
                <View style={{zIndex: 1}}>
                  {_.map(_.reject(users, 'wasRemoved'), (user, index) => (
                    <View key={user.id || index} style={{...styles.shareRow, marginBottom: 1}}>
                      <Text {...s.shareLeftText}>{user.name}{(user.id && user.id === activeDecision.ownerId) && ' (owner)'}{user.id && user.id === activeUserId && ' (you)'}{user.wasRemoved ? ' (removed)' : ''}</Text>
                      {(user.id !== activeDecision.ownerId && activeUserId === activeDecision.ownerId && !user.wasRemoved) && (
                        <TouchableOpacity onPress={() => this.unshareFromUser({user})} style={{position: 'absolute', right: 15}}>
                          <Image source={deleteIcon} {...s.deleteImage}/>
                        </TouchableOpacity>
                      )}
                    </View>
                  ))}
                </View>
                <LabelledView label={'Collaboration Mode:'} styles={{outerView: {marginVertical: K.spacing * 2, zIndex: 1}}}>
                  {_.map(collaborationOptions, ({text, collaborationMode, confirmCondition, confirmMessage}) => (
                    <TouchableOpacity
                      key={collaborationMode}
                      onPress={this.isOwner ?
                        () => this.handleInputChange({key: 'collaborationMode', value: collaborationMode, confirmCondition, confirmMessage}) :
                        () => alert(`Only ${ownerName} can edit the collaboration mode.`)}
                      style={{...styles.shareRow, height: 'auto', justifyContent: 'flex-start', marginBottom: 1, padding: K.spacing, paddingRight: K.spacing * 2}}
                    >
                      <View style={{...styles.collaborationDot ,backgroundColor: activeDecision.collaborationMode === collaborationMode ? 'black' : K.colors.doubleGray}}/>
                      <Text style={{paddingHorizontal: K.margin}}>{text}</Text>
                    </TouchableOpacity>
                  ))}
                </LabelledView>
                {this.isOwner && (
                  <CheckboxInput
                    onChange={({value}) => this.props.updateDecision({id: activeDecision.id, props: {shareesCanEditOptions: value, wasModified: 1}})}
                    label={'Collaborators can add options'}
                    value={activeDecision.shareesCanEditOptions}
                  />
                )}
                {this.isOwner && (
                  <TouchableOpacity onPress={this.createSheet} style={{...styles.shareRow, justifyContent: 'flex-start', marginTop: K.spacing}}>
                    <Label>Manually create a perspective</Label>
                  </TouchableOpacity>
                )}
                {!isSubscribed && <Pressable dataSet={{nohover: 1}} style={{backgroundColor: K.colors.gray, padding: K.spacing, borderRadius: K.borderRadius, marginBottom: K.spacing * 3, marginTop: K.spacing * 2}}>
                  <SharingTrialExplanation/>
                  <Button onPress={() => this.props.history.push('/billing')} style={{backgroundColor: '#98a0b8', marginTop: K.spacing}} textStyle={{color: 'white'}} text='View paid plans'/>
                </Pressable>}
              </View>
            </TouchableWithoutFeedback>
          ) : (
            <View style={{flexDirection: 'column', width: '100%'}}>
              {this.isOwner ? (
                isSubscribed ? (
                  <TouchableOpacity style={{...styles.shareColumn}} onPress={this.handleManageCollaboratorsPress}>
                    <Text {...s.shareText}>Add collaborators</Text>
                  </TouchableOpacity>
                ) : (
                  <Pressable dataSet={{nohover: 1}} style={{backgroundColor: K.colors.gray, padding: K.spacing, borderRadius: K.borderRadius, marginBottom: K.spacing * 3}}>
                    <SharingTrialExplanation style={{marginBottom: K.spacing}}/>
                    {K.isWeb && <View style={{flexDirection: 'row'}}>
                      <TouchableOpacity style={{...styles.shareColumn, marginBottom: 0, flex: 1, ...(!this.isOwner ? {} : {backgroundColor: K.colors.deleteRed})}} onPress={this.handleManageCollaboratorsPress}>
                        <Text {...s.shareText}>{!this.isOwner ? 'Add collaborators' : (K.isWeb ? 'Trial expired' : 'Desktop Feature')}</Text>
                      </TouchableOpacity>
                      <Button onPress={() => this.props.history.push('/billing')} style={{backgroundColor: '#98a0b8', marginLeft: K.margin}} textStyle={{color: 'white'}} text='View paid plans'/>
                    </View>}
                  </Pressable>
                )
              ) : (
                <TouchableOpacity {...s.shareColumn} onPress={() => this.props.setActiveView({data: {isViewingCollaborators: true}})}>
                  <Text {...s.shareText}>{'View collaborators'}</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity style={{...styles.shareColumn}} onPress={this.props.handleShareScreenshotPress}>
                <Text {...s.shareText}>Save a screenshot</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{...styles.shareColumn}} onPress={this.props.handleShareCsvPress}>
                <Text {...s.shareText}>Export to Excel-compatible CSV file</Text>
              </TouchableOpacity>
              <TouchableOpacity style={{...styles.shareColumn}} onPress={this.props.handleGeneratePDFPress}>
                <Text {...s.shareText}>Generate PDF report</Text>
              </TouchableOpacity>
              <Text style={{...K.explanationText, marginTop: K.spacing * 2}}>{`From the share menu, you can add collaborators if you're the decision owner.\n\nYou can also generate documents for use outside of Protagonist, such as a PDF that can be email or printed as a one-sheet summary, a screenshot, or a CSV file for use in Excel or other similar programs.`}</Text>
            </View>
          )}
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: state => ({
    session: state.session,
    activeView: state.activeView
  }),
  mapDispatch: {
    ..._.pick(resourceActions.decisions, ['updateDecision']),
    setActiveView
  }
})(SharePopup));
