import { K } from '~/styles';

var categories = [
  {title: 'Core features', items: [
    {title: 'Options', description: `Protagonist helps answer the question “Which option should I go with?” Each row is an option. Options are the possible courses of action you could take.

- Click title to edit properties.
- Status: A tool to categorize options
- Options have a notes for background and comments for discussion/group commentary`, media: []},
    {title: 'Factors', description: `To effectively compare options, it is important to evaluate them based on several factors. Each column is a “factor,” which is a different aspect of the options being compared.

- Drag the sliders to evaluate each factor for each option.
- Click title to edit properties.
- Factors can have weights to represent greater or lesser importance.
- Make sure you name factors in a way that 'more of it' is a good thing. EG: 'affordability' instead of 'cost': high affordability is good, high cost is bad, so you don't want more that!`, media: []},
    {title: 'Perspectives', description: `People don’t always see eye to eye. “Perspectives” are different tabs where people can enter their own judgment about each factor for each option.

- Click title to edit properties.
- Each perspective has one owner (besides the decision owner).
- The owner can control  whether sheets are viewable by other collaborators or not.`, media: []},
    {title: 'Sharing', description: `One of the biggest benefits of Protagonist is facilitating better discussions with decision collaborators. Once shared, collaborators who can see others’ sheets can see changes in real-time and compare sheets by factor or option using the apps view controls.

Protagonist can also generate a PDF Summary - a one-sheet you can print out or share digitally. These PDFs will help your team present their thought process in an ordered, professional format - one that will get everyone on the same page - quickly and with little effort.

Additionally, you can generate a screenshot to embed or send to others, as well as  an Excel-compatible CSV file for further analysis.`, media: []}
  ]},
  ...(!K.isWeb ? [] : [
  {title: 'Pro-tips', items: [
    {title: 'Popup Hotkeys', description: `- Escape to close\n- Enter to save\n- Backspace to delete`},
    {title: 'Create Hotkeys', description: `- Ctrl/Cmd + Shift + O to create an option
- Ctrl/Cmd + Shift + F to create a factor
- Ctrl/Cmd + Shift + P to create a perspective`}
  ]}])
];

export default categories;
