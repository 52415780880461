import { React, Component, _, View, resourceActions } from '~/components/index.js'; //eslint-disable-line
import { connect } from '@symbolic/redux';
import api from '../../../../lib/api';


class ShareLinkView extends Component {
  async componentDidMount() {
    var {token, decisionId} = this.props.match.params;

    await api.request({uri: '/decisions/accept-share', body: {decisionToken: token}});

    var decision = await api.get('decision', {where: {token}});

    this.props.trackDecisions({decisions: [decision]});

    setTimeout(() => {
      this.props.history.push(`/decisions/${decisionId}/`);
    });
  }

  render() {
    return (
      <View></View>
    );
  }
}

export default connect({
  mapDispatch: {
    ..._.pick(resourceActions.decisions, ['trackDecisions'])
  }
})(ShareLinkView);
