import { K } from '~/styles';

var s = {};

s.pdfPopupContainer = {
  flexDirection: 'column'
};
  s.textInput = {
    marginBottom: K.spacing,
    paddingVertical: K.margin
  };
  s.shareButton = {
    backgroundColor: 'black',
    marginBottom: K.margin
  };
  s.shareButtonText = {
    color: 'white'
  };
  s.resetFormButton = {
  };
  s.resetFormButtonText = {
  };


export default s;
