import {
  React, Component, _, View, styleSpread, Text, TextInput, Image, trackDecisionValues, updateDecision,
  updateDecisionOption, destroyDecisionOption, updateDecisionValue, destroyDecisionValue, PickerInput, lib, Comments, ScrollView
} from '../../../../../index';

import { PanResponder, TouchableOpacity } from 'react-native';
import { withRouter } from 'react-router-native';
import { connect } from '@symbolic/redux';
import { CopilotStepView, Label, confirm } from '@symbolic/rn-lib';
import { setActiveView } from '~/redux/active-view/active-view';
import { K } from '~/styles';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import commentsIcon from '~/assets/comments-icon.png';
import dvStyles from '../../show-decision-view.styles';
import styles from './row.styles';

var s = styleSpread(styles);

class RowBar extends Component {
  state = {isPanning: false, isReadyToPan: false, showOptionFactorNotes: false, forceNumericRerender: false};
  panMoveCount = 0;

  constructor(props) {
    super(props);

    this.getNumericInputRef = ref => this.numericInput = ref;
  }

  disablePanning() {
    if (!K.isWeb) this.props.setScrollViewIsEnabled(true);

    if (this.state.isPanning) {
      this.setState({isReadyToPan: false, isPanning: false, panResponder: null});
    }
    else {
      this.setState({isReadyToPan: false});
    }
  }

  openPopup = () => {
    var {history, option, factor, decisionSheetId} = this.props;

    history.push(`/decisions/${option.decisionId}/options/${option.id}/perspectives/${decisionSheetId}/factors/${factor.id}`);
  };

  async componentDidMount() {
    this._listenerPanResponder = PanResponder.create({
      onStartShouldSetPanResponder: (_event, gestureState) => {
        this.startTime = Date.now();
        this.didScroll = false;
        this.firstMoveX = null;

        this.dx = 0;
        this.dy = 0;

        this.isReadyToPanTimeout = setTimeout(() => {
          if (!this.didScroll && !this.props.isLocked) {
            this.setState({isReadyToPan: true});

            if (!K.isWeb) this.props.setScrollViewIsEnabled(false);
          }
        }, 200);

        return false;
      },
      onMoveShouldSetPanResponder: (_event, gestureState) => {
        this.dx = gestureState.dx;
        this.dy = gestureState.dy;

        var isReadyToPan = K.isWeb || this.state.isReadyToPan; //HINT has been 100ms
        var shouldSet = isReadyToPan;

        //User has tried to scroll scrollview for the first time before 100ms
        if (!this.didScroll && (Math.abs(this.dx) >= 5 || Math.abs(this.dy) >= 5)) {
          this.didScroll = true;

          clearTimeout(this.isReadyToPanTimeout);
        }

        if (shouldSet) {
          clearTimeout(this.isReadyToPanTimeout);

          if (!this.props.isLocked) {
            this.setState({isPanning: true, isReadyToPan: true, panResponder: this._activatedPanResponder});
          }
          else if (!this.props.hasEditPermission) {
            var ownerName = _.get(_.find(this.props.users, {id: this.props.decision.ownerId}), 'name', 'its owner');

            alert(`You have the ability to view this perspective, but only ${ownerName} can edit it.`);
          }
          else if (this.props.isLocked) {
            alert(`This decision is locked and cannot be edited.`);
          }
        };

        return shouldSet;
      }
    });

    this._activatedPanResponder = PanResponder.create({
      onPanResponderMove: (event, gestureState) => {
        this.dx = gestureState.dx;

        if (!this.firstMoveX) this.firstMoveX = _.get(event.nativeEvent, 'locationX', event.nativeEvent.layerX || 0);

        var x = this.firstMoveX + this.dx;
        var value = this.valueFor({x});

        this.setState({tempValue: value});
      },
      onPanResponderEnd: () => {
        if (!this.props.isLocked && this.state.tempValue !== undefined && this.state.tempValue !== this.propsValue) {
          var value = this.state.tempValue;

          this.onValueChange(value);
        }

        setTimeout(() => this.disablePanning()); //HINT fallback in-case user dragged out of the view and handleTouchEnd was fired on another bar
      }
    });

    if (this.isCopilotRow) {
      var animationWasRunning = false;

      this.copilotAnimation = setInterval(() => {
        if (this.isShowingCopilot) {
          animationWasRunning = true;

          this.setState({tempValue: _.includes([undefined, 0.7], this.state.tempValue) ? 0.3 : this.state.tempValue + 0.2});
        }
        else if (animationWasRunning) {
          clearInterval(this.copilotAnimation);

          this.setState({tempValue: undefined});
        }
      }, 1000);
    }

    this.forceUpdate();
  }

  get isCopilotRow() {
    return this.props.isFirst && this.props.rowIndex === (this.props.focusedResourceKey ? 0 : 1);
  }

  get isShowingCopilot() {
    return this.isCopilotRow && this.props.activeView.data.copilotStep === 4;
  }

  componentWillUnmount() {
    clearInterval(this.copilotAnimation);
  }

  valueFor = ({x}) => {
    var proportion = x / this.props.factor.width;

    if (proportion < 0.025) return null;
    else if (proportion < 0.075) return 0;
    else if (proportion < 0.1 + 0.05) return 0.1;
    else if (proportion < 0.2 + 0.05) return 0.2;
    else if (proportion < 0.3 + 0.05) return 0.3;
    else if (proportion < 0.4 + 0.05) return 0.4;
    else if (proportion < 0.5 + 0.05) return 0.5;
    else if (proportion < 0.6 + 0.05) return 0.6;
    else if (proportion < 0.7 + 0.05) return 0.7;
    else if (proportion < 0.8 + 0.05) return 0.8;
    else if (proportion < 0.9 + 0.05) return 0.9;
    else return 1;
  }

  handleTouchStart = () => {
    this.touchStarted = true;
    this.touchStartedTime = Date.now();
  }

  handleTouchEnd = (event) => {
    if (this.touchStarted || this.state.isReadyToPan) {
      this.touchStarted = false;

      if (!this.state.isPanning && this.dx === 0 && this.dy === 0) {
        // var clickLength = Date.now() - this.touchStartedTime;

        var msSinceLastScroll = Date.now() - this.props.scrollHelper.lastScroll;

        if (!this.state.isReadyToPan && msSinceLastScroll > 500) {
          var isClickingTextInput = this.props.mode === 'numeric' && (_.get(event, 'nativeEvent.target.tagName') === 'INPUT' || _.includes(['RCTSinglelineTextInputView', 'AndroidTextInput'], _.get(event, 'target.viewConfig.uiViewClassName')));

          if (!isClickingTextInput) this.openPopup();
        }

        // var x = event.nativeEvent[K.isWeb ? 'layerX' : 'locationX'];
        //   var value = this.findClosest({event});

        //   if (value !== this.value) {
        //     this.setState({tempValue: value});

        //     this.onValueChange(value);
        //   }
      }

      this.disablePanning();

      clearTimeout(this.isReadyToPanTimeout);
    }
  }

  handleInputChange(value) {
    if (value === '' || (_.includes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], _.toNumber(value)))) {
      if (value === '') {
        value = null;
      }
      else {
        value = _.toNumber(value);

        if (!value || value === 0) {
          value = 0;
        }
        else {
          value = _.toNumber(value);

          if (_.includes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], value)) {
            value = (value / 10);
          }
        }
      }

      this.setState({tempValue: value}, () => {
        this.onValueChange(this.state.tempValue);
      });
    }
    else {
      alert('Please enter an integer between 0 and 10');
    }
  }

  async onValueChange(value) {
    var {isLocked, decision} = this.props;

    if (!isLocked) {
      if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});

      if (!value && value !== 0) value = null;

      if (!this.props.decisionValue) {
        await this.createDecisionValue({value});
      }
      else {
        this.props.updateDecisionValue({id: this.props.decisionValue.id, props: {value, lastUpdaterId: this.props.session.user.id}});
      }

      setTimeout(() => this.setState({tempValue: undefined}));
    }
  }

  async createDecisionValue({value}) {
    var {decision, option, factor, decisionSheetId} = this.props;

    if (decision && option && factor && decisionSheetId) {
      var decisionValue = await lib.api.create('decisionValue', {
        lastUpdaterId: this.props.session.user.id,
        orgId: decision.orgId,
        decisionId: decision.id,
        decisionOptionId: option.id,
        decisionFactorId: factor.id,
        decisionSheetId,
        value
      });
    }

    this.props.trackDecisionValues({decisionValues: [decisionValue]});
  }

  handleInputBlur(value) {
    if (value === 0) {
      //force update
      this.numericInputRef.forceUpdate();
    }
  }

  get propsValue() {
    return _.get(this.props.decisionValue, 'value');
  }

  get lastValue() {
    return _.get(this.state, 'lastValue', _.get(this, 'propsValue', 0));
  }

  get value() {
    return _.get(this.state, 'tempValue', this.propsValue);
  }

  get notes() {
    return _.get(this.props, `option.factorNotes.${this.props.factor.id}`, '');
  }

  get width() {
    var factorWidth = this.props.factor.width;
    var {value} = this;
    var offset = (value === 1 || this.props.mode === 'joined') ? 0 : 0.05;

    var width =(factorWidth && value) ? (((factorWidth - K.bar.height) * (value  - 0.1) * (1 / 0.9)) + K.bar.height) : 0;

    return width;
  }

  get displayValue() {
    var value;

    if (_.includes([0.25, 0.75], this.value)) {
      value = this.value === 0.25 ? 3 : 7;
    }
    else {
      value = this.value * 10;
    }

    if (this.value < 0 || (!this.value && this.value !== 0)) {
      value = '?';
    }

    return value;
  }

  get hasCommentsOrNotes() {
    var {sheets, decisionSheetId} = this.props;
    var sheet = _.find(sheets, {id: decisionSheetId});
    var hasComments = _.filter(this.props.comments, comment => {
      return comment.type === 'decisionOptionFactor' &&
        parseInt(comment.data.decisionOptionId) === this.props.option.id &&
        parseInt(comment.data.decisionOptionFactorId) === this.props.factor.id &&
        (sheet.isMasterSheet || sheet.id === comment.decisionSheetId)
    }).length > 0;

    var hasNotes = _.get(this.notes, 'length', 0) > 0;

    return hasComments || hasNotes;
  }

  render() {
    var {factor, option, mode, isLocked, noSheetValues, fontScale, isLast} = this.props;
    var panResponder = this.state.panResponder || this._listenerPanResponder;
    var Container = View; //mode === 'numeric' ? View : TouchableOpacity;
    var color = option.status === 'ruledOut' ? '#cccccc' : factor.color;

    noSheetValues = noSheetValues && (this.state.tempValue === undefined || this.state.tempValue === null);

    var valueTextContainerStyles = {
      height: K.bar.height,
      ...((mode !== 'joined') ? {borderTopLeftRadius: styles.K.borderRadius, borderBottomLeftRadius: styles.K.borderRadius} : {}),
      ...((mode !== 'joined' || isLast) ? {borderTopRightRadius: styles.K.borderRadius, borderBottomRightRadius: styles.K.borderRadius} : {}),
    };

    var showTipMessage = !!(((noSheetValues && !this.state.isReadyToPan && !this.isShowingCopilot) && !_.isEmpty(option)) && mode === 'split');

    return (
      <Container>
        <CopilotStepView
          disabled={!this.isCopilotRow}
          text={`HOLD and DRAG the slider to rate each option by the factors.\n\nThere's more to the tour but if you want to dive in and play with the decision you can exit and bring up the guided tour later if you need it.`}
          name='optionFactor'
          order={4}
          dataSet={{grabbing: this.touchStarted ? 1 : undefined}}
          onTouchStart={this.handleTouchStart}
          onMouseDown={this.handleTouchStart}
          onTouchEnd={this.handleTouchEnd}
          onMouseUp={this.handleTouchEnd}
          {...(mode === 'joined' ? {} : _.get(panResponder, 'panHandlers'))}
          style={{
            ...styles.barContainer,
            ...(mode !== 'joined' ? {width: this.props.factor.width} : {}),
            opacity: (option.status !== 'ruledOut' && factor.weight === 0) ? (this.state.isReadyToPan ? 0.2 : 0.3) : (this.state.isReadyToPan ? 0.8 : 1),
            marginRight: mode !== 'joined' ? (!isLast ? styles.K.gapSize : 0) : this.value ? 1 : 0,
            overflow: 'hidden',
            justifyContent: 'center',
            height: styles.K.barHeight
          }}
        >
          <View style={mode !== 'joined' ? {backgroundColor: color + '4D', height: K.bar.height, width: '100%', borderRadius: K.bar.borderRadius, position: 'relative', justifyContent: 'center'} : {}}>
            {showTipMessage && (
              <View pointerEvents='none' style={{position: 'absolute', right: 8, alignItems: 'flex-end', width: this.props.factor.width}}>
                <Label style={{opacity: 0.5, color: 'red', letterSpacing: 0.3, textTransform: 'none', ...(K.isWeb ? {userSelect: 'none', fontSize: fontScale * K.fonts.su.fontSize} : {})}}>{'HOLD + DRAG'}</Label>
              </View>
            )}
            <View pointerEvents='none' style={{
              ...valueTextContainerStyles,
              backgroundColor: !this.value ? 'transparent' : color + 'CD',
              width: Math.max(mode === 'joined' ? 0 : K.bar.height, this.width),
              alignItems: 'flex-end'
            }}>
              {mode === 'split' && !this.props.isLocked && (<>
                <View
                  style={{...styles.valueText, borderWidth: this.state.isReadyToPan || showTipMessage ? 1 : 0, backgroundColor: !this.value ? color + '2d' : color, width: K.bar.height}}
                  dataSet={{grab: this.touchStarted ? undefined : 1, 'hover-outline': 1}}
                >
                  {(!showTipMessage && (this.displayValue === '?' || this.state.isReadyToPan || this.isShowingCopilot) &&  !_.isEmpty(option)) && (
                    <Label style={{opacity: this.displayValue === '?' ? 0.5 : 1, ...(K.isWeb ? {userSelect: 'none', fontSize: fontScale * K.fonts.su.fontSize} : {})}}>{this.displayValue}</Label>
                  )}
                </View>
              </>)}
            </View>
            {/* {showTipMessage && (
              <View style={{
                ...valueTextContainerStyles,
                position: 'absolute',
                backgroundColor: 'transparent',
                width: this.props.factor.width
              }}>

              </View>
            )} */}
          </View>
          {mode === 'numeric' && !_.isEmpty(option) && (
            <View {...s.numericInputContainer}>
              <TextInput
                placeholder={this.displayValue === '?' ? '?' : ''}
                editable={!isLocked}
                keyboardType='numeric'
                {...s.numericInput}
                maxLength={2}
                value={this.displayValue === '?' ? '' : `${this.displayValue}`}
                onInput={({value}) => this.handleInputChange(value)}
                onBlur={({value}) => this.handleInputBlur(value)}
                scrollEnabled={false}
                selectTextOnFocus
                ref={this.getNumericInputRef}
              />
            </View>
          )}
          {mode !== 'joined' && this.hasCommentsOrNotes && (
            <View {...s.commentsIconContainer}>
              <Image {...s.commentsIcon} source={commentsIcon}></Image>
            </View>
          )}
        </CopilotStepView>
      </Container>
    );
  }
}

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {updatedProps: {}, notesPopupIsVisible: false};

    this.update = _.debounce(this.update.bind(this), 200);
  }

  update() {
    var {decision} = this.props;

    if (!decision.isLocked) {
      this.props.updateDecisionOption({id: this.props.option.id, props: this.state.updatedProps});
    }
  }

  handleInputChange({key, value}) {
    var {decision} = this.props;

    if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});

    if (!decision.isLocked && !_.isEmpty(this.props.option)) {
      this.setState(({updatedProps}) => ({updatedProps: {...updatedProps, [key]: value}}));

      this.update();
    }
  }

  handlePopupClose = type => {
    var couldDelete = (!this.title && !this.notes) || type === 'cancel';

    if (couldDelete && type !== 'confirm') {
      this.props.destroyDecisionOption({id: this.props.option.id});
    }

    this.props.history.push(`/decisions/${this.props.decision.id}`);
  }

  handleRowTitlePress = () => {
    var {history, option, decision, sheet, sheets, rowResourceKey, focusedResourceKey} = this.props;

    if (rowResourceKey === 'option') {
      var sheetId = focusedResourceKey === 'decisionFactor' ? _.find(sheets, {isMasterSheet: 1}).id : sheet.id;

      history.push(`/decisions/${decision.id}/options/${option.id}/perspectives/${sheetId}`);
    }
    else {
      if (this.hasEditPermission) this.props.setActiveView({data: {isEditingSheet: true, editingSheetId: sheet.id}});
    }
  }

  get title() {
    return (this.state.updatedProps.title || this.state.updatedProps.title === '') ? this.state.updatedProps.title : this.props.option.title;
  }

  get notes() {
    return (this.state.updatedProps.notes || this.state.updatedProps.notes === '') ? this.state.updatedProps.notes : this.props.option.notes;
  }

  get hasCommentsOrNotes() {
    var {sheet, focusedResourceKey} = this.props;

    return (_.filter(this.props.comments, comment => {
      return comment.type === 'decisionOption' &&
        parseInt(comment.data.decisionOptionId) === this.props.option.id &&
        (focusedResourceKey === 'decisionFactor' || (sheet.isMasterSheet || sheet.id === comment.decisionSheetId))
    }).length > 0) || (_.get(this.notes, 'length') > 0);
  }

  get hasEditPermission() {
    var {decision, sheet={}, session} = this.props;

    return _.includes([decision.ownerId, sheet.userId, sheet.editableBy], session.user.id) || (sheet.isMasterSheet && decision.collaborationMode === 'oneEditableSheet');
  }

  decisionValueFor({sheetId, factorId, optionId}) {
    return _.find(this.props.values, decisionValue => {
      return decisionValue.decisionSheetId === sheetId && decisionValue.decisionFactorId === factorId && decisionValue.decisionOptionId === optionId;
    });
  }

  render() {
    var {
      factors, sheets, options, sheet, option, mode, setScrollViewIsEnabled, maxNetValue, renderMode, decisionSheetId, destroyDecisionValue, trackDecisionValues, activeView,
      scrollHelper, match, history, decision, focusedResourceKey, focusedResourceId, comments, insets, scale, fontScale, session, updateDecisionValue, users,
      rowResourceKey, rowResourceId, updateDecision
    } = this.props;

    var isHeader = _.isEmpty(option);

    var netWidth = option.netValue ? K.table.titleColumnWidth * scale * (option.netValue / maxNetValue) : 0;

    var statusOptions = [
      {value: 'tentative', title: 'Tentative', color: lib.colors.colorFor({status: 'planning'})},
      {value: 'candidate', title: 'Candidate', color: lib.colors.colorFor({status: 'active'})},
      {value: 'final', title: 'Final Selection', color: lib.colors.colorFor({status: 'complete'})},
      {value: 'ruledOut', title: 'Ruled Out', color: lib.colors.colorFor({status: 'archived'})},
    ];

    var statusColor = _.get(_.find(statusOptions, {value: option.status}), 'color');

    var relevantResources = factors;

    if (focusedResourceKey === 'decisionFactor') {
      var focusedFactor = _.find(factors, {id: focusedResourceId});

      relevantResources = sheets;
    }
    else if (focusedResourceKey === 'decisionOption') {
      option = _.find(options, {id: focusedResourceId});
    }
    else if (focusedResourceKey === null && rowResourceKey === 'sheet') {
      relevantResources = [];
    }

    relevantResources = _.map(relevantResources, (resource) => {
      var factor = focusedResourceKey === 'decisionFactor' ? focusedFactor : resource;

      var decisionSheetId = {
        decisionSheet: focusedResourceId,
        decisionFactor: resource.id
      }[focusedResourceKey] || _.get(sheet, 'id');

      var decisionValue = this.decisionValueFor({sheetId: decisionSheetId, factorId: factor.id, optionId: option.id});

      return {...resource, decisionSheetId, factor, decisionValue};
    });

    if (mode === 'joined') {
      relevantResources = _.reject(relevantResources, resource => _.includes([0, null, undefined], _.get(resource, `decisionValue.value`)));
    }

    var isSheet = focusedResourceKey === 'decisionOption' || _.isEmpty(option);
    var title = isSheet ? sheet.title : option.title;
    var isCreating = this.props.location.pathname.includes('create');

    var copilotText = focusedResourceKey !== 'decisionOption' ? `Rows are OPTIONS you’re deciding between` :
      `${K.isWeb ? 'Click' : 'Tap'} here to view more and manage this perspective. \n\nPerspectives are used to compare and contrast differing opinions, by allowing different ratings of the same options.\n\nWe suggest creating a perspective for each team member if you're collaborating.`;
    //WARNING renderMode is a quirky solution to making a sticky table
    //WARNING only reasonable alternative is splitting this component up, which would duplicate a decent amount of code
    return (
      <>
        <View style={{...dvStyles.tableRow, opacity: option.status === 'ruledOut' ? 0.3 : 1, backgroundColor: isHeader ? 'black' : 'transparent', height: K.bar.height}}>
          {_.includes(['title', 'screenshot'], renderMode) && (
            <CopilotStepView
              style={{...dvStyles.tableCell, width: scale * K.insets(insets, 'left', K.table.titleColumnWidth), paddingLeft: K.insets(insets, 'left'), zIndex: 1, flexDirection: 'row', backgroundColor: mode === 'joined' && !isHeader ? K.colors.gray : 'transparent', ...(mode !== 'joined' ? {/*borderTopRightRadius: styles.K.borderRadius, borderBottomRightRadius: styles.K.borderRadius*/} : {})}}
              text={copilotText}
              disabled={this.props.index !== (!focusedResourceKey ? 1 : 0)}
              name={focusedResourceKey !== 'decisionOption' ? 'optionTitle' : 'sheetRow'}
              order={2}
              hideExit
            >
              {mode !== 'joined' && (
                <View style={{...styles.netValueBar, width: netWidth, ...(K.orientation === 'landscape' ? {left: insets.left, width: netWidth, borderRadius: styles.K.borderRadius} : {left: 0}), backgroundColor: (statusColor && option.status !== 'tentative') ? (statusColor) : styles.netValueBar.backgroundColor, borderTopRightRadius: styles.K.borderRadius, borderBottomRightRadius: styles.K.borderRadius}}></View>)}
              <TouchableOpacity style={{...styles.titleButton, paddingHorizontal: K.spacing * scale}} onPress={this.handleRowTitlePress}>
                <Text
                  style={{flex: 1, opacity: title.length ? 1 : 0.4, fontSize: K.fonts[title ? 'sl' : 'su'].fontSize * this.props.fontScale, letterSpacing: K.fonts.sl.letterSpacing * this.props.fontScale, color: isHeader ? 'white' : 'black'}}
                  numberOfLines={2}
                >
                  {title || `NEW ${rowResourceKey === 'option' ? 'OPTION' : 'PERSPECTIVE'}`}
                </Text>
                {mode !== 'joined' && rowResourceKey !== 'sheet' && this.hasCommentsOrNotes && (
                  <Image style={{...styles.commentsIcon, marginRight: 0, marginLeft: K.margin}} source={commentsIcon}></Image>
                )}
              </TouchableOpacity>
            </CopilotStepView>
          )}
          {_.includes(['bars', 'screenshot'], renderMode) && _.map(relevantResources, (resource, index) => {
            var {factor, decisionSheetId, decisionValue} = resource;

            return (
              <RowBar
                {...{option, factor, sheets, mode, scrollHelper, setScrollViewIsEnabled, decisionSheetId, decision, comments, match, history, activeView,
                   users, session, updateDecisionValue, destroyDecisionValue, trackDecisionValues, scale, fontScale, isHeader, focusedResourceKey, updateDecision}}
                rowIndex={this.props.index}
                isFirst={index === 0}
                isLast={index === relevantResources.length - 1}
                isLocked={decision.isLocked || !this.hasEditPermission}
                decisionValue={decisionValue}
                key={`${factor.id}-${decisionSheetId}`}
                hasEditPermission={this.hasEditPermission}
                noSheetValues={option.noSheetValues && index === 0 && this.props.index === (focusedResourceKey === null ? 1 : 0)}
                onNotesChange={value => this.handleInputChange({
                  key: 'factorNotes',
                  value: {...option.factorNotes, [factor.id]: value}
                })}
              />
            );
          })}
        </View>
      </>
    );
  }
}

export default withSafeAreaInsets(withRouter(connect({
  mapState: (state, ownProps) => {
    var {resources, session, activeView} = state;

    var decision = _.get(resources.decisions.byId, ownProps.decisionId);

    var values = _.filter(resources.decisionValues.byId, decisionValue => {
      return decisionValue.decisionId === ownProps.decisionId && decisionValue.decisionOptionId === ownProps.option.id  && _.includes(_.map(ownProps.sheets, 'id'), decisionValue.decisionSheetId);
    });

    return {decision, values, session, users: resources.users.byId, activeView, comments: _.get(resources, 'comments.byId', {})};
  },
  mapDispatch: {
    setActiveView, updateDecisionOption, destroyDecisionOption, updateDecisionValue, destroyDecisionValue, trackDecisionValues,
    updateDecision
  }
})(Row)));
