import { K } from '~/styles';

var slides = [
  {
    title: 'Protagonist helps you make decisions',
    body: 'Rate the options you\'re deciding between based on the important factors',
    video: K.isWeb ? 'https://symbolic-public.s3.amazonaws.com/protagonist/intro/hold-drag.mp4' : require('./hold-drag.mp4'),
    mediaResizeMode: 'contain'
  },
  {
    title: 'You can use it with a team at work...',
    body: '...with your friends and family...\nor just on your own',
    video: K.isWeb ? 'https://symbolic-public.s3.amazonaws.com/protagonist/intro/perspectives.mp4' : require('./perspectives.mp4'),
    mediaResizeMode: 'contain'
  }
];

export default slides;
