import {
  React, Component, _, styleSpread, prepareToAnimate,
  View, ScrollView, Button, Text, logAnalyticsEvent,
  updateDecision, destroyDecision, destroyDecisionOption, Loading, trackDecisionValues, updateDecisionValue,
  createDecisionOption, trackDecisionOptions, trackDecisionFactors, trackDecisionSheets, updateDecisionFactor, trackProcessInstances, resourceActions
} from '../../../index.js'; //eslint-disable-line

import { Dimensions, Platform, UIManager, Animated, Keyboard, Share, Image, TouchableWithoutFeedback, Modal, Pressable } from 'react-native';
import { CopilotStepView, Label, withKeyEvents, confirm, getDevice, getExpoPushToken } from '@symbolic/rn-lib';
import { coloredFactorsFor } from '../../../../lib/color';
import { connect, updateMyAccount, setProtagonistData } from '@symbolic/redux';
import { K } from '~/styles';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { setActiveView, setEvent } from '~/redux/index.js';
import { viewableDecisionSheetIdsFor } from '~/lib/permissions';
import { DocumentTitle } from '@symbolic/rn-lib';

import * as ImageManipulator from 'expo-image-manipulator';
import * as FileSystem from 'expo-file-system';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';

import SecondaryHeader from '~components/secondary-header/secondary-header';
import FactorPopup from '~components/popups/factor-popup/factor-popup.js';
import FactorValuePopup from  '~components/popups/factor-value-popup/factor-value-popup.js';
import RequestReviewPopup from '~components/popups/request-review-popup/request-review-popup.js';
import ColumnHeaders from './column-headers/column-headers';
import SharePopup from './share-popup/share-popup.js';
import ShareSuggestionPopup from '~components/popups/share-suggestion-popup/share-suggestion-popup.js';
import OptionPopup from '~components/popups/option-popup/option-popup.js';
import PDFPopup from './pdf-popup/pdf-popup.js';
import BasicsPopup from '~/components/basics-popup/basics-popup';
import PolydotPopup from '~/components/popups/polydot-popup/polydot-popup';
import Rows from './rows/rows';

import styles from './show-decision-view.styles';
import lib from '@symbolic/lib';
import api from '../../../../lib/api';
import ViewShot from "react-native-view-shot-with-web-support";
import Papa from 'papaparse';
import createIcon from '~/assets/create-icon-white.png';
import optionIcon from '~/assets/option-icon-white.png';
import factorIcon from '~/assets/factor-icon-white.png';
import sheetIcon from '~/assets/sheet-icon-white.png';
import moment from 'moment';

var s = styleSpread(styles);

class ShowDecisionView extends Component {
  leftIsScrolling = false;
  rightIsScrolling = false;

  constructor(props) {
    super(props);

    this.update = _.debounce(this.update.bind(this), 200);
    this.considerShowingShareSuggestionPopup = this.considerShowingShareSuggestionPopup.bind(this);

    this.state = {
      factors: [],
      sortByFactorId: null,
      scrollViewIsEnabled: true,
      updatedProps: {},
      isLoadingSheet: false,
      focusedResourceKey: 'decisionSheet',
      focusedResourceId: null,
      isLoading: !props.isStatic,
      showedRequestReviewPopup: true,
      reloaded: false,
      window: null
    };

    this.scrollHelper = {lastScroll: Date.now()};

    //WARNING this sticky logic is pretty tricky
    //IMPORTANT to useNativeDriver and Animated.ScrollView
    //IMPORTANT to use Animated.event and track activeSideKey on begin drag
    this.sticky = _.mapValues({left: {}, right: {}}, (_value, sideKey) => {
      var oppositeSideKey = sideKey === 'left' ? 'right' : 'left';
      var avScrollY = new Animated.Value(0);
      var handleScroll = Animated.event([{nativeEvent: {contentOffset: {y: avScrollY}}}], {useNativeDriver: true});

      var handleBeginDrag = () => this.sticky.activeSideKey = sideKey;
      var getRef = ref => this.sticky[sideKey].ref = ref;

      avScrollY.addListener(({value: y}) => {
        if (this.sticky.activeSideKey === sideKey || (K.isWeb && this.sticky[oppositeSideKey].lastY !== y)) {
          //WARNING animated true won't help - it's buggy
          this.sticky[oppositeSideKey].ref.scrollTo({x: 0, y, animated: false});
        }

        this.sticky[sideKey].lastY = y;
      });

      return {getRef, avScrollY, handleScroll, handleBeginDrag};
    });
  }

  async componentDidMount() {
    if (K.isWeb) {
      Dimensions.addEventListener('change', this.handleDimensionsChange);
    }

    // await this.considerShowingRequestRatingPopup();

    if (this.props.decision && !this.props.isStatic) {
      var {decision, session} = this.props;
      var {user} = session;
      var {id: decisionId, processInstanceId} = decision;

      this.setState({isLoading: true});

      var usersData = await api.request({uri: '/get-users', body: {decisionId}});
      var users = usersData.data.users;

      var [decisionFactors, decisionOptions, decisionSheets] = await Promise.all([
        api.get('decisionFactors', {where: {decisionId}}),
        api.get('decisionOptions', {where: {decisionId}}),
        api.get('decisionSheets', {where: {decisionId}})
      ]);

      if (processInstanceId) {
        var processInstances = await api.get('processInstances', {where: {id: processInstanceId}});
      }

      var viewableDecisionSheetIds = viewableDecisionSheetIdsFor({user, decision, decisionSheets});

      var decisionValues = await api.get('decisionValues', {where: {decisionId, decisionSheetId: viewableDecisionSheetIds}});

      if (!K.isWeb) {
        var deviceId = (await getDevice({appKey: 'protagonist'})).id;

        if ((_.size(users) > 1 || Platform.OS === 'android') && _.get(this.props.session, `user.devices.${deviceId}.expoPushToken`) === undefined) {
          var expoPushToken = await getExpoPushToken();

          if (expoPushToken) {
            await this.props.updateMyAccount({expoPushTokenData: {expoPushToken, deviceId}});
          }
        }
      }

      prepareToAnimate();

      if (!this.isOwner) {
        if (_.includes(['manyPrivateSheets', 'manyPublicSheets'], decision.collaborationMode) && !_.some(decisionSheets, ({userId, editableBy}) => _.includes([userId, editableBy], user.id))) {
          var decisionSheet = await api.create('decisionSheet', {
            title: `${session.user.firstName}`,
            decisionId: decision.id,
            orgId: decision.orgId,
            userId: session.user.id,
            editableBy: session.user.id
          });

          decisionSheets = [...decisionSheets, decisionSheet];
          viewableDecisionSheetIds = [...viewableDecisionSheetIds, decisionSheet.id];
        }
      }

      var comments = await api.get('comments', {
        where: {resourceId: decisionId, decisionSheetId: [null, ...viewableDecisionSheetIds], type: ['decision', 'decisionOption', 'decisionOptionFactor']}
      });

      this.props.trackUsers({users, reset: true});
      this.props.trackComments({comments, reset: true});
      this.props.trackDecisionFactors({decisionFactors});
      this.props.trackDecisionOptions({decisionOptions});
      this.props.trackDecisionSheets({decisionSheets});
      this.props.trackDecisionValues({decisionValues});

      if (decision.processInstanceId) this.props.trackProcessInstances({processInstances});

      setTimeout(() => {
        this.setFocusedResource({resourceKey: 'decisionSheet'});
        this.sort();

        this.setState({isLoading: false});

        this.props.startCopilot({autopilot: true, key: 'showDecisionView'});
      }, 0);
    }
    else if (this.props.focusedResourceId) {
      this.setFocusedResource({resourceKey: 'decisionSheet', resourceId: this.props.focusedResourceId});
    }

    this.props.setActiveView({data: {activeDecisionMode: 'split'}});

    // var userHasSeenBasicsPopup = _.get(this.props, 'session.user.protagonistData.userHasSeenBasicsPopup') || await sessionStore.get('userHasSeenBasicsPopup') || false;
    //
    // if (!userHasSeenBasicsPopup && !_.get(this.props, 'activeView.data.requestReviewPopupIsVisible')) {
    //   this.props.setProtagonistData({key: 'userHasSeenBasicsPopup', value: true});
    //
    //   this.props.setActiveView({data: {basicsPopupIsVisible: true}});
    // }
  }

  componentWillUnMount() {
    if (K.isWeb) {
      Dimensions.removeEventListener('change', this.handleDimensionsChange);
    }

    this.considerDestroyingDecision();
  }

  componentDidUpdate(prevProps) {
    //HINT trying to cache order on load, to keep reordering from happening as user modifies values
    if (_.get(this.props.options, 'length') && ((this.props.isStatic && !_.isEqual(this.props.options, prevProps.options)) || !this.state.sortedOptionIds)) {
      this.sort();
    }
    if (_.get(prevProps.decision, 'collaborationMode') !== _.get(this.props.decision, 'collaborationMode') && !this.isOwner) {
      var ownerName = _.get(_.find(this.props.users, {id: _.get(this.props.decision, 'ownerId')}), 'name', 'The decision owner');

      setTimeout(() => alert(`${ownerName} has changed permission settings.`));
    }
  }

  considerDestroyingDecision() {
    var {decision} = this.props;

    if (!this.state.reloaded && decision && decision.wasModified === 0) this.props.destroyDecision({id: decision.id});
  }

  modifyDecision = async (callback, options) => {
    var {decision} = this.props;

    if (decision.wasModified !== 1) this.props.updateDecision({id: decision.id, props: {wasModified: 1}});

    options ? callback(options) : callback();
  }

  setFocusedResource = ({resourceKey, resourceId}) => {
    if (resourceKey !== null) {
      var focusedResourceKey = resourceKey || this.state.focusedResourceKey;

      // if (focusedResourceKey === 'decisionSheet' && !this.props.isStatic) this.setState({isLoadingSheet: true});

      var resources = {
        decisionFactor: this.props.factors,
        decisionOption: this.props.options,
        decisionSheet: this.props.sheets,
        decisionValues: this.props.values
      }[focusedResourceKey];

      if (!resourceId) {
        ({id: resourceId} = focusedResourceKey === 'decisionSheet' ? this.defaultDecisionSheet : _.sortBy(resources, 'id')[0]);
      }

      this.setState({focusedResourceKey, focusedResourceId: resourceId});

      if (focusedResourceKey === 'decisionSheet') setTimeout(() => this.setState({/*isLoadingSheet: false, */ sortByFactorId: null}), 0);
    }
    else if (!this.props.isStatic) {
      // this.setState({isLoadingSheet: true});

      this.setState({focusedResourceKey: resourceKey});

      // setTimeout(() => this.setState({isLoadingSheet: false}), 0);
    }
  }

  async considerShowingShareSuggestionPopup({context}) {
    var hasShownShareSuggestionPopup = _.get(this.props.session.user, 'protagonistData.showedShareSuggestionPopup') || await sessionStore.get('showedShareSuggestionPopup');
    var shouldShow = false;

    if (!hasShownShareSuggestionPopup && this.isOwner) {
      if (context === 'creatingFactorsOrOptions' && (this.props.options.length >= 4 || this.props.factors.length >= 4)) {
        shouldShow = true;
      }
      if (context === 'addingPerspective') {
        shouldShow = true;
      }

      if (shouldShow) this.props.setActiveView({data: {...this.props.activeView.data, shareSuggestionPopupIsVisible: true, shareSuggestionContext: context}});
    }
  }

  handleInputChange({key, value}) {
    var {decision} = this.props;

    if (!decision.isLocked) {
      this.setState(({updatedProps}) => ({updatedProps: {...updatedProps, [key]: value}}));

      this.modifyDecision(this.update);
    }
  }

  handleKeyDown = event => {
    if (lib.event.keyPressed(event, 'ctrlcmd') && event.keyCode === 82) {
      this.setState({reloaded: true});
    }

    if (lib.event.keyPressed(event, 'ctrlcmd') && lib.event.keyPressed(event, 'shift')) {
      if (event.key === 'o') this.createOption();
      if (event.key === 'f') this.createFactor();
      if (event.key === 'p') this.openCollaboratorsMenu();
    }
  }

  handleDimensionsChange = () => {
    var window = Dimensions.get('window');

    this.setState({window});
  }

  update() {
    var {decision} = this.props;

    if (!decision.isLocked) {
      this.props.updateDecision({id: decision.id, props: this.state.updatedProps});
    }
  }

  createOption = async () => {
    var {decision, sheets, focusedResourceKey, focusedResourceId} = this.props;

    if (!decision.isLocked) {
      prepareToAnimate();

      var newOption = await api.create('decisionOption', {title: '', decisionId: decision.id, orgId: decision.orgId, status: 'tentative'});

      this.props.trackDecisionOptions({decisionOptions: [newOption]});

      //this.setState({activeOptionId: newOption.id});

      logAnalyticsEvent('create_decision_option');

      setTimeout(() => {
        this.sticky.left.ref.scrollToEnd();
        this.sticky.right.ref.scrollToEnd();

        var sheetId = focusedResourceKey === 'decisionSheet' ? focusedResourceId : _.find(sheets, {isMasterSheet: 1}).id;

        this.props.history.push(`/decisions/${decision.id}/options/${newOption.id}/perspectives/${sheetId}/create`);
      }, 0);
    }
  }

  createFactor = async () => {
    var {decision} = this.props;

    prepareToAnimate();

    var newFactor = await api.create('decisionFactor', {title: '', weight: 1, decisionId: decision.id, orgId: decision.orgId});

    this.props.trackDecisionFactors({decisionFactors: [...this.props.factors, newFactor]});

    logAnalyticsEvent('create_decision_factor');

    setTimeout(() => {
      this.horizontalScrollViewRef.scrollTo({x: 10000});

      this.props.history.push(`/decisions/${decision.id}/factors/${newFactor.id}/create`)
    }, 0);
  }

  createSheet = async () => {
    var {decision, session} = this.props;

    var decisionSheet = await api.create('decisionSheet', {
      title: ``,
      decisionId: decision.id,
      orgId: decision.orgId,
      userId: session.user.id,
      editableBy: session.user.id
    });

    logAnalyticsEvent('create_decision_sheet');

    await this.props.trackDecisionSheets({decisionSheets: [decisionSheet]});

    setTimeout(() => {
      if (this.state.focusedResourceKey === 'decisionSheet') this.setFocusedResource({resourceKey: 'decisionSheet', resourceId: decisionSheet.id});

      this.props.setActiveView({data: {...this.props.activeView.data, isCreatingSheet: true, activeDecisionMode: 'split', editingSheetId: decisionSheet.id}});
    });
  }

  setScrollViewIsEnabled = (scrollViewIsEnabled) => {
    this.setState({scrollViewIsEnabled});

    this.horizontalScrollViewRef.setNativeProps({scrollEnabled: scrollViewIsEnabled});
    this.sticky.right.ref.setNativeProps({scrollEnabled: scrollViewIsEnabled});
  }

  sortedOptionIdsFor({sortByFactorId, options, sheetId=this.decisionSheetId}) {
    var sortedOptions = _.orderBy(options, option => {
      return sortByFactorId ? (_.get(_.find(this.props.values, value => {
        return value.decisionSheetId === sheetId && value.decisionFactorId === sortByFactorId && value.decisionOptionId === option.id;
      }), 'value') || 0) : option.netValue;
    }, 'desc');

    return _.map(sortedOptions, 'id');
  }

  sortBy = ({factorId: sortByFactorId}) => {
    var sortedOptionIds = this.sortedOptionIdsFor({sortByFactorId, options: this.options});

    this.setState({sortByFactorId, sortedOptionIds});
  }

  sort = () => this.sortBy({factorId: null});

  considerShowingRequestRatingPopup = async () => {
    var alreadyShowedRequestReviewPopup = _.get(this.props.session.user, 'protagonistData.showedRequestReviewPopup') || await sessionStore.get('showedRequestReviewPopup');
    var uniqueUsesCount = await sessionStore.get('uniqueUsesCount');
    var atLeast2OwnedDecisions = _.get(this.props, 'ownedDecisions.length', 0) >= 2;
    var userAtLeast4DaysOld = moment.utc(_.get(this.props, 'session.user.created')).isBefore(moment().subtract(4, 'days'));
    var uniqueUsesCountAtLeast4 = uniqueUsesCount >= 4;

    if (!K.isWeb && Platform.OS !== 'android' && !alreadyShowedRequestReviewPopup && atLeast2OwnedDecisions && userAtLeast4DaysOld && uniqueUsesCountAtLeast4) {
      this.props.setActiveView({data: {requestReviewPopupIsVisible: true}});
    }
  }

  // onDeleteButtonPress = async () => {
  //   Keyboard.dismiss();

  //   var {activeOptionId} = this.state;

  //   var option = _.find(this.options, {id: activeOptionId});

  //   if (option) {
  //     var title = _.get(option, 'title');

  //     if (await confirm(`Delete`, `Are you sure? ${title ? `"${title}"` : 'this option'} will be deleted permanently`)) {
  //       this.props.destroyDecisionOption({id: activeOptionId});
  //     }
  //   }
  // }

  onSaveButtonPress = () => {
    Keyboard.dismiss();
  }

  generateOptionsForSheet({sheetId}) {
    var {factors, options} = this.props;

    var options = _.map(options, option => {
      var factorNotes = {..._.get(option, 'factorNotes', {})};

      _.forEach(factors, factor => {
        if (factorNotes[factor.id] === undefined) {
          factorNotes[factor.id] = '';
        }
      });

      var netValue = _.sumBy(factors, ({id, weight}) => {
        var factorValue = _.find(this.props.values, value => {
          return value.decisionSheetId === sheetId && value.decisionFactorId === id && value.decisionOptionId === option.id;
        });

        return (_.get(factorValue, 'value') || 0) * weight;
      });

      return {...option, netValue, factorNotes, isHighestRated: false};
    });

    if (options.length) {
      var highestRatedOption = _.maxBy(options, 'netValue');
      var sheetValues = _.filter(this.props.values, {decisionSheetId: sheetId});

      if (highestRatedOption) highestRatedOption.isHighestRated = true;

      if (sheetValues.length === 0 || _.every(sheetValues, {value: null})) {
        options = _.forEach(options, (option, index) => {
          option.noSheetValues = true;
        });
      }
    }

    return options;
  }

  get options() {
    return this.generateOptionsForSheet({sheetId: this.decisionSheetId});
  }

  get sortedOptionsBySheet() {
    var sortedOptionsBySheet = {};
    var {sortByFactorId} = this.state;

    _.forEach(this.props.sheets, sheet => {
      var options = this.generateOptionsForSheet({sheetId: sheet.id});

      var sortedOptionIds = this.sortedOptionIdsFor({sortByFactorId, options, sheetId: sheet.id});
      sortedOptionsBySheet[sheet.id] = _.orderBy(options, [({id}) => (_.indexOf(sortedOptionIds, id) + 1) || 10000000, 'id']);
    });

    return sortedOptionsBySheet;
  }

  get filename() {
    return this.props.decision.title.replace(/\ /g, '-').replace(/[^0-9a-zA-Z\-]*/g, '');
  }

  get viewableSheets() {
    var {sheets, decision} = this.props;

    var viewableDecisionSheetIds = viewableDecisionSheetIdsFor({user: this.props.session.user, decision, decisionSheets: sheets});

    sheets = _.filter(sheets, ({id}) => _.includes(viewableDecisionSheetIds, id));

    return sheets;
  }

  deleteFactor = async () => {
    var {id, title} = this.props.factor;

    if (this.props.isOnlyFactor) {
      alert(`You must always have at least one factor, please add more factors before deleting this one.`);
    }
    else {
      if (await confirm(`Delete`, `Are you sure? ${title ? `"${title}"` : 'this factor'} will be deleted permanently`)) {
        this.props.destroyDecisionFactor({id});
      }
    }
  }

  getDecisionTableRef = ref => this.decisionTableRef = ref;

  getTableUri = async ({renderForPdf}) => {
    return new Promise(async (resolve) => {
      if (renderForPdf) {
        var cachedActiveDecisionMode = this.props.activeView.data.activeDecisionMode;

        this.props.setActiveView({data: {renderForPdf: true, activeDecisionMode: 'joined'}});
      }

      setTimeout(() => {
        this.setState({renderForSharing: true}, async () => {
          if (this.decisionTableRef) {
            await this.decisionTableRef.capture().then(async uri => {
              if (!K.isWeb) {
                const uriParts = uri.split(".");
                const formatPart = uriParts[uriParts.length - 1];
                let format;
                if (formatPart.includes("png")) {
                  format = "png";
                } else if (formatPart.includes("jpg") || formatPart.includes("jpeg")) {
                  format = "jpeg";
                }
                const { base64 } = await ImageManipulator.manipulateAsync(
                  uri,
                  [],
                  { format: format || "png", base64: true }
                );

                uri = `data:image/${format};base64,${base64}`;
              }

              resolve(uri);

              if (renderForPdf) this.props.setActiveView({data: {renderForPdf: false, activeDecisionMode: cachedActiveDecisionMode}});

              this.setState({renderForSharing: false});
            });
          }
          else {
            if (renderForPdf) this.props.setActiveView({data: {renderForPdf: false, activeDecisionMode: cachedActiveDecisionMode}});

            this.setState({renderForSharing: false});
          }
        });
      });
    });
  }

  share = async ({url, message}) => {
    if (Platform.OS === 'ios') {
      await Share.share({url, message});
    }
    else {
      await Sharing.shareAsync(url);
    }
  }

  handleShareScreenshotPress = async () => {
    var uri = await this.getTableUri({});
    var filename = `${this.filename}.png`;

    if (K.isWeb) {
      var link = document.createElement("a");
      link.download = filename;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else {
      var newFileLocation = `${FileSystem.cacheDirectory}${filename}`;

      await FileSystem.writeAsStringAsync(newFileLocation, uri.split("data:image/png;base64,")[1], {
        encoding: FileSystem.EncodingType.Base64,
      });

      await this.share({url: newFileLocation, message: 'Share Screenshot'})
    }

    logAnalyticsEvent('share_screenshot');
  }

  handleShareCsvPress = async () => {
    var csv = [[this.props.decision.title], ['title', ..._.map(this.props.factors, factor => `${factor.title} (x${_.get(factor, 'weight', 1)})`), 'weighted net score', 'notes']];
    _.forEach(this.props.sheets, sheet => {
      csv.push([sheet.title]);

      var sortedOptions = _.orderBy(this.sortedOptionsBySheet[sheet.id], 'netValue', 'desc');

      _.forEach(sortedOptions, option => {
        var decisionValues = _.filter(this.props.values, value => value.decisionSheetId === sheet.id && value.decisionOptionId === option.id);

        var weightedNetValue = _.sumBy(decisionValues, value => {
          var factor = _.find(this.props.factors, {id: value.decisionFactorId});

          return (_.round(_.get(value, 'value', 0) * 4) * _.get(factor, 'weight', 1));
        });

        csv.push([
          `${option.title || ''}`,
          ..._.map(this.props.factors, factor => {
            var decisionValue = _.find(this.props.values, value => value.decisionSheetId === sheet.id && value.decisionOptionId === option.id && value.decisionFactorId === factor.id);

            return decisionValue ? _.round(_.get(decisionValue, 'value', 0) * 4) : '';
          }),
          weightedNetValue,
          option.notes
        ]);
      });
    });

    csv = Papa.unparse(csv);

    if (K.isWeb) {
      var filename = `${this.filename}.csv`;

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      }
      else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
    else {
      var newFileLocation = `${FileSystem.cacheDirectory}${this.filename}.csv`;

      await FileSystem.writeAsStringAsync(newFileLocation, csv);

      this.share({url: newFileLocation, message: 'Share CSV'});
    }

    logAnalyticsEvent('export_csv');
  }

  handleGeneratePDFPress = () => {
    this.props.setActiveView({data: {sharePopupIsVisible: false, pdfPopupIsVisible: true}});
  }

  // handleBasicsPopupHide = () => {
  //   this.props.setActiveView({data: {basicsPopupIsVisible: false}});

  //   this.props.startCopilot({autopilot: true, key: 'showDecisionView'});
  // }

  sharePdf = async ({settings}) => {
    var tableUri = await this.getTableUri({renderForPdf: true});

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
            @page {
              margin: 20px;
            }
            html, body {
              font-size: 11pt;
              font-family: 'HelveticaNeue-Light', arial;
            }
            a {
              font-size: inherit;
            }
            body {
              position: relative;
              width: calc(612px - 40px);
              height: calc(${Platform.OS === 'android' ? 1050 : 792}px - 40px);
            }
          </style>
        </head>
        <body>
          <div style="height: 100%; padding: 1rem 3rem; font-family: 'HelveticaNeue', arial;">
            <div style="letter-spacing: 0.3rem; font-size: 1.1rem; margin-bottom: 4rem; font-weight: 500;">PROPOSAL</div>
            <div style="font-size: 0.8rem; margin-bottom: 2rem">${moment().format(`M.D.YYYY`)}</div>
            <div style="font-size: 1.5rem; margin-bottom: 1rem; font-family: 'HelveticaNeue', arial;">${settings.title}</div>
            ${settings.presenter && `<div style="font-size: 0.8rem; margin-bottom: 2rem">Presented by ${settings.presenter}</div>`}
            <div style="margin-bottom: 2rem">${settings.summary}</div>
            <div style="margin-bottom: 1rem; white-space: pre-line;">${settings.considerations}</div>
            <div style="white-space: pre-line;">${settings.finalists}</div>
            <div style="margin: 3rem 0">
              <img style="width: 100%;" src="${tableUri}"/>
            </div>
            ${settings.references.length ? '<div style="font-size: 0.8rem; margin-bottom: 1rem">REFERENCES</div>' : ''}
            <div style="white-space: pre-line;">${settings.references.replace(
              /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img,
              '<a style="text-decoration: none; color: rgb(51.837720%, 59.107510%, 69.103450%)" target="_blank" href="$1">$1</a>'
            )}</div>
            <div style="position: absolute; width: 100%; bottom: 10px; font-family: 'HelveticaNeue', arial; letter-spacing: 0.1rem; font-size: 0.8rem; font-weight: 500;">THIS REPORT IS BASED ON ANALYSIS DONE WITH <b>PROTAGONIST</b> SOFTWARE</div>
          </div>
        </body>
      </html>
    `;

    try {
      if (K.isWeb) {
        setTimeout(() => {
          var cachedTitle = document.title;
          var bodyChildNodes = [...document.body.childNodes];

          document.title = this.filename;
          document.body.innerHTML = htmlContent;

          setTimeout(() => {
            window.print();

            document.title = cachedTitle;
            document.body.innerHTML = '';

            // bodyChildNodes.forEach(childNode => document.body.appendChild(childNode));
            bodyChildNodes.forEach(childNode => document.body.appendChild(childNode));
          });
        }, 100);
      }
      else {
        var { uri } = await Print.printToFileAsync({ html: htmlContent });
        var filename = `${this.filename}.pdf`;
        var newFileLocation = `${FileSystem.cacheDirectory}${filename}`;

        await FileSystem.copyAsync({from: `file://${uri}`, to: newFileLocation});

        await this.share({url: newFileLocation, message: 'Share PDF'});
      }
    } catch (err) {
      console.error(err);
    }
  }

  openCollaboratorsMenu = () => {
    this.props.setActiveView({data: {sharePopupIsVisible: true, isViewingCollaborators: true}});
  }

  triggerUndoPopup = ({event}) => {
    this.props.setEvent({event});

    this.props.setActiveView({data: {undoPopupIsVisible: true}});
  }

  get decisionSheetId() {
    var {focusedResourceKey, focusedResourceId} = this.state;

    return (focusedResourceKey === 'decisionSheet' && focusedResourceId) ? focusedResourceId : _.get(this.defaultDecisionSheet, 'id');
  }

  get defaultDecisionSheet() {
    var {session} = this.props;

    return _.find(this.viewableSheets, ({userId, editableBy}) => _.includes([userId, editableBy], session.user.id)) || _.find(this.viewableSheets, {isMasterSheet: 1}) || this.viewableSheets[0];
  }

  get isOwner() {
    var {decision, session} = this.props;

    return decision.ownerId === session.user.id;
  }

  get optionsAreSorted() {
    var sortedOptionIds = this.sortedOptionIdsFor({sortByFactorId: null, options: this.options});

    return _.isEqual(sortedOptionIds, this.state.sortedOptionIds);
  }

  render() {
    var {decision, insets, isStatic, maxWidth, scale = 1, fontScale = 1, session, values, users} = this.props;

    if (!decision) return null;

    var {sortByFactorId, sortedOptionIds, scrollViewIsEnabled, activeOptionId, showingTutorialCopilot, focusedResourceKey, focusedResourceId} = this.state;
    var {setScrollViewIsEnabled, options, sticky, sort, sortedOptionsBySheet, scrollHelper, setFocusedResource, triggerUndoPopup, optionsAreSorted} = this;
    var {factors, sheets, activeDecisionMode: mode, activeView, match, history} = this.props;
    var {activeDecisionMode: mode, sharePopupIsVisible, requestReviewPopupIsVisible, pdfPopupIsVisible, renderForPdf, copilotStep, shareSuggestionPopupIsVisible, polydotPopupIsVisible, undoPopupIsVisible} = activeView.data;

    var sheets = this.viewableSheets;

    if (showingTutorialCopilot === undefined) showingTutorialCopilot = true;

    //< factors
    var containerWidth = maxWidth ? Math.min(maxWidth, Dimensions.get('window').width) : Dimensions.get('window').width;
    var availableWidth = ((renderForPdf) && mode === 'joined') ? factors.length * styles.K.maxColWidth : containerWidth - (K.table.titleColumnWidth * scale) - (factors.length || 0) - K.insets(insets, 'left');
    var netWeight = _.sumBy(factors, 'weight');
    var maxColWidth = mode === 'joined' ? (availableWidth / (_.sumBy(factors, 'weight')) || 1) : _.max([availableWidth / factors.length, scale * styles.K.maxColWidth]);

    var delta = 0;

    factors = _.map(factors, (factor, index) => {
      var normalizedWeight = _.get(factor, 'weight', 1) / netWeight;
      var width = mode === 'joined' ? maxColWidth * _.get(factor, 'weight', 1) : maxColWidth;

      delta += width - Math.floor(width);

      width = Math.floor(width) + (index === factors.length - 1 ? Math.ceil(delta) : 0);

      return {...factor, normalizedWeight, width, shortTitle: factor.title};
    });

    factors = coloredFactorsFor({factors});

    factors = _.sortBy(factors, [
      ({rank}) => rank === null ? 10000 : rank,
      'id'
    ]);

    var maxNetValue = !_.isEmpty(factors) ? _.sumBy(factors, 'weight') : 1;
    //> factors

    //WARNING a little tricky here. trying to:
    //1. account for react state being async and not guranteed to be ready by the time this renders
    //2. cache sort order so it doesn't change as user is modifying
    if (!sortedOptionIds) {
      sortedOptionIds = this.sortedOptionIdsFor({sortByFactorId, options});
    }

    //HINT sort by sort order, and then newly created options
    options = _.orderBy(options, [({id}) => (_.indexOf(sortedOptionIds, id) + 1) || 100000, 'id']);

    var sharedProps = {decision, factors, options, sheets, focusedResourceKey, focusedResourceId, match, history};
    var columnHeaderProps = {...sharedProps, sortByFactorId, scale, fontScale, mode, session, setActiveView: this.props.setActiveView};
    var rowProps = {...sharedProps, scrollHelper, sortedOptionsBySheet, options, setScrollViewIsEnabled, mode, maxNetValue, scrollViewIsEnabled, sticky, scale, fontScale, values};

    // var isAdding = (this.state.isAdding || copilotStep === 2);
    var isAdding = this.state.isAdding;

    var userSheet = _.find(sheets, ({userId, editableBy}) => _.includes([userId, editableBy], session.user.id));

    var ownerName = _.get(_.find(this.props.users, {id: _.get(this.props.decision, 'ownerId')}), 'name', 'the owner of this decision');

    var creationOptions = [
      {
        title: 'Option', hint: 'New row', onPress: this.createOption, hotKey: 'O', icon: optionIcon, alertCondition: !decision.shareesCanEditOptions,
        alertMessage: `Currently only ${ownerName} can create options. If you'd like to add options please ask them to enable that setting.`
      },
      {
        title: 'Factor', hint: 'New column', onPress: this.createFactor, hotKey: 'F', icon: factorIcon, alertCondition: true,
        alertMessage: `Only ${ownerName} can create factors.`
      },
      {
        title: 'Perspective', hint: 'New tab', onPress: this.openCollaboratorsMenu, hotKey: 'P', icon: sheetIcon, alertCondition: !!userSheet || _.includes(['oneViewableSheet', 'oneEditableSheet'], decision.collaborationMode),
        alertMessage: `Each collaborator is limited to one perspective tab. If you'd like an additional one, please ask ${ownerName} to create one for you.`
      }
    ];

    if (!this.isOwner) creationOptions = _.reject(creationOptions, 'alertCondition');

    var isMac = K.isWeb && navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    var noExistingComments = false;

    var matchDecisionOptionId = match.params.decisionOptionId ? parseInt(match.params.decisionOptionId) : null;
    var matchDecisionSheetId = match.params.decisionSheetId ? parseInt(match.params.decisionSheetId) : null;
    var matchDecisionFactorId = match.params.decisionFactorId ? parseInt(match.params.decisionFactorId) : null;

    if (match && matchDecisionOptionId && matchDecisionFactorId && matchDecisionSheetId) {
      noExistingComments = !_.some(this.props.comments, comment => {
        return comment.type === 'decisionOptionFactor' &&
          comment.data.decisionOptionId === matchDecisionOptionId &&
          comment.data.decisionOptionFactorId === matchDecisionFactorId
      });
    }

    return (
      <DocumentTitle title={`${decision.title} - Protagonist`}>
      <>
        {this.state.isLoading ? (
          <Loading text={'Loading Decision\n\nthis can take a few seconds\n\nHold tight...'}/>
        ) : (
          <View {...s.decisionView}>
            {isStatic && <TouchableWithoutFeedback>
              <View {...s.overlay}/>
            </TouchableWithoutFeedback>}
            <SecondaryHeader
              {...{focusedResourceKey, focusedResourceId, isStatic, scale, fontScale, sort, triggerUndoPopup, optionsAreSorted}}
              considerShowingShareSuggestionPopup={this.considerShowingShareSuggestionPopup}
              setFocusedResource={this.setFocusedResource}
              decisionId={decision.id}
            />
          {this.state.isLoadingSheet ? (
            <Loading text={'Loading Sheet\n\nthis can take a few seconds\n\nHold tight...'}/>
          ) : (
            <>
              <ScrollView
                {...s.mainScrollView}
                contentContainerStyle={{flex: 1}}
                keyboardShouldPersistTaps='handled'
                scrollEnabled={false}
              >
                {this.state.renderForSharing ? (
                  <ScrollView {...s.horizontalScrollView} keyboardShouldPersistTaps='handled' horizontal scrollEnabled={true} ref={ref => this.horizontalScrollViewRef = ref}>
                    <ScrollView {...s.verticalScrollView} keyboardShouldPersistTaps='handled' scrollEnabled={true}>
                      <ViewShot style={{flexDirection: 'column', backgroundColor: 'white'}} ref={this.getDecisionTableRef}>
                        <View {...s.tableHeader}>
                          <ColumnHeaders renderMode={'screenshot'} {...{...columnHeaderProps, renderForPdf}}/>
                        </View>
                        <Rows renderMode={'screenshot'} {...rowProps}/>
                      </ViewShot>
                    </ScrollView>
                  </ScrollView>
                ) : (
                  <View {...s.table}>
                    <View {...s.stickyColumn}>
                      <View {...s.tableHeader}>
                        <ColumnHeaders renderMode={'title'} {...{...columnHeaderProps}}/>
                      </View>
                      <Rows renderMode={'title'} {...{...rowProps, activeOptionId, updateActiveOptionId: (id) => this.setState({activeOptionId: id})}}/>
                    </View>
                    <Animated.ScrollView
                      keyboardShouldPersistTaps='handled'
                      ref={ref => this.horizontalScrollViewRef = ref}
                      {...s.horizontalScrollView}
                      horizontal
                      onScroll={() => this.scrollHelper.lastScroll = Date.now()}
                      scrollEnabled={this.state.scrollViewIsEnabled}
                      bounces={false}
                    >
                      <View {...s.innerTable}>
                        <View {...s.tableHeader}>
                          <ColumnHeaders renderMode={'bars'} {...columnHeaderProps}/>
                        </View>
                        <Rows renderMode={'bars'} {...rowProps}/>
                      </View>
                    </Animated.ScrollView>
                  </View>
                )}
              </ScrollView>
              {!isStatic && (
                isAdding ? (
                  <Modal transparent style={{...(isAdding ? {height: '100%', width: '100%'} : {}), position: 'fixed', bottom: 0, right: 0, borderWidth: 0}}>
                    <Pressable style={{...(isAdding ? {height: '100%', width: '100%', justifyContent: 'flex-end'} : {})}} onPress={() => this.setState({isAdding: false})}>
                      <View
                        style={{...styles.addButtonContainer, marginBottom: K.spacing * 2, marginLeft: K.spacing * 2, ...(K.orientation === 'landscape' ? {left: 'auto', right: K.insets(insets, 'right', K.spacing), bottom: K.spacing * 2} : {})}}
                      >
                        <View>
                          {_.map(creationOptions, ({title, onPress, hotKey, hint, icon, alertCondition, alertMessage}, index) => (
                            <Button
                              key={title}
                              style={{
                                backgroundColor: 'black', height: K.calc(70), paddingHorizontal: K.spacing, alignItems: 'flex-end', width: K.isWeb ? 230 : 200, borderBottomColor: 'rgba(255, 255, 255, 0.2)', borderBottomWidth: 1,
                                borderRadius: 0,
                                ...(index === 0 ? {borderTopLeftRadius: K.bar.borderRadius, borderTopRightRadius: K.bar.borderRadius} : {}),
                                ...(index === creationOptions.length - 1 ? {/*borderBottomLeftRadius: K.bar.borderRadius, */borderBottomRightRadius: K.bar.borderRadius} : {})
                              }}
                              onPress={() => {
                                if (!this.isOwner && alertCondition) {
                                  alert(alertMessage);
                                }
                                else {
                                  this.setState({isAdding: false});

                                  this.modifyDecision(onPress);
                                }
                              }}
                            >
                              <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: K.calc(4), width: '100%'}}>
                                <Text style={{color: 'white', flex: 1, fontSize: K.calcFont(16)}}>{title}</Text>
                                <View style={{alignItems: 'center'}}>
                                  <Image source={icon} style={{width: K.bar.borderRadius, height: K.bar.borderRadius}}/>
                                </View>
                              </View>
                              <View style={{flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'flex-start'}}>
                                <Label style={{color: 'white', flex: 1, opacity: 0.5, fontSize: K.calcFont(13)}}>{hint}</Label>
                                {K.isWeb && (
                                  <Label style={{color: 'white', fontSize: K.calcFont(13)}}>{`${isMac ? '⌘' : 'Ctrl'} ⇧ ${hotKey}`}</Label>
                                )}
                              </View>
                            </Button>
                          ))}
                        </View>
                        <Button style={{...styles.addButton, borderTopLeftRadius: 0, borderTopRightRadius: 0}} onPress={() => this.setState({isAdding: !this.state.isAdding})}>
                          <Image style={{width: K.calc(70 / 2), height: K.calc(70 / 2), transform: [{rotate: '45deg'}]}} source={createIcon}/>
                        </Button>
                      </View>
                    </Pressable>
                  </Modal>
                ) : (
                  !decision.isLocked && creationOptions.length > 0 && !undoPopupIsVisible && <CopilotStepView
                    style={{...styles.addButtonContainer, position: 'absolute', bottom: K.spacing * 2, left: K.spacing * 2, ...(K.orientation === 'landscape' ? {left: 'auto', right: K.insets(insets, 'right', K.spacing), bottom: K.spacing * 2} : {})}}
                    text={`This is our universal 'create' button.\n\nYou can use it to create new options and factors or add perspectives.`}
                    name='addButton'
                    order={5}
                  >
                    <Button style={{...styles.addButton}} onPress={() => this.setState({isAdding: !this.state.isAdding})}>
                      <Image style={{width: K.calc(70 / 2), height: K.calc(70 / 2)}} source={createIcon}/>
                    </Button>
                  </CopilotStepView>
                )
              )}
              {sharePopupIsVisible && (
                <SharePopup
                  activeDecision={decision}
                  {...{sheets}}
                  hideSharePopup={() => this.props.setActiveView({data: {sharePopupIsVisible: false, isViewingCollaborators: false}})}
                  handleShareScreenshotPress={() => this.modifyDecision(this.handleShareScreenshotPress)}
                  handleGeneratePDFPress={() => this.modifyDecision(this.handleGeneratePDFPress)}
                  handleShareCsvPress={() => this.modifyDecision(this.handleShareCsvPress)}
                  createSheet={this.createSheet}
                />
              )}
              {requestReviewPopupIsVisible && (<RequestReviewPopup/>)}
              {shareSuggestionPopupIsVisible && (<ShareSuggestionPopup/>)}
              {match && (match.path === '/decisions/:decisionId/factors/:decisionFactorId' || match.path === '/decisions/:decisionId/factors/:decisionFactorId/create') && (
                <FactorPopup
                  considerShowingShareSuggestionPopup={(options) => this.modifyDecision(this.considerShowingShareSuggestionPopup, options)}
                  isCreating={match.path === '/decisions/:decisionId/factors/:decisionFactorId/create'}
                  {...{setFocusedResource, focusedResourceKey, focusedResourceId, triggerUndoPopup}}
                  id={matchDecisionFactorId}
                  {...{sort}}
                />
              )}
              {match && matchDecisionOptionId && !matchDecisionFactorId && (
                <OptionPopup
                  considerShowingShareSuggestionPopup={(options) => this.modifyDecision(this.considerShowingShareSuggestionPopup, options)}
                  editable={decision.ownerId === this.props.session.user.id || decision.shareesCanEditOptions}
                  {...{setFocusedResource, focusedResourceKey, focusedResourceId, triggerUndoPopup}}
                  id={matchDecisionOptionId} decisionSheetId={matchDecisionSheetId}
                />
              )}
              {match && matchDecisionOptionId && matchDecisionFactorId && matchDecisionSheetId && (
                <FactorValuePopup {...{noExistingComments, decisionId: decision.id, decisionOptionId: matchDecisionOptionId, decisionFactorId: matchDecisionFactorId, decisionSheetId: matchDecisionSheetId, users}}/>
              )}
              {pdfPopupIsVisible && (
                <PDFPopup
                  hidePDFPopup={() => this.props.setActiveView({data: {pdfPopupIsVisible: false}})}
                  handleShareScreenshotPress={() => this.modifyDecision(this.handleShareScreenshotPress)}
                  handleGeneratePDFPress={() => this.modifyDecision(this.handleGeneratePDFPress)}
                  handleShareCsvPress={() => this.modifyDecision(this.handleShareCsvPress)}
                  activeDecision={decision}
                  sharePDF={this.sharePdf}
                  {...{factors, options}}
                />
              )}
              {polydotPopupIsVisible && (
                <PolydotPopup activeDecision={decision} onClose={() => this.props.setActiveView({data: {polydotPopupIsVisible: false}})}/>
              )}
            </>
          )}
          </View>
        )}
      </>
      </DocumentTitle>
    );
  }
}

export default withSafeAreaInsets(connect({
  mapState: (state, ownProps) => {
    var {resources, session, activeView} = state;
    var decisionId = ownProps.decisionId || ownProps.match.params.decisionId;
    var decision = _.get(resources.decisions.byId, parseInt(decisionId));
    var props = {session, activeView};

    if (!decision) {
      if (ownProps.history) ownProps.history.push('/404/decision');
    }
    else {
      props = {
        ...props,
        decision,
        comments: _.filter(resources.comments.byId, {resourceId: decision.id}),
        ownedDecisions: _.filter(resources.decisions.byId, {ownerId: session.user.id}),
        options: _.filter(resources.decisionOptions.byId, {decisionId: decision.id}),
        factors: _.filter(resources.decisionFactors.byId, {decisionId: decision.id}),
        sheets: _.filter(resources.decisionSheets.byId, {decisionId: decision.id}),
        values: _.filter(resources.decisionValues.byId, {decisionId: decision.id}),
        processInstances: _.filter(resources.processInstances.byId, {id: decision.processInstanceId}),
        users: resources.users.byId,
        focusedResourceId: ownProps.focusedResourceId
      };
    }

    return props;
  },
  mapDispatch: {
    updateDecision, destroyDecision, trackDecisionOptions, createDecisionOption, setActiveView, setEvent, trackDecisionValues, updateDecisionValue,
    trackDecisionFactors, trackDecisionSheets, trackProcessInstances, destroyDecisionOption, updateDecisionFactor, updateMyAccount, setProtagonistData,
    ..._.pick(resourceActions.users, ['trackUsers']),
    ..._.pick(resourceActions.comments, ['trackComments'])
  }
})(withKeyEvents(ShowDecisionView)));
