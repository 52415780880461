import { React, Component, _, Button, Text, styleSpread, Popup, View, Link } from '~/components/index.js'; //eslint-disable-line
import { setActiveView } from '~/redux/active-view/active-view';
import styles from './expired-trial-popup.styles';
import { withRouter } from 'react-router-native';
import { connect, setProtagonistData } from '@symbolic/redux';
import { Label } from '@symbolic/rn-lib';
import { K } from '~/styles';

var s = styleSpread(styles);

class TrialExpiredPopup extends Component {
  state = {};

  close = async () => {
    await this.props.setProtagonistData({key: 'showedTrialExpiredPopup', value: true});

    this.props.setActiveView({data: {trialExpiredPopupIsVisible: false}});
  }

  handleButtonPress = () => {
    this.close();

    this.props.history.push('/billing');
  }

  get domain() {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:19006' : 'protag.app';
  }

  render() {
    var copy = _.includes(this.props.location.pathname, 'decision') ?
      `Your trial has expired.\n\nThe people you shared this decison with won't be able to view it until you subscribe.` :
      `Your trial has expired.\n\nThe people you shared decisons with won't be able to view them until you subscribe.`;

    return (
      <Popup
        onClose={this.close}
        scrollEnabled
        {...(K.orientation === 'landscape' ? {height: '75%'} : {})}
      >
        <Text>{K.isWeb ? copy: `Sharing is a desktop-only feature - please visit ${this.domain} on your computer to give it a try!`}</Text>
        <View style={{flexDirection: 'row', width: 'auto', marginTop: K.spacing * 2}}>
          <Button onPress={this.close} style={{flex: 1, marginRight: K.margin}} label={K.isWeb ? 'Maybe later' : 'Ok'}/>
          {K.isWeb && <Button style={{backgroundColor: K.colors.purchaseBlue, flex: 1}} textStyle={{color: 'black'}} onPress={this.handleButtonPress} label={'View paid plans'}/>}
        </View>
      </Popup>
    );
  }
}

export default withRouter(connect({
  mapState: (state, ownProps) => {
    var {activeView} = state;

    return {activeView};
  },
  mapDispatch: {
    setActiveView, setProtagonistData
  }
})(TrialExpiredPopup));
