import {
  React, Component, _, View, ScrollView, styleSpread, destroyDecision, lib, Button, logAnalyticsEvent, resourceActions,
  Text, trackDecisions, updateDecision, trackDecisionFactors, trackDecisionSheets, destroyDecisionTemplate, TouchableOpacity, Image, Link, Loading
} from '../../index.js'; //eslint-disable-line

import { setActiveView, setEvent } from '~/redux/index.js';
import { connect } from '@symbolic/redux';
import { Popup, Label, confirm, DecisionTemplatePicker } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { Share, RefreshControl } from 'react-native';
import { DocumentTitle } from '@symbolic/rn-lib';
import { K } from '~/styles';

import api from '../../../lib/api';
import deleteIcon from '../../../assets/x-icon.png';
import styles from './home-view.styles';
import createIcon from '~/assets/create-icon-white.png';
import unsubscribeIcon from '../../../assets/leave-icon.png';
import homeIcon from '~/assets/home-icon.png';

var s = styleSpread(styles);

class DecisionRow extends Component {
  constructor(props) {
    super(props);

    this.deleteDecision = this.deleteDecision.bind(this);
    this.unsubscribeFromDecision = this.unsubscribeFromDecision.bind(this);
  }

  async deleteDecision() {
    var {decision} = this.props;
    var {title, id} = decision;

    // if (await confirm('Delete', `Are you sure? ${title ? `"${title}"` : 'this decision'} will be deleted permanently`)) {
      await this.props.onDeleteDecisionPress({id, title});

      logAnalyticsEvent('decision_deleted');

      this.props.setEvent({event: {
        message: `${title ? `The decision "${title}"` : 'That decision'} has been deleted`,
        action: () => {
          this.props.updateDecision({id, props: {deleted: 0}});

          this.props.trackDecisions({decisions: [decision]});
        }
      }});

      this.props.setActiveView({data: {undoPopupIsVisible: true}});
    // }
  }

  async unsubscribeFromDecision() {
    var {title, id} = this.props.decision;

    if (await confirm('Unsubscribe', `Are you sure? you will be unsubscribed from ${title ? `"${title}"` : 'this decision'} permanently`)) {
      await this.props.onUnsubscribeFromDecisionPress({id, title});

      logAnalyticsEvent('decision_unsubscribed');
    }
  }

  get isOwner() {
    return this.props.decision.ownerId === this.props.session.user.id;
  }

  render() {
    var {insets} = this.props;
    var {id, title, status} = this.props.decision;

    var statusToStoriesStatusMap = {
      'brainstorming': 'planning',
      'analysis': 'active',
      'finalReview': 'pending',
      'finalDecision': 'complete',
    };

    return (
      <View {...s.decisionRow} key={id}>
        <Link to={`/decisions/${id}`} style={{flex: 1}}>
          <View {...s.decision}>
            <Text style={{...styles.decisionTitle}}>{title || 'Untitled Decision'}</Text>
            {status !== 'brainstorming' && (<View style={{...styles.statusDot, backgroundColor: lib.colors.colorFor({status: statusToStoriesStatusMap[status]})}}/>)}
          </View>
        </Link>
        <TouchableOpacity {...s.deleteButton} onPress={this.isOwner ? this.deleteDecision : this.unsubscribeFromDecision}>
          <Image {...s.deleteImage} source={this.isOwner ? deleteIcon : unsubscribeIcon}/>
        </TouchableOpacity>
      </View>
    );
  }
}

class HomeView extends Component {
  state = {
    isCreating: false,
    isRefreshing: false
  };

  onDeleteDecisionPress = async({id}) => {
    this.props.destroyDecision({id});
  }

  onUnsubscribeFromDecisionPress = async({id}) => {
    api.request({uri: '/decisions/unsubscribe', body: {decisionId: id}});

    this.props.destroyDecision({id, hitApi: false});
  }

  beforeCreateDecision = () => {
    this.setState({isLoading: true});
  }

  afterCreateDecision = ({decision}) => {
    setTimeout(() => {
      this.setState({isLoading: false});

      this.props.history.push(`/decisions/${decision.id}`);
    });
  }

  async componentDidMount() {
    this.props.trackUsers({users: [], reset: true});

    this.hasCreatedDecision = await sessionStore.get('hasCreatedDecision');
  }

  onRefresh = async () => {
    this.setState({isRefreshing: true});

    await this.props.considerLoadingResources({forceReload: true});

    setTimeout(() => this.setState({isRefreshing: false}), 1000);
  }

  render() {
    var {insets, session, usersById} = this.props;
    var {isRefreshing} = this.state;
    var hasDecisions = _.size(this.props.decisionsById) > 0;
    var hasOwnedDecisions = _.filter(this.props.decisionsById, {ownerId: this.props.session.user.id}).length > 0;

    var decisions = _.orderBy(this.props.decisionsById, [({lastUpdated}) => {
      var time = new Date(lastUpdated).getTime();

      return time;
    }], ['desc']);

    return (
      <DocumentTitle title='Home - Protagonist'>
      <ScrollView
        style={{...styles.page, paddingRight: K.isWeb ? 0 : K.insets(insets, 'right') || K.spacing, paddingLeft: K.isWeb ? 0 : K.insets(insets, 'left') || K.spacing}}
        refreshControl={<RefreshControl onRefresh={this.onRefresh} enabled={true} refreshing={isRefreshing}/>}
      >
        <View {...s.header}>
          <View {...s.homeIconContainer}>
            <Image {...s.homeIcon} source={homeIcon}/>
          </View>
          <Text {...s.headerTitle}>Welcome to Protagonist</Text>
          {!hasOwnedDecisions && (
            <View {...s.headerMessages}>
              <Text {...s.headerMessage}>From here you can create new Decisions.</Text>
              <Text style={{...styles.headerMessage, marginBottom: 10}}>You can start from scratch or use a template.</Text>
            </View>
          )}
          <View
            style={{...styles.addButtonContainer}}
            // text={`Welcome to Protagonist!\n\nThis is your Home Screen.\n\nCreate a new decision by clicking this button.`}
            // name='homeView'
            // order={1}
          >
            <Button
              style={{...styles.addButton}}
              onPress={() => this.setState({isCreating: true})}
              icon={createIcon}
              iconSize={{width: K.calc(35), height: K.calc(35)}}
            />
          </View>
          {!K.isWeb && hasDecisions && (
            <View {...s.webAppMessage}>
              <Text style={{...K.explanationText, opacity: 0.4, textAlign: 'center'}}>{`Use Protagonist on desktop at\nprotag.app`}</Text>
            </View>
          )}
        </View>
        <ScrollView
          {...s.decisions}
          refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={this.onRefresh} enabled={true}/>}
          contentContainerStyle={styles.scrollViewContent}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          bounces={false}
        >
          {_.map([
            {title: 'Your decisions', decisions: _.filter(decisions, {ownerId: this.props.session.user.id})},
            {title: 'Shared with you', decisions: _.reject(decisions, {ownerId: this.props.session.user.id})}
          ], ({decisions, title}) => hasDecisions && (
              <View {...s.decisionsContainer} key={title}>
                <Label {...s.decisionsLabel}>{title}</Label>
                {_.size(decisions) > 0 ? (
                  _.map(decisions, (decision) => (
                    <DecisionRow
                      onUnsubscribeFromDecisionPress={this.onUnsubscribeFromDecisionPress}
                      onDeleteDecisionPress={this.onDeleteDecisionPress}
                      updateDecision={this.props.updateDecision}
                      trackDecisions={this.props.trackDecisions}
                      setActiveView={this.props.setActiveView}
                      {...{decision, insets, session}}
                      setEvent={this.props.setEvent}
                      key={decision.id}
                    />
                  ))
                ) : (
                  <View {...s.decisionsPlaceholder}>
                    <Text {...s.decisionsPlaceholderText}>
                      {title === 'Your decisions' ? `Decisions you create will appear here.` : `Decisions others share with you will appear here.`}
                    </Text>
                  </View>
                )}
              </View>
          ))}
        </ScrollView>
        {this.state.isCreating && (
          <Popup scrollEnabled height={500} onClose={() => this.setState({isCreating: false})}>
            {this.state.isLoading ? (
              <View style={{flex: 1, paddingTop: K.spacing * 2}}><Loading text={'Creating decision\n\nthis can take a few seconds\n\nHold tight...'}/></View>
            ) : (
              <View style={{marginTop: K.spacing, alignItems: 'center'}}>
                <DecisionTemplatePicker
                  destroyDecisionTemplate={this.props.destroyDecisionTemplate}
                  {...{session, sessionStore, usersById, logAnalyticsEvent}}
                  trackDecisionSheets={this.props.trackDecisionSheets}
                  showBlank={hasDecisions || this.hasCreatedDecision}
                  beforeCreateDecision={this.beforeCreateDecision}
                  afterCreateDecision={this.afterCreateDecision}
                  trackDecisions={this.props.trackDecisions}
                  onSelect={this.handleTemplateSelect}
                />
              </View>
            )}
          </Popup>
        )}
      </ScrollView>
      </DocumentTitle>
    )
  }
}

export default withSafeAreaInsets(connect({
  mapState: ({resources, session}) => ({
    decisionTemplates: _.values(resources.decisionTemplates.byId), decisionsById: resources.decisions.byId,
    usersById: resources.users.byId, session
  }),
  mapDispatch: {
    trackDecisions, trackDecisionFactors, trackDecisionSheets,updateDecision, destroyDecision, destroyDecisionTemplate,
    setActiveView, setEvent, trackUsers: resourceActions.users.trackUsers
  }
})(HomeView));
