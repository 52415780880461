import { React, Component, _, View, styleSpread, Popup, Text, resourceActions, logAnalyticsEvent } from '~/components/index.js'; //eslint-disable-line
import { setActiveView, setEvent } from '~/redux/index.js';
import { ProcessTypePicker, Loading } from '@symbolic/rn-lib';
import { Linking } from 'react-native';
import { connect } from '@symbolic/redux';
import { api } from '@symbolic/lib';
import { K } from '~/styles';

import styles from './polydot-popup.styles.';

var s = styleSpread(styles);

class PolydotPopup extends Component {
  state = {isLoading: true}

  async componentDidMount() {
    this.hasCreatedProcessType = await sessionStore.get('hasCreatedProcessType');

    var processTypes = await api.get('processTypes', {where: {orgId: _.map(this.props.session.orgs, 'id'), isSingleUse: 0}})

    this.props.trackProcessTypes({processTypes, reset: true});

    this.setState({isLoading: false});
  }

  beforeCreateProcessInstanceFromType = () => {
    this.setState({isLoading: true});
  }

  afterCreateProcessInstanceFromType = ({processInstance}) => {
    this.props.updateDecision({id: this.props.activeDecision.id, props: {processInstanceId: processInstance.id}});

    setTimeout(() => {
      var url = `http${process.env.NODE_ENV === 'production' ? 's://polydot.app' : '://localhost:19006'}/projects/${processInstance.id}`;

      if (K.isWeb) {
        window.open(url, '_blank');
      }
      else {
        Linking.openURL(url);
      }

      this.close();
    });
  }

  close = () => {
    this.props.onClose();
  }

  render() {
    var {session, customProcessTypes} = this.props;
    var hasUsedWeFlowLite = _.get(this.props, 'processTypes.length', 0) > 0 || this.hasCreatedProcessType;

    return (
      <Popup scrollEnabled height={500} onClose={this.close}>
        <View style={{alignItems: 'center'}}>
          {this.state.isLoading ? (
            <View style={{flex: 1, paddingTop: K.spacing * 2}}><Loading text={'Loading...'}/></View>
          ) : (
            <ProcessTypePicker
              beforeCreateProcessInstanceFromType={this.beforeCreateProcessInstanceFromType}
              afterCreateProcessInstanceFromType={this.afterCreateProcessInstanceFromType}
              trackProcessInstances={this.props.trackProcessInstances}
              beforeCreateProcessType={this.beforeCreateProcessType}
              {...{session, customProcessTypes, logAnalyticsEvent}}
              createProcessSteps={this.props.createProcessSteps}
              trackProcessTypes={this.props.trackProcessTypes}
              creationProps={{wasModified: 1}}
              showBlank={hasUsedWeFlowLite}
            />
          )}
        </View>
      </Popup>
    );
  }
}

export default connect({
  mapState: state => ({
    session: state.session, processTypes: state.resources.processTypes.byId,
    customProcessTypes: _.values(state.resources.processTypes.byId)
  }),
  mapDispatch: {
    setActiveView, setEvent,
    ..._.pick(resourceActions.decisions, ['updateDecision']),
    ..._.pick(resourceActions.processInstances, ['trackProcessInstances']),
    ..._.pick(resourceActions.processTypes, ['trackProcessTypes']),
    ..._.pick(resourceActions.processSteps, ['createProcessSteps'])
  }
})(PolydotPopup);
